const { gql } = require('@apollo/client');

export const EVENT_FRAGMENT = gql`
  fragment EventFragment on Event {
    _id
    organization {
      _id
      address1
      address2
      city
      country
      createdBy
      creationDate
      email
      logo_url
      name
      state
      stripeAccountId
      stripeChargesEnabled
      taxId
      website
      zip
      currency
      stripeTaxRates {
        stripeTaxRateId
        name
        countryCode
        taxRateInteger
        description
        inclusive
        active
      }
    }
    creationDate
    currency
    date_start
    date_end
    descr_long
    descr_short
    forIndividuals
    forUnderage
    forTeams
    hasOnlineQualifier
    name
    sports
    participantLimit
    participationFee
    pic_logo_url
    type
    subDivisionOptions {
      _id
      name
      description
      minAge
      maxAge
    }
    divisions {
      _id
      name
      description
      maxParticipants
      forIndividuals
      forTeams
      forUnderage
      teamSize
      minAge
      maxAge
      registrationFee
    }
    noAskForPlatformFee
    eventIsVisible

    termsOfServiceUrl
    liabilityWaiverUrl
    privacyPolicyUrl
  }
`;
