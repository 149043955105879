import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArgonTypography from 'components/ArgonTypography';
import ArgonBox from 'components/ArgonBox';
import ArgonSelect from 'components/ArgonSelect';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import NumberInput from 'components/NumberInput';
import ArgonProgress from 'components/ArgonProgress';
import { getRegistrationsByDivisionsForEventInstance } from 'services/restApi';
import { PulseLoader } from 'react-spinners';
import selectData from 'options/selectData';
import { getLeaderboardData } from 'services/restApi';

const DivisionRow = ({
  sourceEventInstanceId,
  inviteCount,
  availableSourceDivisions,
  selectedSourceDivision,
  selectedTargetDivision,
  invitationMethod,
  showError,
  showWarning,
  onChange
}) => {
  console.log(
    'DivisionRow: rendered: selectedSourceDivision: ',
    selectedSourceDivision
  );

  console.log(
    'DivisionRow: rendered: availableSourceDivisions: ',
    availableSourceDivisions
  );

  console.log(
    'DivisionRow: rendered: selectedTargetDivision: ',
    selectedTargetDivision
  );
  console.log('DivisionRow: rendered: inviteCount: ', inviteCount);

  console.log('DivisionRow: rendered: invitationMethod: ', invitationMethod);

  const [selectedMethodOption, setSelectedMethodOption] = useState(
    selectData.autoInvitationMethodOptions.find(
      (mo) => mo.value === invitationMethod
    ) || selectData.autoInvitationMethodOptions[0]
  );

  const [
    sourceEventInstanceLeaderboardData,
    setSourceEventInstanceLeaderboardData
  ] = useState([]);
  const [
    sourceEventInstanceLeaderboardDataLoading,
    setSourceEventInstanceLeaderboardDataLoading
  ] = useState(false);

  // const [matchedDivision, setMatchedDivision] = useState(selectedSourceDivision);

  const [registrationCountLoading, setRegistrationCountLoading] =
    useState(false);
  const [
    registrationCountOfSourceDivision,
    setRegistrationCountOfSourceDivision
  ] = useState();

  const [numberOfAthletesToBeInvited, setNumberOfAthletesToBeInvited] =
    useState(inviteCount || 0);

  useEffect(() => {
    // fetch registration count of source division
    if (sourceEventInstanceId) {
      setRegistrationCountLoading(true);
      getRegistrationsByDivisionsForEventInstance({
        eventInstanceId: sourceEventInstanceId
      })
        .then((result) => {
          console.log(
            'DivisionRow: getRegistrationsByDivisionsForEventInstance: result: ',
            result
          );

          let count = 'n/a';
          if (result?.registrationStatusData)
            count =
              result.registrationStatusData?.find(
                (rsd) => rsd.divisionId === selectedSourceDivision?._id
              )?.spotsTaken || '0';
          setRegistrationCountOfSourceDivision(count);
        })
        .finally(() => {
          setRegistrationCountLoading(false);
        });
    }
  }, [sourceEventInstanceId]);

  // useEffect(() => {
  //   setSourceEventInstanceLeaderboardDataLoading(true);
  //   getLeaderboardData({
  //     eventInstanceId: sourceEventInstanceId,
  //     divisionId: selectedSourceDivision?._id
  //   })
  //     .then((result) => {
  //       console.log(
  //         'AutoInvitationSettingsWizard: getLeaderboardData: ',
  //         result
  //       );
  //       setSourceEventInstanceLeaderboardData(result);
  //     })
  //     .finally(() => {
  //       setSourceEventInstanceLeaderboardDataLoading(false);
  //     });
  // }, [selectedSourceDivision]);

  const handleInvitationMethodChanged = (newOption) => {
    // console.log('New Invitation Method chosen: ', newOption);
    setSelectedMethodOption(newOption);
    onChange &&
      onChange({
        sourceDivision: selectedSourceDivision,
        targetDivision: selectedTargetDivision?._id,
        invitationMethod: newOption?.value,
        plannedInvitations: numberOfAthletesToBeInvited
      });
  };

  const handleInvitationCountChanged = (newCount) => {
    console.log(
      'handleInvitationCountChanged: Planned Invitations changed:',
      newCount
    );

    console.log(
      'handleInvitationCountChanged: selectedSourceDivision: ',
      selectedSourceDivision
    );
    console.log(
      'handleInvitationCountChanged: selectedTargetDivision: ',
      selectedTargetDivision
    );
    setNumberOfAthletesToBeInvited(newCount);
    onChange &&
      onChange({
        sourceDivision: selectedSourceDivision,
        targetDivision: selectedTargetDivision?._id,
        invitationMethod: selectedMethodOption?.value,
        inviteCount: Number(newCount) || 0
      });
  };

  console.log('Show Error: ', showError);

  // const inputSize = undefined;
  const inputSize = 'small';

  return (
    <TableRow key={selectedTargetDivision?._id}>
      <TableCell style={{ paddingLeft: '0px' }}>
        <ArgonTypography variant="caption" fontWeight="regular" color="dark">
          {selectedTargetDivision?.name}
        </ArgonTypography>
      </TableCell>
      <TableCell style={{ paddingRight: '0px' }}>
        <ArgonBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          <ArgonTypography variant="caption" fontWeight="regular" color="dark">
            {selectedSourceDivision?.label}
          </ArgonTypography>
          <ArgonTypography variant="caption" fontWeight="regular" color="dark">
            {sourceEventInstanceLeaderboardDataLoading ? (
              <PulseLoader size={6} color="#E0E0E0" />
            ) : (
              sourceEventInstanceLeaderboardData?.length
            )}
          </ArgonTypography>
        </ArgonBox>
      </TableCell>
      <TableCell style={{ paddingRight: '0px' }} align="left">
        <ArgonTypography variant="caption" fontWeight="regular" color="dark">
          {registrationCountLoading ? (
            <PulseLoader size={6} color="#E0E0E0" />
          ) : (
            registrationCountOfSourceDivision
          )}
        </ArgonTypography>
      </TableCell>
      {/* <TableCell style={{ paddingRight: '0px' }}>
        <ArgonSelect />
      </TableCell> */}
      <TableCell style={{ paddingRight: '0px' }}>
        <ArgonSelect
          options={selectData.autoInvitationMethodOptions}
          size={inputSize}
          value={selectedMethodOption}
          onChange={handleInvitationMethodChanged}
          error={!selectedMethodOption}
        />
      </TableCell>
      <TableCell style={{ paddingRight: '0px' }}>
        <NumberInput
          size={inputSize}
          maxValue={registrationCountOfSourceDivision}
          value={inviteCount || 0}
          onChange={handleInvitationCountChanged}
          // error={plannedInvitations === undefined || plannedInvitations === ''}
          error={selectedSourceDivision && inviteCount === undefined}
        />
      </TableCell>
      {/* <TableCell style={{ paddingRight: '0px' }}>
        <ArgonProgress size={inputSize} value={0} label={'X / Y'} />
      </TableCell> */}
    </TableRow>
  );
};

function EditInvitesPerDivision({
  sourceEventInstanceId,
  targetEventInstanceId,
  divisions,
  invitesPerDivision,
  predecessorDivisions,
  onChange
}) {
  console.log('EditInvitesPerDivision: divisions: ', divisions);
  console.log(
    'EditInvitesPerDivision: invitesPerDivision: ',
    invitesPerDivision
  );
  // console.log(
  //   'AutoInvitationDivisionMapping: predecessorDivisions: ',
  //   predecessorDivisions
  // );
  const [registrationCountLoading, setRegistrationCountLoading] =
    useState(false);

  const [availableSourceDivisions, setAvailableSourceDivisions] = useState(
    predecessorDivisions || []
  );
  const [availablTargetDvisisions, setAvailableTargetDivisions] = useState(
    divisions || []
  );

  // const [mappedDivisions, setMappedDivisions] = useState(
  //   invitesPerDivision || []
  // );

  const [
    registrationCountAcrossAllDivisions,
    setRegistrationCountAcrossAllDivisions
  ] = useState(0);
  const [
    invitationCountAcrossAllDivisions,
    setInvitationCountAcrossAllDivisions
  ] = useState(0);

  useEffect(() => {
    // const mappedDivisions = matchingDivisionsByName({
    //   sourceDivisions: predecessorDivisions,
    //   targetDivisions: divisions
    // });

    setAvailableTargetDivisions(
      divisions?.map((division) => ({
        ...division,
        value: division._id,
        label: division.name,
        sourceDivision: invitesPerDivision.find(
          (md) => md.targetDivision === division._id
        )?.sourceDivision
      }))
    );

    // setAvailableSourceDivisions(
    //   predecessorDivisions?.map((division) => ({
    //     ...division,
    //     value: division._id,
    //     label: division.name
    //   }))
    // );

    setRegistrationCountLoading(true);
    getRegistrationsByDivisionsForEventInstance({
      eventInstanceId: sourceEventInstanceId
    })
      .then((result) => {
        console.log(
          'EditInvitesPerDivision: getRegistrationsByDivisionsForEventInstance: result: ',
          result
        );

        let count = 'n/a';
        if (result?.registrationStatusData)
          count = result.registrationStatusData.reduce(
            (acc, curr) => acc + (Number(curr?.spotsTaken) || 0),
            0
          );
        setRegistrationCountAcrossAllDivisions(count);
      })
      .finally(() => {
        setRegistrationCountLoading(false);
      });
  }, [predecessorDivisions, divisions]);

  useEffect(() => {
    console.log(
      'EditInvitesPerDivision: invitesPerDivision: ',
      invitesPerDivision
    );
    // caclulate total invitations
    const totalInvitations = invitesPerDivision
      .slice()
      .reduce(
        (acc, curr) => (Number(acc) || 0) + (Number(curr?.inviteCount) || 0),
        0
      );
    setInvitationCountAcrossAllDivisions(totalInvitations || 0);
  }, [invitesPerDivision]);

  const handleDivisionMapped = ({ sourceDivision, inviteCount }) => {
    // console.log(
    //   'AutoInvitationDivisionMapping: handleDivisionMapped: targetDivision: ',
    //   targetDivision
    // );
    console.log(
      'EditInvitesPerDivision: handleDivisionMapped: sourceDivision: ',
      sourceDivision
    );

    console.log(
      'EditInvitesPerDivision: handleDivisionMapped: inviteCount: ',
      inviteCount
    );

    // console.log(
    //   'AutoInvitationDivisionMapping: handleDivisionMapped: availableTargetDivisions before update: ',
    //   availablTargetDvisisions
    // );
    console.log(
      'EditInvitesPerDivision: invitesPerDivision before update: ',
      invitesPerDivision
    );

    const updatedInvitesPerDivision = invitesPerDivision.map((division) => {
      if (
        division.divisionFromId?.toString() === sourceDivision?._id?.toString()
      ) {
        console.log(
          'EditInvitesPerDivision: handleDivisionMapped: division: ',
          division
        );
        console.log(
          'EditInvitesPerDivision: handleDivisionMapped: plannedInvitations: ',
          inviteCount
        );
        return {
          ...division,
          // sourceDivision: sourceDivision,
          inviteCount: inviteCount
        };
      }
      return division;
    });

    console.log(
      'EditInvitesPerDivision: handleDivisionMapped: updatedInvitesPerDivision: ',
      updatedInvitesPerDivision
    );

    // now add up the plannedInvitations
    const totalInvitations = updatedInvitesPerDivision
      .slice()
      .reduce(
        (acc, curr) => (Number(acc) || 0) + (Number(curr?.inviteCount) || 0),
        0
      );
    setInvitationCountAcrossAllDivisions(totalInvitations);

    onChange && onChange(updatedInvitesPerDivision);
  };
  const inputSize = 'small';

  return (
    <>
      <Table>
        {/* <TableHead> */}
        <TableRow>
          <TableCell style={{ paddingLeft: '0px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Invite To Division
              <br />
              (this Event Instance)
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '250px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              From Source Division
              <br />
              (preceeding Event Instance)
            </ArgonTypography>
          </TableCell>

          <TableCell style={{ paddingRight: '0px', width: '100px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Registrations in Source Divsion
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '250px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Selection Method
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '150px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              No. of invitations to this Event Instance
            </ArgonTypography>
          </TableCell>
          {/* <TableCell style={{ paddingRight: '0px', width: '250px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Progress
            </ArgonTypography>
          </TableCell> */}
        </TableRow>
        {/* </TableHead>
        <TableBody> */}
        {invitesPerDivision
          // ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          ?.map((division) => {
            console.log('DivisionRow: Rendering Division: ', division);
            console.log(
              'DivisionRow: availableSourceDivisions: ',
              availableSourceDivisions
            );
            console.log(
              'DivisionRow: availablTargetDvisisions: ',
              availablTargetDvisisions
            );

            const fromDivision = availableSourceDivisions.find(
              (asd) =>
                asd?._id?.toString() === division?.divisionFromId?.toString()
            );
            const toDivision = divisions.find(
              (td) => td?._id?.toString() === division?.divisionToId?.toString()
            );

            console.log('DivisionRow: fromDivision: ', fromDivision);
            console.log('DivisionRow: toDivision: ', toDivision);

            if (fromDivision && toDivision)
              return (
                <DivisionRow
                  key={division?._id + division?.sourceDivision}
                  sourceEventInstanceId={sourceEventInstanceId}
                  targetDivision={toDivision}
                  inviteCount={division?.inviteCount}
                  invitationMethod={division?.invitationMethod}
                  availableSourceDivisions={availableSourceDivisions}
                  selectedSourceDivision={fromDivision}
                  selectedTargetDivision={toDivision}
                  // showWarning={
                  //   division?.sourceDivision === undefined
                  //     ? 'Should be mapped to a division, leave empty to not invite into this division'
                  //     : undefined
                  // }
                  showError={
                    availablTargetDvisisions.filter(
                      (atd) =>
                        atd?.sourceDivision?.toString() ===
                        division?.divisionToId?.toString()
                    ).length > 1
                      ? 'Already mapped to another division'
                      : undefined
                  }
                  // plannedInvitations={division?.plannedInvitations}
                  onChange={handleDivisionMapped}
                />
              );
          })}
        {(!divisions || divisions.length === 0) && (
          <TableRow>
            <TableCell colSpan={2} align="center">
              <ArgonTypography
                variant="regular"
                fontWeight="regular"
                color="dark">
                No divisions found
              </ArgonTypography>
            </TableCell>
          </TableRow>
        )}
        {/* </TableBody> */}
        <TableRow>
          <TableCell style={{ paddingLeft: '0px', borderBottom: 'none' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Sums
            </ArgonTypography>
          </TableCell>
          <TableCell
            style={{ paddingRight: '0px', borderBottom: 'none' }}></TableCell>
          <TableCell
            style={{ paddingRight: '0px', borderBottom: 'none' }}
            align="left">
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              {registrationCountAcrossAllDivisions}
            </ArgonTypography>
          </TableCell>
          {/* <TableCell style={{ paddingRight: '0px' }}>
      <ArgonSelect />
    </TableCell> */}
          <TableCell
            style={{ paddingRight: '0px', borderBottom: 'none' }}></TableCell>
          <TableCell style={{ paddingRight: '0px', borderBottom: 'none' }}>
            <ArgonBox pl={1.5}>
              <ArgonTypography variant="caption" fontWeight="bold" color="dark">
                {invitationCountAcrossAllDivisions}
              </ArgonTypography>
            </ArgonBox>
          </TableCell>
          {/* <TableCell style={{ paddingRight: '0px' }}>
      <ArgonProgress size={inputSize} value={0} label={'X / Y'} />
    </TableCell> */}
        </TableRow>
      </Table>
    </>
  );
}

EditInvitesPerDivision.propTypes = {};

export default EditInvitesPerDivision;
