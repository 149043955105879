/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';

function PublishOrDelete({ isPublished, onDelete, onPublish, onUnpublish }) {
  const handlePublishEventButtonClicked = () => {
    onPublish && onPublish(true);
  };

  const handleUnpublishEventButtonClicked = () => {
    onUnpublish && onUnpublish(false);
  };

  return (
    <Card id="publish-delete">
      <ArgonBox p={3} lineHeight={1}>
        <ArgonBox mb={1}>
          <ArgonTypography variant="h5">
            Publish and Delete Event
          </ArgonTypography>
        </ArgonBox>
        <ArgonTypography variant="button" color="text" fontWeight="regular">
          Your event will only be listed on the website if it is published. You
          can unpublish your event at any time. If you delete your event, it
          will be permanently removed from the website. Please be aware that
          registered athletes may claim back participation fees already paid.
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <ArgonBox
          display="flex"
          alignItems="center"
          mb={{ xs: 3, sm: 0 }}></ArgonBox>
        <ArgonBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          {!isPublished && (
            <ArgonButton
              variant="gradient"
              size="small"
              color={'success'}
              onClick={handlePublishEventButtonClicked}
              sx={{ height: '100%', width: '150px' }}>
              Publish Event
            </ArgonButton>
          )}
          {isPublished && (
            <ArgonBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <ArgonButton
                variant="outlined"
                size="small"
                color={'secondary'}
                sx={{ height: '100%', width: '150px' }}
                onClick={handleUnpublishEventButtonClicked}>
                Unpublish Event
              </ArgonButton>
            </ArgonBox>
          )}
          <ArgonBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <ArgonButton
              variant="outlined"
              size="small"
              color="secondary"
              sx={{ height: '100%', width: '150px' }}>
              Delete Event
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default PublishOrDelete;
