/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useRef, useState } from 'react';

// porp-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-chartjs-2 components
import { Line } from 'react-chartjs-2';

// @mui material components
import Card from '@mui/material/Card';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// DefaultLineChart configurations
// import configs from 'examples/Charts/LineCharts/DefaultLineChart/configs';
import configs from './configs';
// import configs from 'examples/Charts/LineCharts/GradientLineChart/configs';

import gradientChartLine from 'assets/theme/functions/gradientChartLine';

// Argon Dashboard 2 PRO MUI base styles
import colors from 'assets/theme/base/colors';
import ArgonSelect from 'components/ArgonSelect';
import {
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
// import StyledToggleButton from 'components/StyledToggleButton';

function DefaultLineChart({
  title,
  description,
  height,
  chart,
  showSelectionFilter,
  onSelectionFilterChange,
  selectionFilterValue
}) {
  // const chartRef = useRef(null);
  // const [chartData, setChartData] = useState({});
  // const { data, options } = chartData;

  // useEffect(() => {
  //   if (chart) {
  //     console.log('Drawing chart....', chart);
  //     const chartDatasets = chart.datasets
  //       ? chart.datasets.map((dataset) => ({
  //           ...dataset,
  //           tension: 0.4,
  //           pointRadius: 0,
  //           borderWidth: 3,
  //           borderColor: colors[dataset.color]
  //             ? colors[dataset.color || 'dark'].main
  //             : colors.dark.main,
  //           fill: true,
  //           maxBarThickness: 6,
  //           backgroundColor: gradientChartLine(
  //             chartRef.current.children[0],
  //             colors[dataset.color]
  //               ? colors[dataset.color || 'dark'].main
  //               : colors.dark.main
  //           )
  //         }))
  //       : [];
  //     setChartData(configs(chart.labels || [], chartDatasets));
  //   }
  // }, [chart]);

  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0.4,
        borderWidth: 3,
        // pointRadius: 2,
        pointRadius: 0,
        pointBackgroundColor: colors[dataset.color]
          ? colors[dataset.color || 'dark'].main
          : colors.dark.main,
        borderColor: colors[dataset.color]
          ? colors[dataset.color || 'dark'].main
          : colors.dark.main,
        maxBarThickness: 6
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const selectionFilterOptions = [
    { value: 'day', label: 'Per day' },
    { value: 'week', label: 'Per week' },
    { value: 'month', label: 'Per month' }
    // { value: 'year', label: 'Year' }
  ];

  const handleSelectionFilterChange = (option) => {
    console.log('Selection filter changed: ', option);
    // setSelectionFilterValue(event.target.value);
    onSelectionFilterChange(option);
  };

  const handleToggleButtonChanged = (event, newSelectionFilterValue) => {
    console.log('Selection filter changed: ', newSelectionFilterValue);
    // setSelectionFilterValue(event.target.value);
    onSelectionFilterChange(newSelectionFilterValue);
  };

  const renderChart = (
    <ArgonBox p={2}>
      {title || description ? (
        <ArgonBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          <ArgonBox display="flex" justifyContent="space-between">
            {title && (
              <ArgonBox mb={1}>
                <ArgonTypography variant="h6">{title}</ArgonTypography>
              </ArgonBox>
            )}

            {/* <ToggleButtonGroup
              size="small"
              orientation="horizontal"
              value={selectionFilterValue}
              exclusive
              onChange={handleToggleButtonChanged}>
    
              <ToggleButton value="YTD" aria-label="list" sx={{ border: 0 }}>
                YTD
              </ToggleButton>
              <ToggleButton value="6M" aria-label="list" sx={{ border: 0 }}>
                6 M
              </ToggleButton>
              <ToggleButton value="12W" aria-label="list" sx={{ border: 0 }}>
                12 W
              </ToggleButton>
              <ToggleButton value="90D" aria-label="list" sx={{ border: 0 }}>
                90 D
              </ToggleButton>
            </ToggleButtonGroup> */}

            {/* <ButtonGroup
              variant="text"
              size="small"
              aria-label="outlined button group">
              <Button>YTD</Button>
              <Button>6 M</Button>
              <Button>12 W</Button>
              <Button>90 D</Button>
            </ButtonGroup> */}

            {showSelectionFilter && (
              <ArgonSelect
                id="selectionFilter"
                label="Filter"
                size="small"
                options={selectionFilterOptions}
                value={selectionFilterValue || 'day'}
                onChange={handleSelectionFilterChange}
              />
            )}
          </ArgonBox>

          <ArgonBox mb={2}>
            <ArgonTypography
              component="div"
              variant="button"
              fontWeight="regular"
              color="text">
              {description}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      ) : null}
      {useMemo(
        () => (
          <ArgonBox
            // ref={chartRef}
            height={height}>
            <Line data={data} options={options} />
          </ArgonBox>
        ),
        [chart, height]
      )}
    </ArgonBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
  title: '',
  description: '',
  height: '19.125rem',
  showSelectionFilter: false,
  onSelectionFilterChange: () => {},
  selectionFilterValue: 'day'
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
  showSelectionFilter: PropTypes.bool,
  onSelectionFilterChange: PropTypes.func,
  selectionFilterValue: PropTypes.object
};

export default DefaultLineChart;
