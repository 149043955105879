import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogWrapper from 'components/DialogWrapper';
import { Grid } from '@mui/material';
import ArgonInput from 'components/ArgonInput';
import FormField from 'components/FormField';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import selectData from 'options/selectData';
import { createStripeTaxRate } from 'services/REST_API/stripe';
import { useSnackbar } from 'notistack';
import { getAllStripeTaxRates } from 'services/REST_API/stripe';

function CreateTaxRateDialog({
  organizationId,
  open,
  handleClose,
  onNewTaxRateCreated
}) {
  console.log('CreateTaxRateDialog organizationId: ', organizationId);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      organizationId: organizationId,
      name: '',
      taxRateInteger: '',
      countryCode: '',
      jurisdiction: '',
      inclusive: true
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      taxRateInteger: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(100, 'Must be less than or equal to 100'),
      countryCode: Yup.string().required('Required'),
      jurisdiction: Yup.string().required('Required'),
      inclusive: Yup.boolean().required('Required')
    }),
    onSubmit: async (values) => {
      console.log('values: ', values);
      await createStripeTaxRate({
        organizationId: values.organizationId,
        name: values.name,
        countryCode: values.countryCode,
        jurisdiction: values.jurisdiction,
        taxRateInteger: values.taxRateInteger,
        description: values.description,
        inclusive: values.inclusive
      })
        .then((data) => {
          console.log('data from REST API: ', data);

          formik?.resetForm();
          enqueueSnackbar('Tax rate created successfully', {
            variant: 'success'
          });
          onNewTaxRateCreated && onNewTaxRateCreated(data);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('Tax rate creation failed', {
            variant: 'error'
          });
        });
    }
  });

  const taxInclusiveOptions = [
    { value: true, label: 'Tax is included in price' },
    { value: false, label: 'Tax will be added to price' }
  ];

  console.log('CreateNew Tax Rate formik errors: ', formik.errors);

  return (
    <>
      <DialogWrapper
        title="Create New Tax Rate"
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth>
        <FormField
          id="name"
          name="name"
          required
          label="Name (as shown on invoice)"
          placeholder="E.g. USt. DE 19%"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched?.name && Boolean(formik.errors.name)}
          helperText={formik.touched?.name && formik.errors.name}
        />
        <FormField
          id="taxRateInteger"
          name="taxRateInteger"
          required
          label="Tax rate in percent"
          placeholder="E.g. 19"
          value={formik.values.taxRateInteger}
          onChange={formik.handleChange}
          error={
            formik.touched?.taxRateInteger &&
            Boolean(formik.errors.taxRateInteger)
          }
          helperText={
            formik.touched?.taxRateInteger && formik.errors.taxRateInteger
          }
        />
        <ArgonBox mb={0}>
          <ArgonBox ml={0.5}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Jurisdiction <span style={{ color: 'red' }}>*</span>
            </ArgonTypography>
          </ArgonBox>
          <ArgonSelect
            id="country"
            name="country"
            // size="small"
            overflow={true}
            placeholder="Please choose"
            options={selectData.countries}
            // value={formik.values.organizationType}
            value={selectData.countries.filter(
              (option) => option.code2 === formik.values.jurisdiction
            )}
            onChange={async (option) => {
              console.log('option', option);
              formik.setFieldValue('countryCode', option.code2, false);
              formik.setFieldValue('jurisdiction', option.code2, false);
            }}
            // onChange={(option) =>
            //   handleSelectMenuChanged('sports', option.value)
            // }
            error={
              formik.touched.jurisdiction && Boolean(formik.errors.jurisdiction)
            }
            helperText={
              formik.touched.jurisdiction && formik.errors.jurisdiction
            }
          />
        </ArgonBox>
        <ArgonBox mb={0}>
          <ArgonBox ml={0.5}>
            <ArgonTypography
              variant="caption"
              fontWeight="bold"
              color="dark"
              textTransform="capitalize">
              Tax included? <span style={{ color: 'red' }}>*</span>
            </ArgonTypography>
          </ArgonBox>
          <ArgonSelect
            id="inclusive"
            name="inclusive"
            overflow={true}
            placeholder="Please choose"
            options={taxInclusiveOptions}
            // value={formik.values.organizationType}
            value={taxInclusiveOptions?.filter(
              (option) => option.value === formik.values.inclusive
            )}
            onChange={async (option) => {
              console.log('option', option);
              formik.setFieldValue('inclusive', option.value, false);
            }}
            // onChange={(option) =>
            //   handleSelectMenuChanged('sports', option.value)
            // }
            error={formik.touched.inclusive && Boolean(formik.errors.inclusive)}
            helperText={formik.touched.inclusive && formik.errors.inclusive}
          />
        </ArgonBox>
        <ArgonBox
          mt={3}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <ArgonButton
            name="cancelButton"
            variant="outlined"
            size="small"
            color="secondary"
            onClick={handleClose}>
            Cancel
          </ArgonButton>
          <ArgonButton
            name="saveButton"
            variant="gradient"
            size="small"
            color="success"
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}>
            Save
          </ArgonButton>
        </ArgonBox>
      </DialogWrapper>
    </>
  );
}

CreateTaxRateDialog.propTypes = {
  organizationId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onNewTaxRateCreated: PropTypes.func.isRequired
};

export default CreateTaxRateDialog;
