import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

export const getScoresStatisticsByJudge = ({
  eventInstanceId,
  divisionId,
  workoutId
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/judging/per-judge`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        divisionId,
        workoutId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getScoresStatisticsProgress = ({
  eventInstanceId,
  divisionId,
  workoutId,
  judgeId
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/judging/progress`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        divisionId,
        workoutId,
        judgeId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getScoresStatisticsProgressPerJudge = ({
  eventInstanceId,
  divisionId,
  workoutId,
  judgeId
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/judging/progress-per-judge`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        divisionId,
        workoutId,
        judgeId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getScoringHeatCompletionPercentage = ({
  eventInstanceId,
  workoutId,
  heatNumber
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/scoring/organizer/heat/completion`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        workoutId,
        heatNumber
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getScoringWorkoutCompletionPercentage = ({
  eventInstanceId,
  workoutId
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/scoring/organizer/workout/completion`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        workoutId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getEventInstanceAutoInvitationStatistics = ({ eventInstanceId }) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/event-instance/auto-invitations`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
