import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArgonBox from 'components/ArgonBox';
import TooltipWrapper from 'components/TooltipWrapper';
import ArgonTypography from 'components/ArgonTypography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArgonSelect from 'components/ArgonSelect';

import { countryCodeOptions } from 'options/countryCodes';
import { countryCodeOptionsGroupedByRegion } from 'options/countryCodes';
import MobileFriendlySelect from 'components/MobileFriendlySelect';

function FormikCountrySelector({
  id,
  name,
  label,
  tooltip,
  required,
  showFlags,
  textColor,
  formik,
  onChange, // to handle additional actions when the field value changes

  ...rest
}) {
  //   const options = countryCodeOptions();

  console.log('FormikCountrySelector:', formik);

  console.log(
    'Country Selector Error:',
    name,
    formik?.touched?.[name],
    Boolean(formik?.errors?.[name])
  );

  const options = countryCodeOptionsGroupedByRegion();

  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    setSelectedOption(
      options
        .map((region) => region.options)
        .flat()
        .find((country) => country['alpha-2'] === formik?.values?.[name])
    );
  }, [formik]);

  return (
    <div>
      {label && (
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <TooltipWrapper tooltip={tooltip}>
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color={textColor}
              {...rest}>
              {label}&nbsp;
              {required && (
                // <sup>
                <span style={{ color: 'red' }}>*</span>
                // </sup>
              )}
              {tooltip && (
                <ArgonBox ml={1} display="inline-block">
                  <ArgonTypography variant="regular">
                    <InfoOutlinedIcon color="secondary" />
                  </ArgonTypography>
                </ArgonBox>
              )}
            </ArgonTypography>
          </TooltipWrapper>
        </ArgonBox>
      )}

      <ArgonSelect
        key={selectedOption?.value}
        options={options}
        id={id}
        name={name}
        overflow
        // value={{ value: ' test', label: 'test' }}
        value={selectedOption}
        onChange={async (option) => {
          console.log('option:', option);
          setSelectedOption(option);
          await formik.setFieldValue(name, option.value);
          onChange && onChange(option);
        }}
        error={formik?.touched?.[name] && Boolean(formik.errors?.[name])}
        helperText={formik?.touched?.[name] && formik.errors?.[name]}
        // error={Boolean(formik.errors[name])}
        // helperText={formik.errors[name]}
        // style={{ width: width }}
        {...rest}
      />
    </div>
  );
}

FormikCountrySelector.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  showFlags: PropTypes.bool,
  textColor: PropTypes.string,
  formik: PropTypes.object.isRequired
};

export default FormikCountrySelector;
