export function removeGqlGarbageFromArrays(array) {
  if (!array || !Array.isArray(array)) return [];

  return array?.map((item) => {
    delete item.__typename;
    delete item.__schema;
    delete item.__type;
    return item;
  });
}
