import React, { Component, useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { isMobile } from 'react-device-detect';
import colors from 'assets/theme/base/colors';
// import ReactSelect from 'react-select';
import ArgonSelect from 'components/ArgonSelect';
import { useArgonController } from 'context';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

export default function MobileFriendlySelect({
  id,
  name,
  options,
  value,
  error,
  helperText,
  label,
  labelColor,
  required,
  onChange,
  rest
}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { light, dark } = colors;

  console.log('MobileFriendlySelect: Options: ', options);

  const [selectedOption, setSelectedOption] = useState(value);
  const handleSelectChange = useCallback(
    (event) => {
      console.log('MobileFriendlySelect: Value: ', event?.target?.value);
      console.log('MobileFriendlySelect: Options to look in: ', options);

      const option = options.find(
        (option) => option.value === event?.target?.value
      );
      console.log('MobileFriendlySelect: Selected Option:', option);
      setSelectedOption(option);
      onChange &&
        // onChange(options.find(({ val }) => val === event.target?.value)?.value);
        onChange(option);
    },

    [options]
  );

  //   const handleSelectChange = (event) => {
  //     //     (event) => {
  //     console.log('Event: ', event);
  //     console.log(
  //       'Selected Option:',
  //       options.find(({ val }) => val === event.target?.value)
  //     );
  //     setSelectedOption(options.find(({ val }) => val === event.target?.value));
  //     onChange &&
  //       // onChange(options.find(({ val }) => val === event.target?.value)?.value);
  //       onChange(options.find(({ val }) => val === event.target?.value));
  //   };

  //     []
  //   );
  //   console.log('Value: ', value);
  //   console.log('SelectedOption: ', selectedOption);

  //   useEffect(() => {
  //     setSelectedOption(options?.find(({ val }) => val === value));
  //   }, [value, options]);

  const isDesktopOrAdvancedFeaturesAreEnabled = !isMobile;
  if (isDesktopOrAdvancedFeaturesAreEnabled) {
    return (
      <>
        {label && (
          <>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color={labelColor || (darkMode ? dark.main : light.main)}
                textTransform="capitalize">
                {label}
                {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
              </ArgonTypography>
            </ArgonBox>
          </>
        )}
        <ArgonSelect
          id={id}
          name={name}
          options={options}
          value={selectedOption}
          onChange={(selectedOption) => {
            setSelectedOption(selectedOption);
            onChange && onChange(selectedOption);
          }}
          error={error}
          helperText={helperText}
          {...rest}
        />
      </>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <select
        key={options}
        style={{
          position: 'absolute',
          opacity: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
        value={selectedOption ? selectedOption.value : ''}
        onChange={handleSelectChange}>
        {/* <option value="" disabled style={{ display: 'none' }}>
          Select...
        </option> */}
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <ArgonSelect
        id={id}
        name={name}
        options={options}
        // onChange={(selectedOption) => setSelectedOption(selectedOption)}
        onChange={(selectedOption) => {
          setSelectedOption(selectedOption);
          onChange && onChange(selectedOption);
        }}
        error={error}
        helperText={helperText}
        value={selectedOption}
        {...rest}
      />
    </div>
  );
}
