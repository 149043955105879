import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArgonTypography from 'components/ArgonTypography';
import ArgonBox from 'components/ArgonBox';
import ArgonSelect from 'components/ArgonSelect';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import NumberInput from 'components/NumberInput';
import ArgonProgress from 'components/ArgonProgress';
import { getRegistrationsByDivisionsForEventInstance } from 'services/restApi';
import { PulseLoader } from 'react-spinners';
import { getLeaderboardData } from 'services/restApi';

const methodOptions = [{ value: 'top', label: 'Top ranks with fill up' }];

const DivisionRow = ({
  sourceEventInstanceId,
  targetDivision,
  availableSourceDivisions,
  selectedSourceDivision,
  invitationMethod,
  plannedInvitations,
  showError,
  showWarning,
  onChange
}) => {
  console.log(
    'DivisionRow: selectedSourceDivision: ',
    availableSourceDivisions,
    targetDivision,
    selectedSourceDivision
  );

  const [
    sourceEventInstanceLeaderboardData,
    setSourceEventInstanceLeaderboardData
  ] = useState();
  const [
    sourceEventInstanceLeaderboardDataLoading,
    setSourceEventInstanceLeaderboardDataLoading
  ] = useState(false);

  const [selectedMethodOption, setSelectedMethodOption] = useState(
    // methodOptions.find((mo) => mo.value === invitationMethod) ||
    methodOptions[0]
  );

  // const [matchedDivision, setMatchedDivision] = useState(selectedSourceDivision);

  const [registrationCountLoading, setRegistrationCountLoading] =
    useState(false);
  const [
    registrationCountOfSourceDivision,
    setRegistrationCountOfSourceDivision
  ] = useState();

  const [numberOfAthletesToBeInvited, setNumberOfAthletesToBeInvited] =
    useState(plannedInvitations);

  useEffect(() => {
    // fetch registration count of source division
    if (sourceEventInstanceId) {
      setRegistrationCountLoading(true);
      getRegistrationsByDivisionsForEventInstance({
        eventInstanceId: sourceEventInstanceId
      })
        .then((result) => {
          console.log(
            'DivisionRow: getRegistrationsByDivisionsForEventInstance: result: ',
            result
          );

          let count = 'n/a';
          if (result?.registrationStatusData)
            count =
              result.registrationStatusData?.find(
                (rsd) => rsd.divisionId === selectedSourceDivision?._id
              )?.spotsTaken || '0';
          setRegistrationCountOfSourceDivision(count);
        })
        .finally(() => {
          setRegistrationCountLoading(false);
        });
    }

    if (selectedSourceDivision) {
      setSourceEventInstanceLeaderboardDataLoading(true);
      getLeaderboardData({
        eventInstanceId: sourceEventInstanceId,
        divisionId: selectedSourceDivision?._id
      })
        .then((result) => {
          console.log(
            'AutoInvitationDivisionMapping: DivisionRow: getLeaderboardData for division: ',
            selectedSourceDivision?.name,
            result
          );
          setSourceEventInstanceLeaderboardData(result);
        })
        .finally(() => {
          setSourceEventInstanceLeaderboardDataLoading(false);
        });
    }
  }, [sourceEventInstanceId]);

  const handleDivisionChanged = async (newOption) => {
    // setMatchedDivision(newOption);
    console.log('DivisionRow: handleDivisionChanged: newOption: ', newOption);

    console.log('DivisionRow: handleDivisionChanged: onChange: ', {
      sourceDivision: newOption?._id,
      targetDivision: targetDivision?._id,
      invitationMethod: selectedMethodOption.value,
      plannedInvitations: numberOfAthletesToBeInvited
    });
    if (newOption?._id) {
      setSourceEventInstanceLeaderboardDataLoading(true);
      await getLeaderboardData({
        eventInstanceId: sourceEventInstanceId,
        divisionId: newOption?._id
      })
        .then((result) => {
          console.log(
            'AutoInvitationDivisionMapping: DivisionRow: getLeaderboardData for changed division: ',
            selectedSourceDivision,
            result
          );
          setSourceEventInstanceLeaderboardData(result);
        })
        .catch((error) => {
          console.error(
            'AutoInvitationDivisionMapping: DivisionRow: getLeaderboardData for division: ',
            selectedSourceDivision?.name,
            error
          );
        })
        .finally(() => {
          setSourceEventInstanceLeaderboardDataLoading(false);
        });
    }

    onChange &&
      onChange({
        // sourceDivision: newOption?._id,
        // targetDivision: targetDivision?._id,
        sourceDivision: newOption,
        targetDivision: targetDivision,
        invitationMethod: selectedMethodOption.value,
        plannedInvitations: numberOfAthletesToBeInvited
      });
  };

  console.log('Show Error: ', showError);
  console.log(
    'DivisionRow: selectedSourceDivision: plannedInvitations:',
    plannedInvitations,
    typeof plannedInvitations
  );

  // const inputSize = undefined;
  const inputSize = 'small';

  return (
    <TableRow key={targetDivision?._id}>
      <TableCell style={{ paddingLeft: '0px', width: '50%' }}>
        <ArgonTypography variant="caption" fontWeight="bold" color="dark">
          {targetDivision?.name}
        </ArgonTypography>
      </TableCell>
      <TableCell style={{ paddingRight: '0px', maxWidth: '50%' }}>
        <ArgonBox>
          <ArgonSelect
            options={availableSourceDivisions}
            onChange={handleDivisionChanged}
            value={selectedSourceDivision}
            success={selectedSourceDivision}
            overflow={true}
            // error={showError}
            isClearable
            size={inputSize}
            // error={!selectedSourceDivision }
            error={
              showError ||
              (!sourceEventInstanceLeaderboardDataLoading &&
                sourceEventInstanceLeaderboardData?.data?.length === 0)
            }
            isLoading={sourceEventInstanceLeaderboardDataLoading}
          />
          {!sourceEventInstanceLeaderboardDataLoading &&
            sourceEventInstanceLeaderboardData?.data?.length === 0 && (
              <ArgonBox mt={-0.5} ml={0.5}>
                <ArgonTypography
                  variant="caption"
                  fontWeight="regular"
                  color="error">
                  No leaderboard data found for this division
                </ArgonTypography>
              </ArgonBox>
            )}
          {showError && (
            <ArgonBox mt={-0.5} ml={0.5}>
              <ArgonTypography
                variant="caption"
                fontWeight="regular"
                color="error">
                {showError}
              </ArgonTypography>
            </ArgonBox>
          )}
          {showWarning && (
            <ArgonBox mt={-0.5} ml={0.5}>
              <ArgonTypography
                variant="caption"
                fontWeight="regular"
                color="warning">
                {showWarning}
              </ArgonTypography>
            </ArgonBox>
          )}
        </ArgonBox>
      </TableCell>
    </TableRow>
  );
};

function AutoInvitationDivisionMapping({
  invitesPerDivision,
  sourceEventInstanceId,
  targetEventInstanceId,
  divisions,
  predecessorDivisions,
  onChange
}) {
  // console.log('AutoInvitationDivisionMapping: divisions: ', divisions);
  console.log(
    'AutoInvitationDivisionMapping: predecessorDivisions: ',
    predecessorDivisions
  );

  const [availableSourceDivisions, setAvailableSourceDivisions] = useState(
    predecessorDivisions || []
  );
  const [availablTargetDvisisions, setAvailableTargetDivisions] = useState(
    divisions || []
  );

  const [mappedDivisions, setMappedDivisions] = useState([]);

  // useEffect(() => {
  //   // setUnmappedPredecessorDivisions(predecessorDivisions);

  // //   setMappedDivisions(
  // //     matchingDivisionsByName({
  // //       sourceDivisions: predecessorDivisions,
  // //       targetDivisions: divisions
  // //     })
  // //   );
  // }, [predecessorDivisions, divisions]);

  // useEffect(() => {
  //   setAvailableSourceDivisions(
  //     predecessorDivisions.map((division) => ({
  //       ...division,
  //       value: division._id,
  //       label: division.name,
  //       disabled: true
  //     }))
  //   );
  // }, [predecessorDivisions]);

  useEffect(() => {
    if (invitesPerDivision?.length > 0) {
      console.log(
        'AutoInvitationDivisionMapping: taking existing mapping from prop, no new mapping performed.',
        invitesPerDivision
      );

      console.log('AutoInvitationDivisionMapping: divisions: ', divisions);

      const mappedTargetDivisions = divisions?.map((division) => ({
        ...division,
        value: division._id,
        label: division.name,
        sourceDivision: invitesPerDivision.find(
          (md) => md?.divisionToId?.toString() === division._id.toString()
        )?.divisionFromId,
        divisionFromId: invitesPerDivision.find(
          (md) => md?.divisionToId?.toString() === division._id.toString()
        )?.divisionFromId,
        divisionFromName: invitesPerDivision.find(
          (md) => md?.divisionToId?.toString() === division._id.toString()
        )?.divisionFromName,
        divisionToId: division._id,
        divisionToName: division.name,
        inviteCount: invitesPerDivision.find(
          (md) => md?.divisionToId?.toString() === division._id.toString()
        )?.inviteCount,
        invitationMethod: invitesPerDivision.find(
          (md) => md?.divisionToId?.toString() === division._id.toString()
        )?.invitationMethod
      }));

      console.log(
        'AutoInvitationDivisionMapping: mappedTargetDivisions: ',
        mappedTargetDivisions
      );

      setAvailableTargetDivisions(mappedTargetDivisions);
    } else {
      // doing division auto-mapping by name
      const mappedDivisions = matchingDivisionsByName({
        sourceDivisions: predecessorDivisions,
        targetDivisions: divisions
      });

      const mappedTargetDivisions = divisions?.map((division) => ({
        ...division,
        value: division._id,
        label: division.name,
        sourceDivision: mappedDivisions.find(
          (md) => md.targetDivision === division._id
        )?.sourceDivision,
        divisionFromId: mappedDivisions.find(
          (md) => md.targetDivision === division._id
        )?.sourceDivision,
        divisionFromName: mappedDivisions.find(
          (md) => md.targetDivision === division._id
        )?.sourceDivisionName,
        divisionToId: division._id,
        divisionToName: division.name,
        inviteCount: 0,
        invitationMethod: 'top'
      }));

      setAvailableTargetDivisions(mappedTargetDivisions);

      // setAvailableSourceDivisions(
      //   predecessorDivisions?.map((division) => ({
      //     ...division,
      //     value: division._id,
      //     label: division.name
      //   }))
      // );

      onChange && onChange(mappedTargetDivisions);

      console.log(
        'AutoInvitationDivisionMapping: result of auto-mapping divisions: ',
        mappedTargetDivisions
      );
    }
    console.log(
      'AutoInvitationDivisionMapping: predecessorDivisions: ',
      predecessorDivisions
    );
    setAvailableSourceDivisions(
      predecessorDivisions?.map((division) => ({
        ...division,
        value: division._id,
        label: division.name
      }))
    );

    // onChange && onChange(mappedDivisions);
  }, [predecessorDivisions, divisions]);

  const matchingDivisionsByName = ({ sourceDivisions, targetDivisions }) => {
    const matchedDivisions = [];

    targetDivisions?.forEach((targetDivision) => {
      const matchingDivision = sourceDivisions.find(
        (division) => division.name === targetDivision.name
      );

      if (matchingDivision) {
        matchedDivisions.push({
          targetDivision: targetDivision?._id, // participants will be invited to this division
          sourceDivision: matchingDivision?._id, // from preceeding event instance's division

          targetDivisionName: targetDivision?.name,
          sourceDivisionName: matchingDivision?.name,

          divisionToId: targetDivision?._id,
          divisionFromId: matchingDivision?._id,
          divisionFromName: matchingDivision?.name,
          divisionToName: targetDivision?.name
        });
      }
    });
    console.log('Divisions matched by name: ', matchedDivisions);
    return matchedDivisions;
  };

  const handleDivisionMapped = ({
    targetDivision,
    sourceDivision,
    // plannedInvitations,
    invitationMethod
  }) => {
    console.log(
      'AutoInvitationDivisionMapping: handleDivisionMapped: targetDivision: ',
      targetDivision
    );
    console.log(
      'AutoInvitationDivisionMapping: handleDivisionMapped: sourceDivision: ',
      sourceDivision
    );
    // console.log(
    //   'AutoInvitationDivisionMapping: handleDivisionMapped: plannedInvitations: ',
    //   plannedInvitations
    // );

    // replace the division in availablTargetDvisisions with the new sourceDivision in place of the old one
    const newTargetDivisions = availablTargetDvisisions.map((division) => {
      if (division._id === targetDivision._id) {
        return {
          ...division,
          sourceDivision: sourceDivision?._id,
          sourceDivisionId: sourceDivision?._id,
          sourceDivisionName: sourceDivision?.name,
          targetDivisionId: targetDivision._id,
          targetDivisionName: availablTargetDvisisions.find(
            (td) => td._id.toString() === targetDivision.toString()
          )?.name,
          // plannedInvitations: plannedInvitations,
          invitationMethod: invitationMethod,

          divisionFromId: sourceDivision?._id,
          divisionFromName: sourceDivision?.name,
          divisionToId: targetDivision._id,
          divisionToName: availablTargetDvisisions.find(
            (td) => td._id.toString() === targetDivision.toString()
          )?.name
        };
      }
      return division;
    });
    console.log(
      'AutoInvitationDivisionMapping: handleDivisionMapped: Updated target divisions:',
      newTargetDivisions
    );

    setAvailableTargetDivisions(newTargetDivisions);

    onChange && onChange(newTargetDivisions);
  };
  const inputSize = undefined;

  return (
    <>
      <Table>
        <TableRow>
          <TableCell style={{ paddingLeft: '0px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Invite To Division
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '250px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              From Source Division (Preceeding Event Instance)
            </ArgonTypography>
          </TableCell>
        </TableRow>

        {availablTargetDvisisions
          // ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          ?.map((division) => {
            console.log('Rendering Division: ', division);
            console.log(
              'AutoInvitationDivisionMapping: source division already mapped to another target division?: ',
              availablTargetDvisisions.filter(
                (atd) =>
                  atd?.sourceDivision?.toString() ===
                  division?.sourceDivision?.toString()
              ).length > 1
            );

            return (
              <DivisionRow
                key={division?._id + division?.sourceDivision}
                sourceEventInstanceId={sourceEventInstanceId}
                targetDivision={division}
                availableSourceDivisions={availableSourceDivisions}
                selectedSourceDivision={availableSourceDivisions?.find(
                  (asd) =>
                    asd?._id?.toString() ===
                    division?.sourceDivision?.toString()
                )}
                // showWarning={
                //   division?.sourceDivision === undefined
                //     ? 'Should be mapped to a division, leave empty to not invite into this division'
                //     : undefined
                // }
                showError={
                  availablTargetDvisisions.filter(
                    (atd) =>
                      atd?.sourceDivision?.toString() ===
                      division?.sourceDivision?.toString()
                  ).length > 1
                    ? 'Already mapped to another division'
                    : undefined
                }
                plannedInvitations={division?.plannedInvitations}
                onChange={handleDivisionMapped}
              />
            );
          })}
        {(!divisions || divisions.length === 0) && (
          <TableRow>
            <TableCell colSpan={2} align="center">
              <ArgonTypography
                variant="regular"
                fontWeight="regular"
                color="dark">
                No divisions found
              </ArgonTypography>
            </TableCell>
          </TableRow>
        )}
        {/* </TableBody> */}
      </Table>
    </>
  );
}

AutoInvitationDivisionMapping.propTypes = {};

export default AutoInvitationDivisionMapping;
