/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';
import ArgonBadge from 'components/ArgonBadge';

import TableCell from 'layouts/pages/account/settings/components/TableCell';

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from 'context';
import { Table, TableBody, TableRow } from '@mui/material';
import ArgonDropzoneS3ButtonOnly from 'components/ArgonDropzoneS3ButtonOnly';
import { useState } from 'react';
import { EVENT_UPDATE } from 'GraphQL/Events/mutations';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { uriEncode } from 'services/s3UriEncode';

function LegalDocs({ event }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { enqueueSnackbar } = useSnackbar();

  function truncate(str, n) {
    const theString = decodeURI(str).replace(/%2B/g, ' ');
    return theString.length > n ? theString.slice(0, n - 1) + '...' : theString;
  }

  const currentTosFileName =
    event?.termsOfServiceUrl && event?.termsOfServiceUrl?.split('/').pop();
  const currentLiabilityWaiverFileName =
    event?.liabilityWaiverUrl && event?.liabilityWaiverUrl?.split('/').pop();
  const currentPrivacyPolicyFileName =
    event?.privacyPolicyUrl && event?.privacyPolicyUrl?.split('/').pop();

  const [gqlUpdateEvent] = useMutation(EVENT_UPDATE);

  const updateEventWithLegalDocs = async ({
    tosUrl,
    liabilityWaiverUrl,
    privacyPolicyUrl
  }) => {
    let correctTosUrl =
      tosUrl &&
      tosUrl
        .replace('public-media-temp', 'public-media')
        .replace(/%20/g, '%2B');

    console.log(
      'TOS URL before final conversion to prevent Umlaute: ',
      correctTosUrl
    );

    correctTosUrl =
      correctTosUrl &&
      correctTosUrl
        .split('/')
        .map((part, index) => {
          if (index === 0) {
            return part;
          } else {
            return encodeURIComponent(part);
          }
        })
        .join('/');

    console.log('Correct TOS URL: ', correctTosUrl);

    let correctLiabilityWaiverUrl =
      liabilityWaiverUrl &&
      liabilityWaiverUrl
        ?.replace('public-media-temp', 'public-media')
        .replace(/%20/g, '%2B');

    correctLiabilityWaiverUrl =
      correctLiabilityWaiverUrl &&
      correctLiabilityWaiverUrl
        .split('/')
        .map((part, index) => {
          if (index === 0) {
            return part;
          } else {
            return encodeURIComponent(part);
          }
        })
        .join('/');

    console.log('Correct Liability Waiver URL: ', correctLiabilityWaiverUrl);

    let correctPrivacyPolicyUrl =
      privacyPolicyUrl &&
      privacyPolicyUrl
        .replace('public-media-temp', 'public-media')
        .replace(/%20/g, '%2B');

    correctPrivacyPolicyUrl =
      correctPrivacyPolicyUrl &&
      correctPrivacyPolicyUrl
        .split('/')
        .map((part, index) => {
          if (index === 0) {
            return part;
          } else {
            return encodeURIComponent(part);
          }
        })
        .join('/');

    console.log('Correct Privacy Policy URL: ', correctPrivacyPolicyUrl);

    await gqlUpdateEvent({
      variables: {
        eventId: event?._id,
        data: {
          termsOfServiceUrl: correctTosUrl,
          liabilityWaiverUrl: correctLiabilityWaiverUrl,
          privacyPolicyUrl: correctPrivacyPolicyUrl
        }
      }
    })
      .then(async (result) => {
        console.log('Result from updateEvent: ', result);
        enqueueSnackbar('Document uploaded successfully.', {
          variant: 'success'
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error'
        });
      });
  };

  return (
    <Card id="2fa" sx={{ overflow: 'visible' }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}>
        <ArgonTypography variant="h5">Legal Documents</ArgonTypography>
      </ArgonBox>
      <ArgonBox p={3}>
        <Table sx={{ minWidth: '36rem', widht: '100%' }}>
          <ArgonBox component="thead">
            {/* <TableRow>
              <TableCell width="auto" padding={[1, 1, 1, 0.5]}></TableCell>
              <TableCell align="center" padding={[1, 1, 1, 0.5]}></TableCell>
            </TableRow> */}
          </ArgonBox>
          <TableBody>
            <TableRow>
              <TableCell width="100%" padding={[1, 2, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Terms of Service
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    Upload a PDF file with your Terms of Service. This document
                    will be the basis upon which athletes register to and
                    participates in your events. It is important that you have a
                    legal document that protects your organization and your
                    athletes.
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell
                // width="100%"
                padding={[1, 1, 1, 0.5]}>
                <ArgonBox
                  display="flex"
                  alignItems={{ xs: 'flex-end', sm: 'center' }}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  gap={2}>
                  {/* <ArgonBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}> */}
                  <ArgonBox>
                    {!event?.termsOfServiceUrl ? (
                      <ArgonTypography
                        variant="button"
                        color="text"
                        fontWeight="regular">
                        <ArgonBadge
                          variant="contained"
                          color="warning"
                          badgeContent="required"
                          container
                        />
                      </ArgonTypography>
                    ) : (
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        noWrap
                        fontWeight="regular">
                        {truncate(decodeURI(currentTosFileName), 35)}
                      </ArgonTypography>
                    )}
                  </ArgonBox>
                  <ArgonDropzoneS3ButtonOnly
                    id="upload_terms_of_service_dropzone"
                    label={!event?.termsOfServiceUrl ? 'Upload' : 'Replace'}
                    pathToPresignedUrl={`${process.env.REACT_APP_BACKEND_URI}/v1/media/get-signed-url`}
                    key={
                      event?.termsOfServiceUrl || 'emptyTermsOfServiceUpload'
                    }
                    onFileAdded={async (url) => {
                      console.log('Terms of Service URL: ', url);
                      await updateEventWithLegalDocs({ tosUrl: url });
                    }}
                    payloadPurpose="event-legal-termsOfService"
                    // image={event?.pic_logo_url}
                    message=""
                    identifier={event?._id}
                    options={{
                      // clickable: Boolean(eventData?._id),
                      paramName: 'file',
                      // dictDefaultMessage: "Put your custom message here",
                      method: 'PUT',
                      chunking: true,
                      chunkSize: 1000000,
                      parallelChunkUploads: true,
                      retryChunks: true,
                      retryChunksLimit: 3,
                      forceChunking: true,
                      // uploadMultiple: true,
                      parallelUploads: 3,
                      addRemoveLinks: false,
                      // maxFiles: 1,
                      acceptedFiles: 'application/pdf',
                      autoQueue: true,
                      autoProcessQueue: true
                      // clickable: 'upload-terms-of-service-button'
                    }}
                  />
                  {/* <ArgonButton
                    id="upload-terms-of-service-button"
                    variant="outlined"
                    color={darkMode ? 'white' : 'dark'}
                    size="small">
                    Upload
                  </ArgonButton> */}
                </ArgonBox>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{ backgroundColor: 'red' }}
                width="100%"
                padding={[1, 2, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Liability Waiver
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    If you have a separate liablility waiver, upload it here.
                    Athletes will be promted to accept this waiver before they
                    can register for your event.
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox
                  display="flex"
                  alignItems={{ xs: 'flex-end', sm: 'center' }}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  gap={2}>
                  {/* <ArgonBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}> */}
                  <ArgonBox>
                    {!event?.liabilityWaiverUrl ? (
                      <ArgonTypography
                        variant="button"
                        color="text"
                        fontWeight="regular">
                        <ArgonBadge
                          variant="contained"
                          color="secondary"
                          badgeContent="optional"
                          container
                        />
                      </ArgonTypography>
                    ) : (
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        noWrap
                        fontWeight="regular">
                        {truncate(
                          decodeURI(currentLiabilityWaiverFileName),
                          35
                        )}
                      </ArgonTypography>
                    )}
                  </ArgonBox>
                  <ArgonDropzoneS3ButtonOnly
                    id="upload_terms_of_service_dropzone"
                    label={!event?.liabilityWaiverUrl ? 'Upload' : 'Replace'}
                    pathToPresignedUrl={`${process.env.REACT_APP_BACKEND_URI}/v1/media/get-signed-url`}
                    key={
                      event?.liabilityWaiverUrl || 'emptyLiabilityWaiverUpload'
                    }
                    onFileAdded={async (url) => {
                      console.log('Terms of Service URL: ', url);
                      await updateEventWithLegalDocs({
                        liabilityWaiverUrl: url
                      });
                    }}
                    payloadPurpose="event-legal-liabilityWaiver"
                    // image={event?.pic_logo_url}
                    message=""
                    identifier={event?._id}
                    options={{
                      // clickable: Boolean(eventData?._id),
                      paramName: 'file',
                      // dictDefaultMessage: "Put your custom message here",
                      method: 'PUT',
                      chunking: true,
                      chunkSize: 1000000,
                      parallelChunkUploads: true,
                      retryChunks: true,
                      retryChunksLimit: 3,
                      forceChunking: true,
                      // uploadMultiple: true,
                      parallelUploads: 3,
                      addRemoveLinks: false,
                      // maxFiles: 1,
                      acceptedFiles: 'application/pdf',
                      autoQueue: true,
                      autoProcessQueue: true
                      // clickable: 'upload-terms-of-service-button'
                    }}
                  />
                  {/* <ArgonButton
                    id="upload-terms-of-service-button"
                    variant="outlined"
                    color={darkMode ? 'white' : 'dark'}
                    size="small">
                    Upload
                  </ArgonButton> */}
                </ArgonBox>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{ backgroundColor: 'red' }}
                width="100%"
                padding={[1, 2, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Privacy Policy
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    By law your are required to inform the athletes about how
                    you process their personal data. Upload your privacy policy
                    here.
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox
                  display="flex"
                  alignItems={{ xs: 'flex-end', sm: 'center' }}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  gap={2}>
                  {/* <ArgonBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}> */}
                  <ArgonBox>
                    {!event?.privacyPolicyUrl ? (
                      <ArgonTypography
                        variant="button"
                        color="text"
                        fontWeight="regular">
                        <ArgonBadge
                          variant="contained"
                          color="warning"
                          badgeContent="required"
                          container
                        />
                      </ArgonTypography>
                    ) : (
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        noWrap
                        fontWeight="regular">
                        {truncate(decodeURI(currentPrivacyPolicyFileName), 35)}
                      </ArgonTypography>
                    )}
                  </ArgonBox>
                  <ArgonDropzoneS3ButtonOnly
                    id="upload_privacy_policy_button"
                    label={!event?.privacyPolicyUrl ? 'Upload' : 'Replace'}
                    pathToPresignedUrl={`${process.env.REACT_APP_BACKEND_URI}/v1/media/get-signed-url`}
                    key={event?.privacyPolicyUrl || 'emptyPrivacyPolicyUrl'}
                    onFileAdded={async (url) => {
                      await updateEventWithLegalDocs({ privacyPolicyUrl: url });
                    }}
                    payloadPurpose="event-legal-privacyPolicyUrl"
                    // image={event?.pic_logo_url}
                    message=""
                    identifier={event?._id}
                    options={{
                      // clickable: Boolean(eventData?._id),
                      paramName: 'file',
                      // dictDefaultMessage: "Put your custom message here",
                      method: 'PUT',
                      chunking: true,
                      chunkSize: 1000000,
                      parallelChunkUploads: true,
                      retryChunks: true,
                      retryChunksLimit: 3,
                      forceChunking: true,
                      // uploadMultiple: true,
                      parallelUploads: 3,
                      addRemoveLinks: false,
                      // maxFiles: 1,
                      acceptedFiles: 'application/pdf',
                      autoQueue: true,
                      autoProcessQueue: true
                      // clickable: 'upload-terms-of-service-button'
                    }}
                  />
                  {/* <ArgonButton
                    id="upload-terms-of-service-button"
                    variant="outlined"
                    color={darkMode ? 'white' : 'dark'}
                    size="small">
                    Upload
                  </ArgonButton> */}
                </ArgonBox>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ArgonBox>
    </Card>
  );
}

export default LegalDocs;
