import { useQuery } from '@apollo/client';
import { getOrganizationName } from 'services/restApi';

const { getUserGroups } = require('./cognitoAccount');

export async function getCurrentUsersOrganizations() {
  const userGroups = await getUserGroups();

  console.log("User's groups: ", userGroups);

  const filteredOrganizations = userGroups
    .filter((group) => group.startsWith('orga-admin-'))
    .map((group) => group.slice(-24));
  console.log('filteredOrganizations: ', filteredOrganizations);

  let result = [];

  // now get name for each organization and enrich data to return
  for (const organizationId of filteredOrganizations) {
    const res = await getOrganizationName({ organizationId });
    // console.log('Organization name: ', res.organizationName);

    result.push({
      organizationId: organizationId,
      organizationName: res.organizationName
    });
  }

  return result;
}
