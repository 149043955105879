import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataType, Table, useTable } from 'ka-table';

import 'assets/css/ka-table.css';
import ObjectID from 'bson-objectid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import ArgonBox from 'components/ArgonBox';
import Swal from 'sweetalert2';
import { IconButton } from '@mui/material';
import SpectatorTicketsForm from './SpectatorTicketsForm';
import DialogWrapper from 'components/DialogWrapper';

function SpectatorTicketsTable({
  eventInstance,
  onUpdate,
  openAddNewTicket,
  onAddNewTicketFormClosed
}) {
  const { t, i18n } = useTranslation();
  const [spectatorTickets, setSpectatorTickets] = useState([]);

  const [openSpectatorTicketsForm, setOpenSpectatorTicketsForm] =
    useState(false);
  const [openAddSpectatorTicketsForm, setOpenAddSpectatorTicketsForm] =
    useState(openAddNewTicket);

  const [selectedSpectatorTicket, setSelectedSpectatorTicket] = useState(null);

  const paymentsEnabled = eventInstance?.event?.organization?.stripeAccountId;

  useEffect(() => {
    setOpenAddSpectatorTicketsForm(openAddNewTicket);
  }, [openAddNewTicket]);

  const columns = [
    {
      key: 'validDate',
      title: 'Valid on',
      width: 100,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'description',
      title: 'Description',
      width: 300,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'initialQuantity',
      title: 'Offered Quantity',
      width: 80,
      dataType: DataType.Number,
      isEditable: true,
      validation: (value) => {
        return value >= 0 ? '' : 'Quantity must be positive';
      }
    },
    {
      key: 'price',
      title: 'Price',
      width: 80,
      dataType: DataType.Number,
      isEditable: true,
      validation: (value) => {
        return value >= 0 ? '' : 'Price must be positive';
      }
    }
    // {
    //   key: 'action',
    //   title: '',
    //   width: 50,
    //   style: { textAlign: 'right' },
    //   dataType: DataType.String
    // }
  ];

  //   const tableProps = useTable({
  //     columns,
  //     data: spectatorTickets,
  //     rowKeyField: '_id',
  //     editingMode: 'cell'
  //   });

  const handleRowSelection = (rowKeyValue) => {
    const selectedTicket = spectatorTickets.find(
      (ticket) => ticket._id.toString() === rowKeyValue
    );
    setSelectedSpectatorTicket(selectedTicket);
    setOpenSpectatorTicketsForm(true);
  };

  const handleRowDeleteButtonClicked = (rowKeyValue) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
  };

  const dispatch = async (action) => {
    if (action.type === 'SaveCell') {
      const { rowKeyValue, column, value } = action;
      const updatedTickets = spectatorTickets
        .map((ticket) =>
          ticket._id === rowKeyValue
            ? { ...ticket, [column.key]: value }
            : ticket
        )
        .sort((a, b) => new Date(a.validDate) - new Date(b.validDate));
      setSpectatorTickets(updatedTickets);
      if (onUpdate) {
        onUpdate(updatedTickets);
      }
    }
  };

  useEffect(() => {
    if (eventInstance?.spectatorTicketsOffered?.length > 0) {
      const formattedTickets = eventInstance.spectatorTicketsOffered.map(
        (ticket) => ({
          _id: ticket._id || new ObjectID().toHexString(),
          validDate: new Date(ticket.validDate).toLocaleDateString(
            i18n.language,
            {
              // year: 'numeric',
              // month: 'long',
              // day: 'numeric'
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }
          ),
          description:
            ticket.description ||
            'Spectator ticket for ' +
              new Date(ticket.validDate).toLocaleDateString(i18n.language, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }),
          initialQuantity: ticket.initialQuantity || 0,
          price: ticket.price || 0,
          stripeProductId: ticket.stripeProductId || null,
          stripePriceId: ticket.stripePriceId || null,
          pax: ticket.pax || null,
          venueOpens: ticket.venueOpens || null,
          venueCloses: ticket.venueCloses || null
        })
      );
      setSpectatorTickets(formattedTickets);
    } else {
      const tempTickets = [];
      const startDate = new Date(eventInstance?.startDate);
      const endDate = new Date(eventInstance?.endDate);

      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        tempTickets.push({
          _id: new ObjectID().toHexString(),
          validDate: new Date(date).toLocaleDateString(i18n.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
          description:
            'Single spectator ticket for ' +
            new Date(date).toLocaleDateString(i18n.language, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }),
          initialQuantity: 5000,
          price: eventInstance?.spectatorTicketFee || 0,
          pax: 1,
          venueOpens: '09:00',
          venueCloses: '18:00'
          // currency:
          //     eventInstance?.currency || eventInstance?.event?.currency || 'EUR'
        });
      }
      tempTickets.sort((a, b) => new Date(a.validDate) - new Date(b.validDate));
      setSpectatorTickets(tempTickets);
      onUpdate && onUpdate(tempTickets);
    }
  }, [eventInstance]);

  return (
    <>
      <div className="ka-table_transparent_dark_font_small">
        <Table
          //   {...tableProps}
          dispatch={dispatch}
          columns={columns}
          data={spectatorTickets}
          rowKeyField="_id"
          editingMode="cell"
          options={{
            search: true,
            pagination: true
          }}
          childComponents={{
            // dataRow: {
            //   elementAttributes: () => ({
            //     onClick: (event, extendedEvent) => {
            //       console.log('dataRow: onClick: extendedEvent', extendedEvent);

            //       //   table.selectSingleRow(extendedEvent.childProps.rowKeyValue);
            //       // setSelectedParticipantId(extendedEvent.childProps.rowKeyValue);
            //       // setSelectedRegistrationId(
            //       //   extendedEvent.childProps.rowData?.registration[0]?._id
            //       // );
            //       // setParticipantDetailsOpen(true);
            //       handleRowSelection(extendedEvent.childProps.rowKeyValue);
            //     }
            //   })
            // },
            cell: {
              elementAttributes: () => ({
                onClick: (event, extendedEvent) => {
                  if (extendedEvent?.childProps?.column?.key !== 'action') {
                    console.log(
                      'dataCell: onClick: extendedEvent',
                      extendedEvent
                    );

                    handleRowSelection(extendedEvent.childProps.rowKeyValue);
                  }
                }
              })
            },
            cellText: {
              content: ({ column, rowKeyValue, value }) => {
                switch (column?.key) {
                  case 'price':
                    return (
                      <>
                        {new Intl.NumberFormat(i18n.language, {
                          style: 'currency',
                          currency:
                            eventInstance?.currency ||
                            eventInstance?.event?.currency ||
                            'EUR'
                        }).format(Number(value || 0) / 100)}
                      </>
                    );

                  case 'initialQuantity':
                    return (
                      <>
                        {new Intl.NumberFormat(i18n.language).format(
                          Number(value || 0)
                        )}
                      </>
                    );
                  case 'action':
                    return (
                      <ArgonBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        width="100%"
                        gap={1}>
                        <IconButton
                          sx={{
                            fontSize: '1rem'
                          }}>
                          <EditIcon color="secondary" />
                        </IconButton>
                        {/* <IconButton
                          sx={{
                            fontSize: '1rem'
                          }}
                          onClick={() =>
                            handleRowDeleteButtonClicked(rowKeyValue)
                          }>
                          <DeleteIcon color="secondary" />
                        </IconButton> */}
                      </ArgonBox>
                    );

                  default:
                    //   console.log(
                    //     'Key of row clicked:',
                    //     column?.key,
                    //     rowKeyValue,
                    //     value
                    //   );
                    break;
                }
              }
            }
          }}
        />
      </div>
      <DialogWrapper
        title="Edit Spectator Ticket Offer"
        open={openSpectatorTicketsForm}
        onClose={() => {
          setSelectedSpectatorTicket(null);
          setOpenSpectatorTicketsForm(false);
        }}>
        <SpectatorTicketsForm
          spectatorTicket={selectedSpectatorTicket}
          paymentsEnabled
          currency={
            eventInstance?.currency || eventInstance?.event?.currency || 'EUR'
          }
          onClose={() => setOpenSpectatorTicketsForm(false)}
          onSave={(newSpectatorTicket) => {
            const updatedTickets = spectatorTickets.filter(
              (ticket) => ticket._id !== newSpectatorTicket?._id
            );
            const tempTickets = [...updatedTickets, newSpectatorTicket].sort(
              (a, b) => new Date(a.validDate) - new Date(b.validDate)
            );
            setSpectatorTickets(tempTickets);
            setOpenSpectatorTicketsForm(false);
            onUpdate && onUpdate(tempTickets);
          }}
          onDelete={(deletedSpectatorTicketId) => {
            // filter out the deleted spectator ticket
            const updatedTickets = spectatorTickets.filter(
              (ticket) => ticket._id !== deletedSpectatorTicketId
            );
            setSpectatorTickets(updatedTickets);
            onUpdate && onUpdate(updatedTickets);
          }}
        />
      </DialogWrapper>

      <DialogWrapper
        title="Add Spectator Ticket Offer"
        open={openAddSpectatorTicketsForm}
        onClose={() => {
          setSelectedSpectatorTicket(null);
          setOpenAddSpectatorTicketsForm(false);
          onAddNewTicketFormClosed && onAddNewTicketFormClosed();
        }}>
        <SpectatorTicketsForm
          addNew={true}
          //   spectatorTicket={selectedSpectatorTicket}
          paymentsEnabled
          currency={
            eventInstance?.currency || eventInstance?.event?.currency || 'EUR'
          }
          onClose={() => setOpenAddSpectatorTicketsForm(false)}
          onSave={(newSpectatorTicket) => {
            const tempTickets = [...spectatorTickets, newSpectatorTicket].sort(
              (a, b) => new Date(a.validDate) - new Date(b.validDate)
            );
            setSpectatorTickets(tempTickets);
            setOpenAddSpectatorTicketsForm(false);
            onUpdate && onUpdate(tempTickets);
          }}
          onDelete={(deletedSpectatorTicketId) => {
            // filter out the deleted spectator ticket
            const updatedTickets = spectatorTickets.filter(
              (ticket) => ticket._id !== deletedSpectatorTicketId
            );

            setSpectatorTickets(updatedTickets);
            onUpdate && onUpdate(updatedTickets);
          }}
        />
      </DialogWrapper>
    </>
  );
}

SpectatorTicketsTable.propTypes = {
  eventInstance: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    spectatorTicketsList: PropTypes.array,
    spectatorTicketFee: PropTypes.number,
    currency: PropTypes.string
  }),
  onUpdate: PropTypes.func
};

export default SpectatorTicketsTable;
