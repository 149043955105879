import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import DefaultLineChart from './DefaultLineChart';
import { getRegistrationsOverTime } from 'services/restApi';
import moment from 'moment';

import i18n from 'i18n';

import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import { useQuery } from '@apollo/client';
import { getTotalRevenueForOrganization } from 'services/restApi';

function getRangeInDays(startDate, stopDate) {
  var dateArray = [];
  var currentDate = new Date(startDate);
  while (currentDate <= stopDate) {
    dateArray.push(currentDate.toLocaleDateString(i18n.language));
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

function NetRevenueOverTimeChart({ organizationId }) {
  const [groupBy, setGroupBy] = useState({
    label: 'Per month',
    value: 'month'
  });

  const [groupByValue, setGroupByValue] = useState('month');

  //   const [firstDate, setFirstDate] = useState(null);
  //   const [lastDate, setLastDate] = useState(moment().format('MMMM Do YYYY'));

  const [xLabels, setXLabels] = useState([]);
  const [valuesArray, setValuesArray] = useState([]);

  // const [data, setData] = useState(null);

  // const {
  //   data: eventInstanceData,
  //   loading,
  //   error
  // } = useQuery(EVENTINSTANCE_GET_BY_ID, {
  //   variables: {
  //     eventInstanceId: eventInstanceId
  //   },
  //   onCompleted: (data) => {
  //     setEventInstanceEndDate(data?.eventInstance?.endDate);
  //   },
  //   onError: (error) => {
  //     console.log('Error while getting event instance: ', error);
  //   }
  // });

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long'
    });
  }

  const extractDates = (data) => {
    return data?.map((item) => {
      console.log('Iterating items: ', item);

      return moment(item?.date, 'MM/DD/YYYY').locale(i18n.language).format('L');
    });
  };

  // TODO: use eventInstanceEndDate to set boundary of date-range

  const fetchData = async (groupBy) => {
    const result = await getTotalRevenueForOrganization({
      organizationId,
      groupBy: groupBy.value
    });
    console.log('Chart data received: ', result?.data);

    const convertedDataArray =
      result?.data?.map((item) => ({
        date: new Date(item?.date).toLocaleDateString(i18n.language),
        week: item?.week,
        month: item?.month,
        year: item?.year,
        total: item?.amountNetRevenueForOrganization / 100
      })) || [];

    console.log('Converted data array: ', convertedDataArray);

    if (groupBy?.value === 'day') {
      console.log('Preparing data for a day scale');
      const extractedDatesArray = extractDates(result?.data);
      const extractedValuesArray = result?.data?.map(
        (item) => item.amountNetRevenueForOrganization
      );

      // console.log('extractedDatesArray: ', extractedDatesArray);
      // console.log('extractedValuesArray: ', extractedValuesArray);

      // setDatesArray(extractedDatesArray);
      // setValuesArray(extractedValuesArray);

      // convert result in array with dates in locale format to map with dates-range-array later

      const { rangeInBetween } = getDateRange(extractedDatesArray, 'day');
      setXLabels(rangeInBetween);

      let lastTotal = 0;
      const totalValues = rangeInBetween.map((date) => {
        lastTotal =
          lastTotal +
          (convertedDataArray?.find((d) => d.date === date)?.total || 0);
        return {
          date: date,
          total: lastTotal
        };
      });

      //  console.log(
      //    'Test: ',
      //    new Date(date).getTime() <= new Date(eventInstanceEndDate).getTime()
      //  );

      console.log('totalValues: ', totalValues);
      setValuesArray(totalValues.map((item) => item.total));
    }

    if (groupBy.value === 'week') {
      console.log('Calculating scale and values on a weekly basis');

      // set x-axis labels (weeks)
      const extraktedWeeks = convertedDataArray?.map((item) => item.week);

      const { rangeInBetween } = getDateRange(extraktedWeeks, 'week');
      setXLabels(rangeInBetween.map((item) => `Week ${item}`));

      // now map the values to the weeks (filling in missing weeks with last total)

      let lastTotal = 0;
      const totalValues = rangeInBetween.map((week) => {
        lastTotal =
          lastTotal +
          (convertedDataArray?.find((d) => d.week === week)?.total || 0);
        return {
          week: week,
          total: lastTotal
        };
      });
      console.log('totalValues: ', totalValues);
      setValuesArray(totalValues.map((item) => item.total));
    }

    if (groupBy.value === 'month') {
      console.log('Calculating scale and values on a monthly basis');
      // set x-axis labels (months)
      const extraktedMonths = convertedDataArray?.map((item) => item.month);

      const { rangeInBetween } = getDateRange(extraktedMonths, 'month');
      setXLabels(rangeInBetween.map((item) => getMonthName(item)));

      // now map the values to the weeks (filling in missing weeks with last total)

      let lastTotal = 0;
      const totalValues = rangeInBetween.map((month) => {
        lastTotal =
          lastTotal +
          (convertedDataArray?.find((d) => d.month === month)?.total || 0);
        return {
          month: month,
          total: lastTotal
        };
      });
      console.log('totalValues: ', totalValues);
      setValuesArray(totalValues.map((item) => item.total));
    }
  };

  useEffect(() => {
    if (organizationId) fetchData(groupBy);
  }, [organizationId]);

  const getDateRange = (dates, scale = 'day', lastDate = new Date()) => {
    console.log('Scale:', scale);

    Date.prototype.addDays = function (days) {
      var dat = new Date(this.valueOf());
      dat.setDate(dat.getDate() + days);
      return dat;
    };

    let startDate, endDate;
    let minWeek, maxWeek;
    let minMonth, maxMonth;
    let scaleArray = [];

    if (scale === 'day') {
      const datesArray = dates.map((date) => new Date(date));
      const minDate = Math.min(...datesArray);
      //   const maxDateInRange = Math.max(...datesArray);

      // find the earlier of the days: today or the last day of the event instance
      const maxDate = Math.min(
        ...[new Date().getTime(), new Date(lastDate).getTime()]
      ); // set today or, if earlier, the last day of the event instance as max date for graphics presentation

      // console.log('maxDate: ', maxDate);
      // console.log('maxDate: ', Date(1696530264000));

      // console.log('minDate: ', new Date(minDate));
      // console.log('maxDate: ', new Date(maxDate).toString());

      startDate = new Date(minDate).toLocaleDateString();
      endDate = new Date(maxDate).toLocaleDateString();

      scaleArray = getRangeInDays(minDate, maxDate);
    }

    if (scale === 'week') {
      minWeek = Math.min(...dates);

      maxWeek = Math.min(
        ...[new moment().week(), moment(new Date(lastDate)).week()]
      );

      // maxWeek = moment(new Date('2024-02-03')).week();

      // console.log('Current week: ', moment().week());
      // console.log('last date for week: ', new Date(lastDate));
      // console.log('week of lastDate: ', moment(new Date(lastDate)).week());
      // console.log('Max week: ', maxWeek);

      // console.log('Weeks this year: ', moment().weeksInYear());

      //! careful, at the moment this does not correclty work when year changes (week 52 => week 1)
      // now fill an array with each week between minWeek and maxWeek
      let c = 1;
      for (let i = minWeek; i <= maxWeek; i++) {
        if (i <= moment().weeksInYear()) {
          // when week is in current year, just add it to the array
          console.log("Pushing current year's week to array: ", i);
          scaleArray.push(i);
        } else {
          // when after year change, start at week 1 again
          scaleArray.push(c++);
          console.log('Pushing next year week to array: ', c);
        }
      }
    }

    if (scale === 'month') {
      minMonth = Math.min(...dates);
      //   maxWeek = Math.max(...dates);
      maxMonth = moment().month(); // set today as max date //! BUT: this is not correct, should be end date of event instance, needs to be passed in as prop

      // now fill an array with each week between minWeek and maxWeek

      for (let i = minMonth; i <= maxMonth; i++) {
        scaleArray.push(i);
      }
    }

    console.log('scaleArray: ', scaleArray);

    return {
      startDate,
      endDate,
      minWeek,
      maxWeek,
      minMonth,
      maxMonth,
      rangeInBetween: scaleArray,
      scale: scale
    };
  };

  const handleChartSelectionFilterChanged = (option) => {
    console.log('Selection filter changed: ', option);
    // setGroupByValue(option.value);
    setGroupBy(option);

    if (organizationId) fetchData(option);
  };

  return (
    <DefaultLineChart
      title={`Accumulated Net Revenue YTD ${moment().year()}`}
      showSelectionFilter={true}
      height="20vH"
      onSelectionFilterChange={handleChartSelectionFilterChanged}
      selectionFilterValue={groupBy}
      chart={{
        labels: xLabels,
        // labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Accumulated net revenue',
            color: 'info',
            data: valuesArray
            // data: [50, 40, 300, 220, 500, 250, 400, 230, 500]
          }
          //   {
          //     label: 'Referral',
          //     color: 'dark',
          //     data: [30, 90, 40, 140, 290, 290, 340, 230, 400]
          //   },
          //   {
          //     label: 'Direct',
          //     color: 'primary',
          //     data: [40, 80, 70, 90, 30, 90, 140, 130, 200]
          //   }
        ]
      }}
    />
  );
}

NetRevenueOverTimeChart.propTypes = {
  organizationId: PropTypes.string.isRequired
};

export default NetRevenueOverTimeChart;
