import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArgonSelect from 'components/ArgonSelect';

import AddIcon from '@mui/icons-material/Add';
import CreateTaxRateDialog from '../CreateTaxRateDialog';
import { getAllStripeTaxRates } from 'services/REST_API/stripe';

function StripeTaxRateSelector({
  organization,
  selectedTaxRateId,
  onChange,
  error,
  errorText,
  disabled,
  ...restProps
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [taxRates, setTaxRates] = useState([]);
  const [openNewTaxRateDialog, setOpenNewTaxRateDialog] = useState(false);

  // const [taxRates, setTaxRates] = useState([]);

  const fetchTaxRatesFromStripe = async () => {
    if (organization?._id) {
      const temp = await getAllStripeTaxRates({
        organizationId: organization?._id
      });
      console.log('ManageTaxRates: Tax rates fetched:', temp);
      // setTaxRates(temp);
      return temp;
    }
  };

  const prepareTaxRateData = (taxRates) => {
    let taxRatesArray = [];
    if (taxRates?.length > 0) {
      taxRatesArray = taxRates.map((taxRate) => ({
        value: taxRate.id,
        label: `${taxRate?.display_name} (${
          taxRate?.inclusive ? 'incl.' : 'excl.'
        })`
      }));
    }
    taxRatesArray.push({
      value: 'add',
      label: 'Create new tax rate'
    });
    return taxRatesArray;
  };

  console.log('StripeTaxRateSelector organization: ', organization?._id);
  console.log('StripeTaxRateSelector selectedTaxRateId: ', selectedTaxRateId);

  useEffect(() => {
    setIsLoading(true);
    fetchTaxRatesFromStripe()
      .then((taxRates) => {
        setTaxRates(prepareTaxRateData(taxRates));
      })
      .catch((error) => {
        console.error('Error fetching tax rates:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // bring organization tax rates into correct format and save to state
    // let taxRates = [];
    // console.log(
    //   'StripeTaxRateSelector useEffect tax rates: ',
    //   organization?.stripeTaxRates
    // );
    // if (organization?.stripeTaxRates?.length > 0)
    //   taxRates = organization.stripeTaxRates?.map((taxRate) => ({
    //     value: taxRate.stripeTaxRateId,
    //     label: `${taxRate?.name} (${taxRate?.inclusive ? 'incl.' : 'excl.'})`
    //   }));
    // taxRates.push({
    //   value: 'add',
    //   label: 'Create new tax rate'
    // });
    // console.log('Tax rate for organization: ', taxRates);
    // setTaxRates(taxRates);
  }, [organization]);

  const handleTaxRateChanged = (taxRateOption) => {
    if (taxRateOption.value === 'add') {
      // open dialog to create new tax rate
      console.log('Open dialog to create new tax rate');
      setOpenNewTaxRateDialog(true);
    } else onChange && onChange(taxRateOption);
  };

  const handleCloseNewTaxRateDialog = () => {
    setOpenNewTaxRateDialog(false);
  };

  const handleNewTaxRateCreated = (newTaxRateObject) => {
    const newTaxRate = {
      value: newTaxRateObject.id,
      label: `${newTaxRateObject?.display_name} (${
        newTaxRateObject?.inclusive ? 'incl.' : 'excl.'
      })`
    };
    const updatedTaxRates = [...taxRates];
    updatedTaxRates.splice(taxRates.length - 1, 0, newTaxRate);

    setTaxRates(updatedTaxRates);

    onChange && onChange(newTaxRate);
    setOpenNewTaxRateDialog(false);
  };

  console.log('StripeTaxRateSelector for organization: ', organization);

  return (
    <>
      <ArgonSelect
        id="stripeRaxRateSelector"
        name="stripeRaxRateSelector"
        placeholder="Please choose"
        options={taxRates}
        overflow={true}
        //value={formik.values.type}
        value={taxRates.filter((option) => option.value === selectedTaxRateId)}
        onChange={handleTaxRateChanged}
        // error={Boolean(error)}
        // helperText={errorText}
        {...restProps}
        isDisabled={disabled}
        isLoading={isLoading}
      />
      <CreateTaxRateDialog
        organizationId={organization?._id}
        open={openNewTaxRateDialog}
        handleClose={handleCloseNewTaxRateDialog}
        onNewTaxRateCreated={handleNewTaxRateCreated}
      />
    </>
  );
}

StripeTaxRateSelector.propTypes = {
  organization: PropTypes.object.isRequired,
  selectedTaxRateId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default StripeTaxRateSelector;
