module.exports = `  
<table id="u_content_button_1" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
       <!--[if mso]><style>.v-button {background: transparent !important;}</style><![endif]-->
<div class="v-text-align" align="center">
  <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="{{{buttonLink}}}" style="height:46px; v-text-anchor:middle; width:117px;" arcsize="0%"  stroke="f" fillcolor="{{{buttonColor}}}"><w:anchorlock/><center style="color:#FFFFFF;"><![endif]-->
    <a href="{{{buttonLink}}}" target="_blank" class="v-button v-font-size" style="box-sizing: border-box;display: inline-block;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: {{{buttonColor}}}; border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;font-size: 13px;">
      <span style="display:block;padding:15px 20px;line-height:120%;">{{{buttonText}}}</span>
    </a>
    <!--[if mso]></center></v:roundrect><![endif]-->
</div>
      </td>
    </tr>
  </tbody>
</table>
`;
