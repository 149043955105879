import { gql } from '@apollo/client';

export const USER_INSERT = gql`
  mutation InsertUser($data: UserInsertInput!) {
    firstName
    lastName
    dateOfBirth
    gender
    address1
    address2
    city
    zip
    country
    pic_url
    fcWidgetId
  }
`;

export const USER_UPDATE = gql`
  mutation UpdateUser($userId: ObjectId!, $data: UserUpdateInput!) {
    updateOneUser(query: { _id: $userId }, set: $data) {
      firstName
      lastName
      dateOfBirth
      gender
      address1
      address2
      city
      zip
      country
      pic_url
      fcWidgetId
    }
  }
`;
