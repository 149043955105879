import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataType, Table, useTable } from 'ka-table';

import 'assets/css/ka-table.css';
import ObjectID from 'bson-objectid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import ArgonBox from 'components/ArgonBox';
import Swal from 'sweetalert2';
import { IconButton } from '@mui/material';

import DialogWrapper from 'components/DialogWrapper';
import VolunteerPositionForm from './VolunteerPositionForm';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';
function VolunteerPositionsTable({
  eventInstance,
  onUpdate,
  openAddNewVolunteerPosition,
  onAddNewVolunteerPositionFormClosed
}) {
  const { t, i18n } = useTranslation();
  const [volunteerPositions, setVolunteerPositions] = useState([]);

  const [openVolunteerPositionsForm, setOpenVolunteerPositionsForm] =
    useState(false);
  const [openAddVolunteerPositionsForm, setOpenAddVolunteerPositionsForm] =
    useState(openAddNewVolunteerPosition);

  const [selectedVolunteerPosition, setSelectedVolunteerPosition] =
    useState(null);

  useEffect(() => {
    setOpenAddVolunteerPositionsForm(openAddNewVolunteerPosition);
  }, [openAddNewVolunteerPosition]);

  const columns = [
    {
      key: 'name',
      title: 'Position Name',
      width: 100,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'description',
      title: 'Description',
      width: 300,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'requiredCapacity',
      title: 'Offered Positions',
      width: 80,
      dataType: DataType.Number,
      isEditable: true,
      validation: (value) => {
        return value >= 0 ? '' : 'Quantity must be positive';
      }
    },
    {
      key: 'formattedStartDate',
      title: 'Start Date',
      width: 80,
      // dataType: DataType.Date,
      isEditable: true
    },
    {
      key: 'formattedEndDate',
      title: 'End Date',
      width: 80,
      // dataType: DataType.Date,
      isEditable: true
    }
    // {
    //   key: 'overnightAccomodationOffered',
    //   title: 'Overnight accomodation offered',
    //   width: 80,
    //   dataType: DataType.Boolean,
    //   isEditable: true
    // }
    // // {
    // //   key: 'endDate',
    // //   title: 'End Date',
    // //   width: 80,
    // //   dataType: DataType.Date,
    // //   isEditable: true
    // // },
    // {
    //   key: 'action',
    //   title: '',
    //   width: 50,
    //   style: { textAlign: 'right' },
    //   dataType: DataType.String
    // }
  ];

  //   const tableProps = useTable({
  //     columns,
  //     data: spectatorTickets,
  //     rowKeyField: '_id',
  //     editingMode: 'cell'
  //   });

  const handleRowSelection = (rowKeyValue) => {
    const selectedPosition = volunteerPositions.find(
      (position) => position._id.toString() === rowKeyValue
    );
    setSelectedVolunteerPosition(selectedPosition);
    setOpenVolunteerPositionsForm(true);
  };

  const handleRowDeleteButtonClicked = (rowKeyValue) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
  };

  const dispatch = async (action) => {
    if (action.type === 'SaveCell') {
      // const { rowKeyValue, column, value } = action;
      // const updatedTickets = spectatorTickets
      //   .map((ticket) =>
      //     ticket._id === rowKeyValue
      //       ? { ...ticket, [column.key]: value }
      //       : ticket
      //   )
      //   .sort((a, b) => new Date(a.validDate) - new Date(b.validDate));
      // setSpectatorTickets(updatedTickets);
      // if (onUpdate) {
      //   onUpdate(updatedTickets);
      // }
    }
  };

  useEffect(() => {
    if (eventInstance?.volunteerPositionsOffered?.length > 0) {
      const formattedPositions = eventInstance.volunteerPositionsOffered.map(
        (position) => ({
          ...position,
          _id: position._id || new ObjectID().toHexString(),

          formattedStartDate: new Date(position.startDate).toLocaleDateString(
            i18n.language,
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }
          ),
          formattedEndDate: new Date(position.endDate).toLocaleDateString(
            i18n.language,
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }
          )
        })
      );
      setVolunteerPositions(formattedPositions);
    }
  }, [eventInstance]);

  const handleDataUpdated = (updatedPositions) => {
    // before updating positions, remove all fields that start with "formatted"
    const updatedPositionsWithoutFormattedFields = updatedPositions.map(
      (position) => {
        const { formattedStartDate, formattedEndDate, ...rest } = position;
        return rest;
      }
    );
    setVolunteerPositions(updatedPositionsWithoutFormattedFields);
    onUpdate && onUpdate(updatedPositionsWithoutFormattedFields);
  };

  // if (!volunteerPositions || volunteerPositions?.length === 0) {
  //   return (
  //     <>
  //       <ArgonTypography variant="caption" color="error">
  //         No volunteer positions offered. Use button to add one or more.
  //       </ArgonTypography>
  //       <DialogWrapper
  //         title="Add Volunteer Position"
  //         open={openAddVolunteerPositionsForm}
  //         onClose={() => {
  //           setSelectedVolunteerPosition(null);
  //           setOpenAddVolunteerPositionsForm(false);
  //           onAddNewVolunteerPositionFormClosed &&
  //             onAddNewVolunteerPositionFormClosed();
  //         }}>
  //         <VolunteerPositionForm
  //           addNew={true}
  //           //   spectatorTicket={selectedSpectatorTicket}
  //           paymentsEnabled
  //           currency={
  //             eventInstance?.currency || eventInstance?.event?.currency || 'EUR'
  //           }
  //           onClose={() => setOpenAddVolunteerPositionsForm(false)}
  //           onSave={(newVolunteerPosition) => {
  //             const tempPositions = [
  //               ...volunteerPositions,
  //               newVolunteerPosition
  //             ].sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  //             setVolunteerPositions(tempPositions);
  //             setOpenAddVolunteerPositionsForm(false);
  //             // onUpdate && onUpdate(tempPositions);
  //             handleDataUpdated(tempPositions);
  //           }}
  //           onDelete={(deletedVolunteerPositionId) => {
  //             // filter out the deleted spectator ticket
  //             const updatedPositions = volunteerPositions.filter(
  //               (position) => position._id !== deletedVolunteerPositionId
  //             );

  //             setVolunteerPositions(updatedPositions);
  //             // onUpdate && onUpdate(updatedPositions);
  //             handleDataUpdated(updatedPositions);
  //           }}
  //         />
  //       </DialogWrapper>
  //     </>
  //   );
  // }

  return (
    <>
      {!volunteerPositions || volunteerPositions?.length === 0 ? (
        <>
          <ArgonTypography variant="caption" color="error">
            No volunteer positions offered. Use button to add one or more.
          </ArgonTypography>
        </>
      ) : (
        <>
          <div className="ka-table_transparent_dark_font_small">
            <Table
              //   {...tableProps}
              dispatch={dispatch}
              columns={columns}
              data={volunteerPositions}
              rowKeyField="_id"
              editingMode="cell"
              options={{
                search: true,
                pagination: true
              }}
              childComponents={{
                // dataRow: {
                //   elementAttributes: () => ({
                //     onClick: (event, extendedEvent) => {
                //       console.log('dataRow: onClick: extendedEvent', extendedEvent);

                //       //   table.selectSingleRow(extendedEvent.childProps.rowKeyValue);
                //       // setSelectedParticipantId(extendedEvent.childProps.rowKeyValue);
                //       // setSelectedRegistrationId(
                //       //   extendedEvent.childProps.rowData?.registration[0]?._id
                //       // );
                //       // setParticipantDetailsOpen(true);
                //       handleRowSelection(extendedEvent.childProps.rowKeyValue);
                //     }
                //   })
                // },
                cell: {
                  elementAttributes: () => ({
                    onClick: (event, extendedEvent) => {
                      if (extendedEvent?.childProps?.column?.key !== 'action') {
                        console.log(
                          'dataCell: onClick: extendedEvent',
                          extendedEvent
                        );

                        handleRowSelection(
                          extendedEvent.childProps.rowKeyValue
                        );
                      }
                    }
                  })
                },
                cellText: {
                  content: ({ column, rowKeyValue, value }) => {
                    switch (column?.key) {
                      case 'price':
                        return (
                          <>
                            {new Intl.NumberFormat(i18n.language, {
                              style: 'currency',
                              currency:
                                eventInstance?.currency ||
                                eventInstance?.event?.currency ||
                                'EUR'
                            }).format(Number(value || 0) / 100)}
                          </>
                        );

                      case 'initialQuantity':
                        return (
                          <>
                            {new Intl.NumberFormat(i18n.language).format(
                              Number(value || 0)
                            )}
                          </>
                        );
                      case 'action':
                        return (
                          <ArgonBox
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            width="100%"
                            gap={1}>
                            <IconButton
                              sx={{
                                fontSize: '1rem'
                              }}>
                              <EditIcon color="secondary" />
                            </IconButton>
                            {/* <IconButton
                          sx={{
                            fontSize: '1rem'
                          }}
                          onClick={() =>
                            handleRowDeleteButtonClicked(rowKeyValue)
                          }>
                          <DeleteIcon color="secondary" />
                        </IconButton> */}
                          </ArgonBox>
                        );

                      default:
                        //   console.log(
                        //     'Key of row clicked:',
                        //     column?.key,
                        //     rowKeyValue,
                        //     value
                        //   );
                        break;
                    }
                  }
                }
              }}
            />
          </div>
        </>
      )}
      <DialogWrapper
        title="Edit Volunteer Position"
        open={openVolunteerPositionsForm}
        onClose={() => {
          setSelectedVolunteerPosition(null);
          setOpenVolunteerPositionsForm(false);
        }}>
        <VolunteerPositionForm
          volunteerPosition={selectedVolunteerPosition}
          currency={
            eventInstance?.currency || eventInstance?.event?.currency || 'EUR'
          }
          onClose={() => setOpenVolunteerPositionsForm(false)}
          onSave={(newVolunteerPosition) => {
            const updatedPositions = volunteerPositions.filter(
              (position) => position._id !== newVolunteerPosition?._id
            );
            const tempPositions = [
              ...updatedPositions,
              newVolunteerPosition
            ].sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            setVolunteerPositions(tempPositions);
            setOpenVolunteerPositionsForm(false);
            // onUpdate && onUpdate(tempPositions);
            handleDataUpdated(tempPositions);
          }}
          onDelete={(deletedVolunteerPositionId) => {
            // filter out the deleted spectator ticket
            const updatedPositions = volunteerPositions.filter(
              (position) => position._id !== deletedVolunteerPositionId
            );
            setVolunteerPositions(updatedPositions);
            // onUpdate && onUpdate(updatedPositions);
            handleDataUpdated(updatedPositions);
          }}
        />
      </DialogWrapper>

      <DialogWrapper
        title="Add Volunteer Position"
        open={openAddVolunteerPositionsForm}
        onClose={() => {
          setSelectedVolunteerPosition(null);
          setOpenAddVolunteerPositionsForm(false);
          onAddNewVolunteerPositionFormClosed &&
            onAddNewVolunteerPositionFormClosed();
        }}>
        <VolunteerPositionForm
          addNew={true}
          //   spectatorTicket={selectedSpectatorTicket}
          defaultStartDate={eventInstance?.startDate}
          defaultEndDate={eventInstance?.endDate}
          currency={
            eventInstance?.currency || eventInstance?.event?.currency || 'EUR'
          }
          onClose={() => setOpenAddVolunteerPositionsForm(false)}
          onSave={(newVolunteerPosition) => {
            const tempPositions = [
              ...volunteerPositions,
              newVolunteerPosition
            ].sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            setVolunteerPositions(tempPositions);
            setOpenAddVolunteerPositionsForm(false);
            // onUpdate && onUpdate(tempPositions);
            handleDataUpdated(tempPositions);
          }}
          onDelete={(deletedVolunteerPositionId) => {
            // filter out the deleted spectator ticket
            const updatedPositions = volunteerPositions.filter(
              (position) => position._id !== deletedVolunteerPositionId
            );

            setVolunteerPositions(updatedPositions);
            // onUpdate && onUpdate(updatedPositions);
            handleDataUpdated(updatedPositions);
          }}
        />
      </DialogWrapper>
    </>
  );
}

VolunteerPositionsTable.propTypes = {
  eventInstance: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
  onAddNewVolunteerPositionFormClosed: PropTypes.func
};

export default VolunteerPositionsTable;
