import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from './fragments';

export const EVENT_INSERT = gql`
  ${EVENT_FRAGMENT}
  mutation InsertEvent($data: EventInsertInput!) {
    insertOneEvent(input: $data) {
      ...EventFragment
    }
  }
`;

export const EVENT_UPDATE = gql`
  ${EVENT_FRAGMENT}
  mutation UpdateEvent($eventId: ObjectId!, $data: EventUpdateInput!) {
    updateOneEvent(query: { _id: $eventId }, set: $data) {
      ...EventFragment
    }
  }
`;

export const EVENT_DIVISION_CREATE = gql`
  mutation eventDivisionCreate($data: EventDivisionCreateInput) {
    EventDivisionCreate(input: $data) {
      _id
      name
      description
      maxParticipants
      forIndividuals
      forTeams
      forUnderage
      teamSize
      minAge
      maxAge
      registrationFee
    }
  }
`;

export const EVENT_DIVISION_UPDATE = gql`
  mutation updateEventDivision($data: EventDivisionUpdateInput_!) {
    EventDivisionUpdate(input: $data) {
      eventId
      division {
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
      }
    }
  }
`;

export const EVENT_DIVISION_DELETE = gql`
  mutation deleteDivision($data: EventDivisionDeleteInput!) {
    EventDivisionDelete(input: $data) {
      status
    }
  }
`;
