import React, { useState } from 'react';

// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonInput from 'components/ArgonInput';
import TooltipWrapper from 'components/TooltipWrapper';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { getDecimalSeparator } from 'services/numberFormatting';
import { getGroupSeparator } from 'services/numberFormatting';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

// First, you need to create the `numberMask` with your desired configurations

const useStyles = makeStyles((theme) => ({
  container: {
    // display: 'flex',
    // flexWrap: 'wrap'
  },
  formControl: {
    // margin: theme.spacing(1)
  }
}));

// const numberMask = createNumberMask({
//   prefix: '',
//   suffix: ' $' // This will put the dollar sign at the end, with a space.
// });

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', {
  minYear: 1900,
  maxYear: 2099
});

function TextMaskCustom(props) {
  const {
    inputRef,
    onEnterPressed,
    error,
    helperText,
    width,
    integerLimit,
    decimalLimit,
    prefix, // prefix for the input
    suffix, // suffix for the input
    ...other
  } = props;

  const { i18n } = useTranslation();

  const weightMask = createNumberMask({
    allowDecimal: true,
    prefix: prefix || '',
    suffix: suffix || '',
    decimalSymbol: getDecimalSeparator(i18n.language), //',',
    thousandsSeparatorSymbol: getGroupSeparator(i18n.language), //'.',
    includeThousandsSeparator: true,
    decimalLimit: decimalLimit,
    integerLimit: integerLimit,
    allowNegative: false, // allow negative numbers
    allowLeadingZeroes: false, // allow leading zeroes
    requireDecimals: false // require decimals
  });

  // console.log('getDecimalSeparator: ', getDecimalSeparator(i18n.language));
  // console.log('getGroupSeparator: ', getGroupSeparator(i18n.language));

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={weightMask}
      //   placeholderChar={'\u2000'}
      //   pipe={autoCorrectedDatePipe}
      //   guide
      //   keepCharPositions
      onKeyDown={(event) => {
        // console.log('event: ', event);
        if (event.key === '.') {
          console.log('Enter key pressed');
          event.preventDefault();
          event.stopPropagation();
          Swal.fire({
            title: 'Please use a comma instead of a period',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'OK',
            timer: 2000
          });
        }
        if (event.key === 'Enter') {
          console.log('Enter key pressed');
          onEnterPressed && onEnterPressed(event.target.value);
        }
      }}
      error={error}
      helperText={helperText}
      style={{ width: width }}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

function FormikNumberInput({
  id,
  name,
  label,
  // value,
  // onChange,
  formik,
  tooltip,
  required,
  integerLimit, // limit of integers possible before comma
  decimalLimit, // limit of decimals possible after comma
  prefix, // prefix for the input
  suffix, // suffix for the input
  textColor,
  size,
  ...rest
}) {
  const { i18n } = useTranslation();

  const classes = useStyles();
  // const [values, setValues] = useState({
  //   textmask: formikvalue
  // });

  const handleChange = (event) => {
    event.preventDefault();
    console.log('new value', event.target.value);

    let value = event.target.value;

    // when entered in european style, convert to US / number style

    // setValues({
    //   ...values,
    //   [name]: value //! probably here I should use Intl.NumberFormat to format the number to locale
    // });

    if (getDecimalSeparator(i18n.language) === ',') {
      // remove all dots
      value = value.replace(/\./g, '');
      // replace comma with dot
      value = value.replace(',', '.');
    }

    // strip prefix, if present
    if (prefix) value = value.replace(prefix, '');

    // strip suffix, if present
    if (suffix) value = value.replace(suffix, '');

    let numValue = Number(value);

    console.log('new value', value, numValue);
    // onChange && onChange(value);

    formik.setFieldValue(name, numValue, true);
  };

  const handleEnterPressed = (value) => {
    // when entered in european style, convert to US / number style
    // if (getDecimalSeparator(i18n.language) === ',') {
    //   // remove all dots
    //   value = value.replace(/\./g, '');
    //   // replace comma with dot
    //   value = value.replace(',', '.');
    // }
    // let numValue = Number(value);
    // console.log('Weight Input: enter pressed: new value', value, numValue);
    // onEnterPressed && onEnterPressed(value);
  };

  return (
    <>
      {label && (
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <TooltipWrapper tooltip={tooltip}>
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color={textColor}
              {...rest}>
              {label}&nbsp;
              {required && (
                // <sup>
                <span style={{ color: 'red' }}>*</span>
                // </sup>
              )}
              {tooltip && (
                <ArgonBox ml={1} display="inline-block">
                  <ArgonTypography variant="regular">
                    <InfoOutlinedIcon color="secondary" />
                  </ArgonTypography>
                </ArgonBox>
              )}
            </ArgonTypography>
          </TooltipWrapper>
        </ArgonBox>
      )}

      <ArgonInput
        id={id}
        name={name}
        className={classes.formControl}
        value={formik.values[name]}
        onChange={handleChange}
        onEnterPressed={handleEnterPressed}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        // value={formik.values[name]}
        // onChange={formik.handleChange}
        inputComponent={TextMaskCustom}
        // inputProps={{ mask: mask }}
        size={size}
        inputProps={{
          //   inputComponent: TextMaskCustom

          integerLimit,
          decimalLimit,
          prefix,
          suffix

          // size
        }}
        variant="outlined"
        // margin="dense"

        {...rest}
      />
    </>
  );
}

// typechecking props for FormField
FormikNumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  textColor: PropTypes.string,
  formik: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  decimalLimit: PropTypes.number,
  integerLimit: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string
};

export default FormikNumberInput;
