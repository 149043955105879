export const invitationSubjectTemplate = (eventName) =>
  `Invitation to participate in ${eventName}`;

export const invitationBodyTemplate = ({
  eventName,
  startDate,
  endDate,
  expiryInDays,
  organizerName,
  locale
}) => {
  // cut out the time from the date

  let _startDate = Intl.DateTimeFormat(locale, {
    dateStyle: 'long'
    // timeStyle: 'none'
  })
    .format(new Date(startDate))
    .split(',')[0];

  let _endDate = Intl.DateTimeFormat(locale, {
    dateStyle: 'long'
    // timeStyle: 'none'
  })
    .format(new Date(endDate))
    .split(',')[0];

  let text = `Hey {{{recipientFirstName}}},<br /><br />You are invited to participate in ${eventName}`;

  if (_startDate === _endDate) text += ` on ${_startDate}.`;
  else text += `from ${_startDate} to ${_endDate}.`;

  text += `<br /><br />However, you must accept this invitation within ${expiryInDays} days by following the "Accept Button" below and finish the registration process. If you already now that you will not able to participate, please push the "Decline Button" to give other athletes a chance to participate.<br /> <br />We are looking forward to seeing you at the event! <br /> <br />Best regards, <br />${organizerName}`;

  return text;
};
