import React, { useEffect, useState } from 'react';
import DialogWrapper from 'components/DialogWrapper';
import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ArgonSelect from 'components/ArgonSelect';
import LoaderWrap from 'LoaderWrap';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import { EVENTINSTANCE_DIVISION_CREATE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import { stripeCreateNewPrice } from 'services/REST_API/stripe';
import { FormatMoney } from 'format-money-js';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import selectData from 'options/selectData';

const CloneDivisionsDialog = ({ currentEventInstance, open, onClose }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  console.log(
    'CloneDivisionsDialog: currentEventInstance: ',
    currentEventInstance
  );

  const [selectedEventInstanceId, setSelectedEventInstanceId] = useState(null);
  const [workInProgress, setWorkInProgress] = useState(false);

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  const [fetchEventInstances, { data, loading }] = useLazyQuery(
    EVENTINSTANCES_GET_BY_EVENTID,
    {
      onCompleted: (data) => {
        console.log(
          'CloneDivisions: Event Instances received from Database: ',
          data
        );
      },
      onError: (error) => {
        console.error(
          'CloneDivisions: Error getting event instances from Database: ',
          error
        );
      }
    }
  );

  const [cloneEventInstanceDivisions, { loading: cloningInProgress }] =
    useLazyQuery(EVENTINSTANCE_GET_BY_ID, {
      // variables: { eventInstanceId: eventInstanceId },
      onCompleted: (data) => {
        console.log(
          'CloneDivisions: Event Instances received from Database: ',
          data
        );

        Swal.fire({
          title: 'Confirm',
          text: 'Please confirm that you want to clone the divisions from the event instance. New divisions will be created with the same settings as the original divisions. Existing divisions will remain unchanged.',
          icon: 'warning',
          showCancelButton: true,
          // confirmButtonColor: '#3085d6',
          // cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, clone divisions!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            console.log('Cloning divisions...');
            setWorkInProgress(true);
            await cloneDivisions(data?.eventInstance?.divisions);
            setWorkInProgress(false);
            onClose && onClose();
          }
        });
      },
      onError: (error) => {
        console.error(
          'CloneDivisions: Error getting event instances from Database: ',
          error
        );
      }
    });

  const [createDivision] = useMutation(EVENTINSTANCE_DIVISION_CREATE, {
    onCompleted: (data) => {
      console.log('onCompleted: ', data);
      enqueueSnackbar(
        `Division "${data?.EventInstanceDivisionCreate?.name}" has been created!`,
        {
          variant: 'success'
        }
      );
    },
    onError: (error) => {
      console.error('onError: ', error);
      enqueueSnackbar(
        `Error creating division "${data?.EventInstanceDivisionCreate?.name}"!`,
        {
          variant: 'error'
        }
      );
    },
    refetchQueries: [
      {
        query: EVENTINSTANCE_GET_BY_ID,
        variables: { eventInstanceId: currentEventInstance?._id }
      }
    ]
  });

  useEffect(() => {
    if (currentEventInstance?.event?._id) {
      fetchEventInstances({
        variables: { eventId: currentEventInstance?.event?._id }
      });
    }
  }, [currentEventInstance]);

  const handleClose = () => {
    // setShow(false);
    onClose && onClose();
  };

  const handleCloneDivisions = async () => {
    console.log(
      'CloneDivisions: handleCloneDivisions',
      selectedEventInstanceId
    );

    cloneEventInstanceDivisions({
      variables: { eventInstanceId: selectedEventInstanceId }
    });
  };

  const cloneDivisions = async (previousDivisionsList) => {
    console.log('previousDivisionsList: ', previousDivisionsList);

    for (const division of previousDivisionsList) {
      console.log('division: ', division);

      let stripePriceId = division?.stripePriceId;

      if (currentEventInstance?.event?.stripeProductId) {
        console.log(
          'EventInstance has a stripe product, now taking care of prices...'
        );
        if (
          Number(division?.registrationFee) !==
            fm.un(division?.registrationFee) * 100 ||
          !stripePriceId
        ) {
          console.log(
            'No previous price for division or price has changed. Updating price...'
          );
          // creating new price for division
          await stripeCreateNewPrice({
            organizationId: currentEventInstance?.organizationId,
            productId: currentEventInstance?.stripeProductId,
            priceInCents: fm.un(division?.registrationFee) * 100,
            currency:
              currentEventInstance?.currency ||
              currentEventInstance?.event?.currency ||
              currentEventInstance?.event?.organization?.currency ||
              'EUR'
          })
            .then((res) => {
              console.log('Created new price: ', res);
              stripePriceId = res?.id;
            })
            .catch((err) => {
              console.error(err);
              enqueueSnackbar(
                'Something went wrong while creating price in Stripe. Please add this division manually.',
                {
                  variant: 'error'
                }
              );
            });
        }
      } else {
        console.log(
          'EventInstance has no stripe product, not taking care of prices in stripe...'
        );
      }

      const newDivisionParameters = {
        data: {
          eventInstanceId: currentEventInstance._id,
          division: {
            name: division.name,
            description: division.description,
            maxParticipants: division.maxParticipants,
            forIndividuals: division.forIndividuals,
            forTeams: division.forTeams,
            forUnderage: division.forUnderage,
            teamSize: division.teamSize,
            minAge: division.minAge,
            maxAge: division.maxAge,
            registrationFee: division.registrationFee,
            advancingAthletesNumber: division.advancingAthletesNumber,
            advancingAthletesPercentage: division.advancingAthletesPercentage,
            assignedJudges: division.assignedJudges,
            sequence: division.sequence,
            stripePriceId: division.stripePriceId
          }
        }
      };

      console.log('newDivisionParameters: ', newDivisionParameters);

      await createDivision({
        variables: newDivisionParameters
      });
    }
    return true;
  };

  return (
    <>
      <DialogWrapper
        title="Clone Divisions"
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm">
        <LoaderWrap loading={loading}>
          <ArgonSelect
            id="event-instance-select"
            name="event-instance-select"
            overflow
            label="Choose Event Instance to clone divisions from"
            options={data?.eventInstances
              ?.filter(
                (eventInstance) =>
                  eventInstance._id !== currentEventInstance._id
              )
              .map((eventInstance) => ({
                label:
                  eventInstance.name ||
                  selectData.eventInstanceTypes.find(
                    (eit) => eit.value === eventInstance.type
                  )?.label ||
                  eventInstance.type,
                value: eventInstance._id
              }))}
            onChange={(option) => setSelectedEventInstanceId(option?.value)}
          />
          <ArgonBox mt={3} mb={0} display="flex" justifyContent="flex-end">
            <ArgonButton
              variant="gradient"
              color="success"
              size="small"
              loading={cloningInProgress}
              onClick={handleCloneDivisions}>
              Clone Divisions
            </ArgonButton>
          </ArgonBox>
        </LoaderWrap>
      </DialogWrapper>
    </>
  );
};

export default CloneDivisionsDialog;
