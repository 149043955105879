import React, { useEffect, useState } from 'react';

// @mui core components

import Grid from '@mui/material/Grid';

// Data

import { useArgonController } from 'context';
import { AppBar, Icon, Tab, Tabs } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';

function EventInstanceMenuWrapper({ children }) {
  const { eventId, eventInstanceId } = useParams();

  const navigate = useNavigate();
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  // const tempSelectedTab = window?.location?.pathname?.split('/').pop();
  const tempLocation = window?.location?.pathname?.split('/');
  // const tempSelectedTab = "'" + tempLocation[tempLocation.length - 1] + "'";
  const tempSelectedTab = tempLocation[tempLocation.length - 1];
  console.log('tempLocation', tempLocation.length);

  console.log('tempSelectedTab', tempSelectedTab, typeof tempSelectedTab);

  const [selectedTab, setSelectedTab] = useState(
    tempLocation.length > 5 || tempLocation[tempLocation.length - 1 === '']
      ? tempSelectedTab
      : 'overview'
  );

  const handleTabChange = (event, newValue) => {
    console.log('handleTabChange', newValue);
    // setSelectedTab(newValue);
    navigate(`/organizer/events/${eventId}/${eventInstanceId}/${newValue}`);
  };

  useEffect(() => {
    if (eventInstanceId) {
      fetchEventInstance();
    }
  }, [eventInstanceId]);

  const [fetchEventInstance, { data }] = useLazyQuery(EVENTINSTANCE_GET_BY_ID, {
    variables: {
      eventInstanceId: eventInstanceId
    },
    onCompleted: (data) => {
      // console.log('EVENTINSTANCE_GET_BY_ID', data);
    },
    onError: (err) => {
      console.log('EventInstanceMenuWrapper: Fetching EventInstances:', err);
    }
  });

  const eventInstance = data?.eventInstance;

  return (
    <>
      <ArgonBox mt={4}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            <AppBar position="static">
              {eventInstance?.type === 'leaderboard' ? (
                <>
                  <Tabs
                    orientation={tabsOrientation}
                    value={selectedTab}
                    onChange={handleTabChange}>
                    <Tab
                      label="Overview"
                      value="overview"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Setup"
                      value="setup"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Workouts"
                      value="workouts"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Participants"
                      value="participants"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Competition Prep"
                      value="competitionPrep"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Leaderboard"
                      value="leaderboard"
                      style={{ fontWeight: 'bold' }}
                    />
                  </Tabs>
                </>
              ) : (
                <>
                  <Tabs
                    orientation={tabsOrientation}
                    value={selectedTab}
                    onChange={handleTabChange}>
                    <Tab
                      label="Overview"
                      value="overview"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Setup"
                      value="setup"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Merchandise"
                      value="merchandise"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Workouts"
                      value="workouts"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Participants"
                      value="participants"
                      style={{ fontWeight: 'bold' }}
                    />
                    <Tab
                      label="Communications"
                      value="communications"
                      style={{ fontWeight: 'bold' }}
                    />

                    {eventInstance?.isVirtualEvent ? (
                      <Tab
                        label="Judging"
                        value="judging"
                        style={{ fontWeight: 'bold' }}
                      />
                    ) : (
                      <Tab
                        label="Competition Prep"
                        value="competitionPrep"
                        style={{ fontWeight: 'bold' }}
                      />
                    )}
                    <Tab
                      label="Leaderboard"
                      value="leaderboard"
                      style={{ fontWeight: 'bold' }}
                    />
                  </Tabs>
                </>
              )}
            </AppBar>
          </Grid>

          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </ArgonBox>
    </>
  );
}

export default EventInstanceMenuWrapper;
