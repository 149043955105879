import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogWrapper from 'components/DialogWrapper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import ArgonInput from 'components/ArgonInput';
import FormikTextInput from 'components/FormikTextInput';
import ArgonDatePicker from 'components/ArgonDatePicker';
import { useTranslation } from 'react-i18next';
import FormikNumberInput from 'components/FormikNumberInput';
import ArgonCurrencyInput from 'components/ArgonCurrencyInput';
import ArgonButton from 'components/ArgonButton';
import ArgonBox from 'components/ArgonBox';
import Swal from 'sweetalert2';
import ObjectID from 'bson-objectid';
import { FormatMoney } from 'format-money-js';
import CurrencyInput from 'react-currency-input-field';
import ArgonTypography from 'components/ArgonTypography';
import InputMask from 'react-input-mask';
import { calculateSpectatorTicketPriceWithoutFee } from 'services/calculateFees';
import { calculateSpectatorTicketPriceWithFee } from 'services/calculateFees';

function SpectatorTicketsForm({
  spectatorTicket,
  paymentsEnabled,
  currency,
  onSave,
  onDelete,
  onClose,
  addNew
}) {
  const { t, i18n } = useTranslation();

  const [priceAfterSystemFee, setPriceAfterSystemFee] = useState(0);
  const [price, setPrice] = useState(0);

  console.log('SpectatorTicketForm', spectatorTicket);

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  useEffect(() => {
    if (spectatorTicket) {
      formik.resetForm();
      formik.setValues(spectatorTicket);
      formik.setFieldValue('validDate', new Date(spectatorTicket.validDate));
      setPriceAfterSystemFee(
        calculateSpectatorTicketPriceWithFee(spectatorTicket?.price) / 100
      );
      setPrice(spectatorTicket?.price / 100);
    }
  }, [spectatorTicket]);

  const validationSchema = Yup.object().shape({
    validDate: Yup.string().required('Valid on is required'),
    description: Yup.string().required('Description is required'),

    price: Yup.string().required('Price is required'),

    // currency: Yup.string().required('Currency is required'),
    initialQuantity: Yup.number().required('Initial quantity is required'),
    venueOpens: Yup.string().required('Venue opens is required'),
    venueCloses: Yup.string().required('Venue closes is required')
  });

  const formik = useFormik({
    initialValues: {
      _id: spectatorTicket?._id || new ObjectID().toHexString(),
      validDate: new Date(spectatorTicket?.validDate) || '',
      description: spectatorTicket?.description || '',
      //   price: Number(spectatorTicket?.price) / 100 || 0,
      tempPrice: Number(spectatorTicket?.price) || 0 / 100, // price before adding system fee, can be edited by event admin
      price: Number(spectatorTicket?.price) || 0 / 100, // price after adding system fee, cannot be edited by event admin
      //   currency: currency || 'EUR',
      initialQuantity: spectatorTicket?.initialQuantity || 5000,

      venueOpens: spectatorTicket?.venueOpens || '08:00',
      venueCloses: spectatorTicket?.venueCloses || '18:00',

      pax: spectatorTicket?.pax || 1
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newValues = {
        ...values,

        validDate: new Date(values.validDate).toLocaleDateString(
          i18n.language,
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }
        ),

        price: fm.un(price) * 100
      };

      console.log('Saving values:', newValues);
      onSave(newValues);
    }
  });

  console.log('SpectatorTicketForm: Formik values', formik.values);
  console.log('SpectatorTicketForm: Formik errors', formik.errors);

  const handleDatePickerChanged = (fieldName, newValue) => {
    console.log(fieldName, newValue[0]);
    if (newValue.length > 0) {
      formik.setFieldValue(fieldName, newValue[0]);
    } else {
      console.error(
        'New picked date is an array with multiple dates. Cannot update.'
      );
    }
  };

  const handleDeleteButtonClicked = () => {
    Swal.fire({
      title: 'Delete Ticket Offer?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete && onDelete(spectatorTicket?._id);
        onClose && onClose();
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ArgonBox ml={0.5}>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            // color={textColor}
          >
            Ticket is valid on (date) &nbsp;
            <span style={{ color: 'red' }}>*</span>
          </ArgonTypography>
        </ArgonBox>
        <ArgonDatePicker
          id="validDate"
          name="validDate"
          // defaultValue={formik.values.startDate}
          //   label="Ticket is valid on (date)"
          options={
            {
              // allowInput: true,
              // enableTime: true,
              // time_24hr: true,
              // defaultHour: 8
            }
          }
          input={{
            // size: 'small',
            placeholder: 'Select a date',
            error: formik.touched.validDate && Boolean(formik.errors.validDate),

            helperText: formik.touched.validDate && formik.errors.validDate
          }}
          onChange={(date) => {
            handleDatePickerChanged('validDate', date);
          }}
          value={formik.values.validDate}
          //   defaultValue={formik.values.validDate}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextInput
          fullWidth
          id="description"
          name="description"
          required
          label="Description"
          formik={formik}
        />
      </Grid>
      <Grid item xs={6}>
        <ArgonBox ml={0.5}>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            // color={textColor}
          >
            Venue opens at (hh:mm) &nbsp;
            <span style={{ color: 'red' }}>*</span>
          </ArgonTypography>
        </ArgonBox>
        <InputMask
          mask="99:99"
          value={formik.values.venueOpens}
          disabled={false}
          // maskChar={maskChar}
          placeholder={'hh:mm'}
          error={formik.touched.venueOpens && Boolean(formik.errors.venueOpens)}
          helperText={formik.touched.venueOpens && formik.errors.venueOpens}
          onChange={async (event) => {
            console.log('Input Mask Changed Event: ', event);
            //manualTimeFormatCheck(event.currentTarget.value);

            // if (event.key === 'Enter') {
            await formik.setFieldValue('venueOpens', event.currentTarget.value);

            // }
          }}>
          {(inputProps) => (
            <ArgonInput
              id="venueOpens"
              name="venueOpens"
              {...inputProps}
              // inputRef={inputRef}
              value={formik.values.venueOpens}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={6}>
        <ArgonBox ml={0.5}>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            // color={textColor}
          >
            Venue closes at (hh:mm) &nbsp;
            <span style={{ color: 'red' }}>*</span>
          </ArgonTypography>
        </ArgonBox>
        <InputMask
          mask="99:99"
          value={formik.values.venueCloses}
          disabled={false}
          // maskChar={maskChar}
          placeholder={'hh:mm'}
          error={
            formik.touched.venueCloses && Boolean(formik.errors.venueCloses)
          }
          helperText={formik.touched.venueCloses && formik.errors.venueCloses}
          onChange={async (event) => {
            console.log('Input Mask Changed Event: ', event);
            //manualTimeFormatCheck(event.currentTarget.value);

            // if (event.key === 'Enter') {
            await formik.setFieldValue(
              'venueCloses',
              event.currentTarget.value
            );

            // }
          }}
          style={{
            width: '100%'
          }}>
          {(inputProps) => (
            <ArgonInput
              id="venueCloses"
              name="venueCloses"
              {...inputProps}
              // inputRef={inputRef}
              value={formik.values.venueCloses}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={12}>
        <FormikNumberInput
          id="pax"
          name="pax"
          label="Pax / persons per ticket"
          required
          formik={formik}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberInput
          id="initialQuantity"
          name="initialQuantity"
          label="Initial Quantity for sale"
          required
          formik={formik}
        />
      </Grid>
      <Grid item xs={12}>
        <ArgonCurrencyInput
          id="price"
          name="price"
          label="Price per Ticket (incl. local taxes)"
          required
          //   intlConfig={{
          //     locale: i18n.language
          //     // currency: currency || 'EUR'
          //   }}
          //   // prefix={currency || 'EUR' + ' '}
          //   allowNegativeValue={false}
          //   placeholder=""
          //   defaultValue={0}
          //   decimalsLimit={2}
          //   decimalScale={2}
          //   //   value={fm.from(formik.values.price / 100)}
          //   value={(formik.values.price || 0) / 100}
          //   onValueChange={(value, name) => {
          //     console.log('CurrencyInput:', value, fm.un(value) * 100, name);
          //     formik.setFieldValue(name, fm.un(value || 0) * 100, true);
          //     // formik.setTouched(name, true);
          //   }}
          allowNegativeValue={false}
          value={price}
          defaultValue={0}
          intlConfig={{
            locale: i18n.language,
            currency: currency || 'EUR'
          }}
          decimalScale={2}
          // decimalsLimit={2}
          onValueChange={(value, name, values) => {
            console.log('CurrencyInput: values', values, fm.un(value));
            setPrice(value);

            setPriceAfterSystemFee(
              calculateSpectatorTicketPriceWithFee(fm.un(value) * 100) / 100
            );
            formik.setFieldValue(name, value);
          }}
          error={formik.touched.price && Boolean(formik.errors.price)}
          // transformRawValue={(value) => (value * 100).toString()}
          disabled={!paymentsEnabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ArgonCurrencyInput
          id="priceAfterSystemFee"
          name="priceAfterSystemFee"
          label="Price per Ticket after adding system fee"
          required
          //   intlConfig={{
          //     locale: i18n.language
          //     // currency: currency || 'EUR'
          //   }}
          //   // prefix={currency || 'EUR' + ' '}
          //   allowNegativeValue={false}
          //   placeholder=""
          //   defaultValue={0}
          //   decimalsLimit={2}
          //   decimalScale={2}
          //   //   value={fm.from(formik.values.price / 100)}
          //   value={(formik.values.price || 0) / 100}
          //   onValueChange={(value, name) => {
          //     console.log('CurrencyInput:', value, fm.un(value) * 100, name);
          //     formik.setFieldValue(name, fm.un(value || 0) * 100, true);
          //     // formik.setTouched(name, true);
          //   }}
          allowNegativeValue={false}
          value={priceAfterSystemFee}
          defaultValue={0}
          intlConfig={{
            locale: i18n.language,
            currency: currency || 'EUR'
          }}
          // decimalScale={2}
          decimalsLimit={2}
          // onValueChange={(value, name, values) => {
          //   console.log('CurrencyInput: values', values);

          //   setPrice(value);
          // }}
          // error={formik.touched.priceAfterSystemFee && Boolean(formik.errors.priceAfterSystemFee)}
          // transformRawValue={(value) => (value * 100).toString()}
          disabled={true}
        />
        <ArgonTypography variant="caption" color="text">
          <ArgonBox color="inherit" mt={1} ml={0.5}>
            STRONG ONE adds a fixed fee to the price of the ticket for using our
            platform. This price will be displayed to the spectator.
          </ArgonBox>
        </ArgonTypography>
      </Grid>

      <Grid item xs={12}>
        <ArgonBox mt={2} display="flex" justifyContent="space-between">
          <ArgonButton
            color="secondary"
            variant="outlined"
            onClick={() => onClose && onClose()}>
            Cancel
          </ArgonButton>
          <ArgonBox display="flex" flexDirection="row" gap={1}>
            {!addNew && (
              <ArgonButton
                color="error"
                variant="outlined"
                onClick={() => handleDeleteButtonClicked()}>
                Delete
              </ArgonButton>
            )}
            <ArgonButton
              color="success"
              variant="gradient"
              onClick={formik.handleSubmit}>
              Done
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </Grid>
    </Grid>
  );
}

SpectatorTicketsForm.propTypes = {};

export default SpectatorTicketsForm;
