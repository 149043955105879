import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

function TooltipWrapper({ tooltip, children }) {
  if (tooltip) {
    console.log('TooltipWrapper tooltip: ', tooltip);
    return (
      <>
        <Tooltip title={tooltip} arrow>
          <span>{children}</span>
        </Tooltip>
      </>
    );
  } else {
    return children;
  }
}

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string
};

export default TooltipWrapper;
