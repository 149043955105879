import { gql } from '@apollo/client';
import { ORGANIZATION_FRAGMENT } from './fragments';

export const ORGANIZATION_GET_BY_ID = gql`
  query getOrganizationById($id: ObjectId!) {
    organization(query: { _id: $id }) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
