/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from 'react';

// // @mui material components
// import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Settings page components
import BaseLayout from '../BaseLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import EventSetup from './EventSetup';
import EventInstancePage from './EventInstances/Setup';

function EventDetails() {
  const { eventId } = useParams();

  let [searchParams] = useSearchParams();
  console.log('Search Params: ', searchParams);
  // const navigate = useNavigate();
  // const location = useLocation();

  const [selectedEventInstanceId, setSelectedEventInstanceId] =
    React.useState(undefined);

  const [isNewRecord, setIsNewRecord] = React.useState(
    searchParams?.get('new') === 'true'
    // Boolean(eventId == 'create')
  );

  return (
    <BaseLayout eventId={eventId} navbarTitle="Event Details">
      <ArgonBox mt={4} mb={4}>
        {!selectedEventInstanceId ? (
          <EventSetup eventId={eventId} isNewRecord={isNewRecord} />
        ) : (
          <EventInstancePage
            key={selectedEventInstanceId}
            eventInstanceId={selectedEventInstanceId}
            eventId={eventId}
          />
        )}
      </ArgonBox>
    </BaseLayout>
    // </BaseLayout>
  );
  // }
}

export default EventDetails;
