import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import DetailedStatisticsCard from './DetailedStatisticsCard';
import { getTotalRevenueForOrganization } from 'services/restApi';
import Loader from 'Loader';
import ArgonTypography from 'components/ArgonTypography';
import moment from 'moment';
import { getTotalRegistrationsForOrganization } from 'services/restApi';

function TopRowStatisticsCards({ organizationId }) {
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [currency, setCurrency] = useState();
  const [netRevenue, setNetRevenue] = useState('--');
  const [countRegistrations, setCountRegistrations] = useState('--');
  const [amountRegistrations, setAmountRegistrations] = useState('--');
  const [amountTickets, setAmountTickets] = useState('--');
  const [amountMerchOrders, setAmountMerchOrders] = useState('--');
  const [amountOutstanding, setAmountOutstanding] = useState('--');

  const [amountRefunds, setAmountRefunds] = useState('--');

  useEffect(() => {
    if (organizationId) {
      // get first day of this year
      var fromDate = moment().startOf('year').toISOString();
      let toDate = moment().toISOString();

      setLoading(true);
      // get total registrations YTD
      getTotalRegistrationsForOrganization({
        organizationId,
        fromDate,
        toDate
      })
        .then((res) => {
          console.log(
            'TopRowStatisticsCards: total registrations ',
            res?.data.totalRegistrations
          );
          if (res?.data?.totalRegistrations > 0)
            setCountRegistrations('# ' & res?.data?.totalRegistrations);
          else setCountRegistrations('--');

          // get financials for organization YTD
          getTotalRevenueForOrganization({
            organizationId,
            fromDate,
            toDate
          }).then((res) => {
            console.log('TopRowStatisticsCards: financials', res?.data);
            setData(res?.data);

            let currency = res?.data[0]?.currency || 'EUR';
            if (currency === 'EUR') currency = '€';
            if (currency === 'USD') currency = '$';
            if (currency === 'GBP') currency = '£';

            // const netRev = `${currency && currency + ' '}${Math.round(
            //   res?.data[0]?.amountNetRevenueForOrganization
            // )}`;

            if (res?.data[0]?.amountNetRevenueForOrganization > 0)
              setNetRevenue(
                `${currency && currency + ' '}${Math.round(
                  (res?.data[0]?.amountNetRevenueForOrganization || 0) / 100
                )}`
              );
            if (res?.data[0]?.amountRegistrations > 0)
              setAmountRegistrations(
                `${currency && currency + ' '}${Math.round(
                  (res?.data[0]?.amountRegistrations || 0) / 100
                )}`
              );
            if (res?.data[0]?.amountTickets > 0)
              setAmountTickets(
                `${currency && currency + ' '}${Math.round(
                  (res?.data[0]?.amountTickets || 0) / 100
                )}`
              );
            if (res?.data[0]?.amountMerchOrders > 0)
              setAmountMerchOrders(
                `${currency && currency + ' '}${Math.round(
                  res?.data[0]?.amountNetRevenueForOrganization / 100
                )}`
              );
            if (res?.data[0]?.amountTotalRefunded > 0)
              setAmountRefunds(
                `${currency && currency + ' '}${Math.round(
                  (res?.data[0]?.amountTotalRefunded || 0) / 100
                )}`
              );

            if (res?.data[0]?.amountOutstanding > 0)
              setAmountOutstanding(
                `${currency && currency + ' '}${Math.round(
                  (res?.data[0]?.amountOutstanding || 0) / 100
                )}`
              );
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [organizationId]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={3}>
        <DetailedStatisticsCard
          title="YTD Net Revenue"
          infoText="Total sales minus refunds, outstanding payments and collected platform fees since the beginning of this year "
          count={netRevenue}
          icon={{
            color: 'success',
            component: <i className="ni ni-money-coins" />
          }}
          //   percentage={{
          //     color: 'success',
          //     count: '+55%',
          //     text: 'since yesterday'
          //   }}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DetailedStatisticsCard
          title="YTD Registrations"
          infoText="Total number of registrations for all events since the beginning of the year"
          count={countRegistrations}
          icon={{
            color: 'error',
            component: <i className="ni ni-world" />
          }}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DetailedStatisticsCard
          title="YTD Refunds"
          infoText="Total amount refunded to customers since the beginning of the year"
          count={amountRefunds}
          icon={{
            color: 'success',
            component: <i className="ni ni-paper-diploma" />
          }}
          // percentage={{
          //   color: 'error',
          //   count: '-2%',
          //   text: 'since last quarter'
          // }}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DetailedStatisticsCard
          title="Outstanding payments"
          infoText="Amounts due but not yet collected"
          count={amountOutstanding}
          icon={{
            color: 'warning',
            component: <i className="ni ni-cart" />
          }}
          // percentage={{
          //   color: 'success',
          //   count: '+5%',
          //   text: 'than last month'
          // }}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

TopRowStatisticsCards.propTypes = {};

export default TopRowStatisticsCards;
