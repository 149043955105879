import React, { useState } from 'react';

// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonInput from 'components/ArgonInput';
import ArgonSelect from 'components/ArgonSelect';
import TooltipWrapper from 'components/TooltipWrapper';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function FormikYesNoInput({
  id,
  name,
  label,
  required,
  textColor,
  tooltip,
  formik,
  yesInfo,
  noInfo,
  ...rest
}) {
  const options = [
    {
      value: true,
      label: 'Yes' + (yesInfo ? ` (${yesInfo})` : '')
    },
    {
      value: false,
      label: 'No' + (noInfo ? ` (${noInfo})` : '')
    }
  ];

  return (
    <>
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <TooltipWrapper tooltip={tooltip}>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            color={textColor}
            {...rest}>
            {label}&nbsp;
            {required && (
              // <sup>
              <span style={{ color: 'red' }}>*</span>
              // </sup>
            )}
            {tooltip && (
              <ArgonBox ml={1} display="inline-block">
                <ArgonTypography variant="regular">
                  <InfoOutlinedIcon color="secondary" />
                </ArgonTypography>
              </ArgonBox>
            )}
          </ArgonTypography>
        </TooltipWrapper>
      </ArgonBox>
      <ArgonSelect
        options={options}
        id={id}
        name={name}
        value={options?.find((o) => o.value == formik.values[name])}
        onChange={(option) => {
          console.log('option:', option);
          formik.setFieldValue(name, option.value);
        }}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        {...rest}
      />
    </>
  );
}

// typechecking props for FormField
FormikYesNoInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  textColor: PropTypes.string,
  formik: PropTypes.object.isRequired
};

export default FormikYesNoInput;
