import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

import * as Yup from 'yup';

export const getParticipantInfoToAcceptTerms = ({
  participantId,
  passwordToken
}) =>
  new Promise(async (resolve, reject) => {
    if (!participantId) reject(new Error('registrationId is required'));
    if (!passwordToken) reject(new Error('token is required'));

    // const session = await getSession();
    console.log('Fetching Data from REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/accept-tos/get-data`,

      headers: {
        // Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ participantId, passwordToken })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const sendParticipantTosAcceptanceResult = ({
  participantId,
  passwordToken,
  hasAgreedToS,
  email,
  firstName,
  lastName,
  gender,
  dateOfBirth,
  userId,
  createUserAccount,
  newPassword,
  customRequiredFields
}) =>
  new Promise(async (resolve, reject) => {
    if (!participantId) reject(new Error('registrationId is required'));
    if (!passwordToken) reject(new Error('password token is required'));

    // const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/accept-tos/handle-result`,

      headers: {
        // Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        participantId,
        passwordToken,
        hasAgreedToS,
        email,
        firstName,
        lastName,
        gender,
        dateOfBirth,
        userId,
        createUserAccount,
        newPassword,
        customRequiredFields
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const manuallySendParticipantTOSAcceptanceMail = ({ participantId }) =>
  new Promise(async (resolve, reject) => {
    if (!participantId) reject(new Error('registrationId is required'));

    const session = await getSession();
    console.log('Fetching Data from REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/send-tos-reminder`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ participantId })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const manuallyAcceptParticipantToSAsOrganizer = ({ participantId }) =>
  new Promise(async (resolve, reject) => {
    if (!participantId) reject(new Error('registrationId is required'));

    const session = await getSession();
    console.log('Fetching Data from REST API...');

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/mark-tos-accepted`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ participantId })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const initiateAutoInvitations = ({
  inviteToEventInstanceId,
  inviteFromEventInstanceId,
  messageTemplateId,
  inviteSubject,
  inviteMessageBody,
  inviteExpiryInDays,
  messageSenderEmail,
  messageSenderName,
  invitesPerDivision
}) =>
  new Promise(async (resolve, reject) => {
    // if (!participantId) reject(new Error('registrationId is required'));

    try {
      const schema = Yup.object().shape({
        inviteToEventInstanceId: Yup.string().required(),
        inviteFromEventInstanceId: Yup.string().required(),
        messageTemplateId: Yup.string().required(),

        inviteSubject: Yup.string().required(),
        inviteMessageBody: Yup.string().required(),
        inviteExpiryInDays: Yup.number().required(),

        messageSenderEmail: Yup.string().required(),
        messageSenderName: Yup.string().required(),
        invitesPerDivision: Yup.array().required()
      });

      await schema
        .validate({
          inviteToEventInstanceId,
          inviteFromEventInstanceId,
          messageTemplateId,
          inviteSubject,
          inviteMessageBody,
          inviteExpiryInDays,
          messageSenderEmail,
          messageSenderName,
          invitesPerDivision
        })
        .catch((error) => {
          throw new Error('Validation Error: ' + error.message);
        });

      const session = await getSession();
      console.log('Fetching Data from REST API...');

      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration-invite/auto/invoke`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          inviteToEventInstanceId,
          inviteFromEventInstanceId,
          messageTemplateId,
          inviteSubject,
          inviteMessageBody,
          inviteExpiryInDays,
          messageSenderEmail,
          messageSenderName,
          invitesPerDivision
        })
      };

      axios(config)
        .then(function (response) {
          console.log('data from axios request', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
