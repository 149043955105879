import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

export const getOrdersPaginated = ({
  pageOffset,
  pageSize,
  sortByField,
  sortDirection,
  filterPaymentStatus,
  filterOrderStatus,
  filterOrganizationId
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/order/get-paginated`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        page: pageOffset || 0,
        pageSize: pageSize || 10,
        sortByField: sortByField || 'creationDate',
        sortDirection: sortDirection || 1,
        filterPaymentStatus,
        filterOrderStatus,
        filterOrganizationId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const markOrderAsFulfilled = ({ orderId }) =>
  new Promise(async (resolve, reject) => {
    if (!orderId) reject('No orderId provided');

    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/order/mark-as-completed`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        orderId: orderId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
