export const calculatePlatformFee = (registrationFee) => {
  // REACT_APP_TDU_FIXED_FEE=2
  // REACT_APP_TDU_PERCENTAGE_FEE=4

  if (!registrationFee) return 0;
  if (registrationFee === 0) return 0;

  const platformFee =
    Number(process.env.REACT_APP_TDU_FIXED_FEE) +
    (Number(registrationFee) * process.env.REACT_APP_TDU_PERCENTAGE_FEE) / 100;
  console.log('platformFee', platformFee);

  return Number(platformFee);
};

export const calculateSpectatorTicketPriceWithFee = (priceInCents) =>
  Number(priceInCents) === 0
    ? 0
    : Number(priceInCents) +
      Number(process.env.REACT_APP_TICKET_FIXED_FEE) * 100;

export const calculateSpectatorTicketPriceWithoutFee = (priceInCents) =>
  Number(priceInCents) === 0
    ? 0
    : Number(priceInCents) -
      Number(process.env.REACT_APP_TICKET_FIXED_FEE) * 100;
