import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getOrdersPaginated } from 'services/REST_API/orders';
import { downloadCSV } from 'services/csv-download';
import DialogWrapper from 'components/DialogWrapper';
import ArgonSelect from 'components/ArgonSelect';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import { useTranslation } from 'react-i18next';

function ExportCsvFilterDialog({ open, onClose }) {
  const { t, i18n } = useTranslation();

  const filterPaymentOptions = [
    {
      value: undefined,
      label: 'all'
    },
    {
      value: 'paid',
      label: 'paid'
    },
    {
      value: 'open',
      label: 'open'
    },
    {
      value: 'pending',
      label: 'pending'
    },
    {
      value: 'free',
      label: 'free'
    }
  ];

  const filterOrderOptions = [
    {
      value: undefined,
      label: 'all'
    },
    {
      value: 'open',
      label: 'open'
    },
    {
      value: 'completed',
      label: 'completed'
    }
  ];

  const [filterPaymentStatus, setFilterPaymentStatus] = useState(
    filterPaymentOptions[0]
  );
  const [filterOrderStatus, setFilterOrderStatus] = useState(
    filterOrderOptions[0]
  );

  const [workInProgress, setWorkInProgress] = useState(false);

  const prepareData = (orderData) => {
    const tempData = orderData?.map((order) => ({
      ...order,
      formattedAmountDue: new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: order.currency || 'EUR',
        minimumFractionDigits: 2
      }).format(order.totalAmountDue / 100)
      // customerName:
      //   order?.createdBy?.lastName + ', ' + order?.createdBy?.firstName,
      // customerEmail: order?.createdBy?.email
    }));

    console.log('Prepared data: ', tempData);

    return tempData;
  };

  const fetchSelectedDataForDownload = () =>
    new Promise(async (resolve, reject) => {
      let pageSize = 50;
      let index = 0;
      let availablePages = 0;
      let rowsForExport = [];

      try {
        const { data } = await getOrdersPaginated({
          pageOffset: index,
          pageSize: pageSize,
          // sort: sortField,
          // sortDirection: sortDirection,
          filterPaymentStatus: filterPaymentStatus.value,
          filterOrderStatus: filterOrderStatus.value
        });

        console.log('Order data from backend at page 0: ', data);

        //   const fetchedData = Array.from(
        //     data?.SearchParticipantsPaginated[0]?.searchResults
        //   );

        data?.orders.forEach((row) => {
          rowsForExport.push(row);
        });

        availablePages = Math.ceil(data?.orders[0]?.totalCount / pageSize) || 0;

        console.log('availablePages: ', availablePages);

        // keep fetching as long as there are pages available
        while (index < availablePages) {
          index++;
          const { data } = await getOrdersPaginated({
            pageOffset: index * pageSize || 0,
            pageSize: pageSize,
            // sort: sortField,
            // sortDirection: sortDirection,
            filterPaymentStatus: filterPaymentStatus.value,
            filterOrderStatus: filterOrderStatus.value
          });
          // const fetchedData = Array.from(
          //   data?.SearchParticipantsPaginated[0]?.searchResults
          // );
          // console.log('fetchedData at index ', index, fetchedData);

          data?.orders.forEach((row) => {
            rowsForExport.push(row);
          });
        }
        // console.log('all rows for export', rowsForExport);

        // setExportData(rowsForExport);
        resolve(prepareData(rowsForExport));
      } catch (error) {
        console.error('Error fetching data for export: ', error);
        reject(error);
      }
    });

  const handleDownloadCsvButtonClicked = () => {
    setWorkInProgress(true);
    fetchSelectedDataForDownload()
      .then((result) => {
        downloadCSV(result, [
          '_id',
          'creationDate',

          'invoiceId',
          'invoicePdfUrl',
          'invoiceUrl',

          'merchandise',
          'merchandiseTotal',
          'orderStatus',
          'organization',
          'paymentIntentId',
          'paymentStatus',
          'registrations',
          'registrationsTotal',

          'customerFirstName',
          'customerLastName',
          'customerEmail',

          'customerAddress1',
          'customerAddress2',
          'customerCity',
          'customerState',
          'customerCountry',

          'customerZip',

          'shippingName',
          'shippingAddress1',
          'shippingAddress2',
          'shippingZip',
          'shippingCity',
          'shippingState',
          'shippingCountry',

          'stripeCustomerId',
          'shippingFee',
          'systemFeeTotal',

          'taxAmounts',
          'tickets',
          'ticketsTotal',
          'totalAmountDue',
          'currency',
          'totalCount'
        ]);
      })
      .catch((error) => {
        console.error('Error downloading CSV', error);
      })
      .finally(() => {
        setWorkInProgress(false);
      });
  };

  const handleClose = (event) => {
    event?.preventDefault();
    onClose() && onClose();
  };

  return (
    <div>
      <DialogWrapper
        open={open}
        title="Export Orders to CSV"
        onClose={onClose}
        onConfirm={handleDownloadCsvButtonClicked}
        confirmButtonLabel="Download CSV"
        confirmButtonDisabled={workInProgress}>
        <ArgonSelect
          options={filterPaymentOptions}
          value={filterPaymentStatus}
          onChange={(event) => {
            setFilterPaymentStatus(event.target.value);
          }}
          label="Payment Status"
          fullWidth
          margin="normal"
        />
        <ArgonSelect
          options={filterOrderOptions}
          value={filterOrderStatus}
          onChange={(event) => {
            setFilterOrderStatus(event.target.value);
          }}
          label="Order Status"
          fullWidth
          margin="normal"
        />
        <ArgonBox
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <ArgonButton
            variant="outlined"
            color="dark"
            onClick={handleClose}
            disabled={workInProgress}>
            Cancel
          </ArgonButton>
          <ArgonButton
            variant="contained"
            color="primary"
            onClick={handleDownloadCsvButtonClicked}
            loading={workInProgress}>
            Export Data
          </ArgonButton>
        </ArgonBox>
      </DialogWrapper>
    </div>
  );
}

ExportCsvFilterDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ExportCsvFilterDialog;
