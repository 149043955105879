import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { USER_GET_BY_ID } from 'GraphQL/Users/queries';
import { getSession } from 'services/cognito/cognitoAccount';
import ArgonAvatar from 'components/ArgonAvatar';
import { useNavigate } from 'react-router-dom';
import { USER_UPDATE } from 'GraphQL/Users/mutations';

function UserAccountCircle(props) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(undefined);
  const [userGroups, setUserGroups] = useState(undefined);

  const [gqlUpdateUser] = useMutation(USER_UPDATE);

  // fetch logged in user from db
  const [fetchLoggedInUser] = useLazyQuery(USER_GET_BY_ID, {
    onCompleted: async (data) => {
      console.log('UserAccountCircle: Logged in user data', data);
      // console.log('Logged In User:', data);
      if (data.user?._id) {
        const userId = data.user?._id;
        setUserData(data?.user);

        var restoreId = data?.user?.fcWidgetId; //Which need to be fetched from your DB
        console.log('FC Widget: Restore ID: ', restoreId);

        window.fcWidgetMessengerConfig = {
          externalId: userId, // user's id unique to your system
          restoreId: restoreId ? restoreId : null
        };
        await window.fcWidget?.user.get(function (resp) {
          var status = resp && resp.status,
            data = resp && resp.data;
          console.log('FC Widget got User Data: ', data, status);
          if (status !== 200) {
            window.fcWidget.user.setProperties({
              firstName: data?.user?.firstName, // user's first name
              lastName: data?.user?.lastName, // user's last name
              email: data?.user?.email, // user's email address
              userGroups: userGroups,
              externalId: userId, // user's id unique to your system
              lastUrl: window.location.href
            });
            window.fcWidget.on('user:created', function (resp) {
              console.log(
                "FC Widget User Created, User's ID: ",
                resp && resp.data && resp.data.id
              );
              var status = resp && resp.status,
                data = resp && resp.data;
              if (status === 200) {
                if (data?.restoreId) {
                  // Update restoreId in your database
                  console.log('FC Widget: Restore ID:', data.restoreId);
                  gqlUpdateUser({
                    variables: {
                      userId: data?.user?._id,
                      data: {
                        fcWidgetId: data.restoreId
                      }
                    }
                  })
                    .then((res) => {
                      console.log('FC Widget: Restore ID Updated in DB:', res);
                    })
                    .catch((err) => {
                      console.error(
                        'FC Widget: Restore ID Update Failed:',
                        err
                      );
                    });
                }
              }
            });
          } else {
            window.fcWidget.user.setProperties({
              firstName: data?.user?.firstName, // user's first name
              lastName: data?.user?.lastName, // user's last name
              email: data?.user?.email, // user's email address
              userGroups: userGroups,
              lastUrl: window.location.href,
              externalId: userId, // user's id unique to your system
              restoreId: restoreId // unique id to restore conversations across devices
            });
            console.log(
              'FC Widget: User was loged in, properties set anyway...'
            );

            // now checking for restoreId
            if (!restoreId) {
              window.fcWidget?.user?.get().then((resp) => {
                const newRestoreId = resp?.data?.restoreId;

                console.log(
                  'FC Widget: Restore ID to set in daabase:',
                  newRestoreId
                );
                if (newRestoreId) {
                  gqlUpdateUser({
                    variables: {
                      userId: userId,
                      data: {
                        fcWidgetId: newRestoreId
                      }
                    }
                  })
                    .then((res) => {
                      console.log('FC Widget: Restore ID Updated in DB:', res);
                    })
                    .catch((err) => {
                      console.error(
                        'FC Widget: Restore ID Update Failed:',
                        err
                      );
                    });
                }
              });
            }
          }
        });
      } else {
        setUserData(undefined);
      }
      console.log('data', data);
    }
  });

  useEffect(() => {
    getSession()
      .then((session) => {
        const userId = session['custom:userId'];
        const userGroups = session['cognito:groups'];
        setUserGroups(userGroups);
        fetchLoggedInUser({ variables: { userId: userId } });
      })
      .catch((err) => {
        console.log("DefaultNavBar: Couldn't get session, no user logged in.");
        console.log(err);

        navigate('/login', { replace: true });
      });
  }, []);

  return (
    <div>
      <ArgonAvatar
        bgColor="info"
        src={userData?.pic_url}
        alt={userData?.firstName + ' ' + userData?.lastName}
        variant="rounded"
        size="xs"
        shadow="sm"></ArgonAvatar>
    </div>
  );
}

UserAccountCircle.propTypes = {};

export default UserAccountCircle;
