import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Handlebars from 'handlebars';
import { useLazyQuery } from '@apollo/client';
import { MESSAGETEMPLATE_GET_BY_ID } from 'GraphQL/MessageTemplates/queries';
import { createButton } from './createButton';

function PreviewScheduledMessage({
  scheduledMessage,
  messageTemplateId,
  simulateAcceptDeclineButtons
}) {
  const [_parsedHtml, _setParsedHtml] = useState();

  console.log('PreviewScheduledMessage: scheduledMessage:', scheduledMessage);
  console.log('PreviewScheduledMessage: messageTemplateId', messageTemplateId);

  const [fetchMessageTemplate, { loading, data }] = useLazyQuery(
    MESSAGETEMPLATE_GET_BY_ID,
    {
      onCompleted: (data) => {
        console.log('PreviewScheduledMessage: received from Database: ', data);
        // const { name, html, design } = data.messagetemplate;

        // console.log('PreviewScheduledMessage: Loading design...', design);
      },
      onError: (error) => {
        console.error(
          'PreviewScheduledMessage: Error receiving MessageTemplate:',
          error
        );
      }
    }
  );

  useEffect(() => {
    fetchMessageTemplate({
      variables: { id: messageTemplateId }
    }).then((result) => {
      try {
        // const templateData = data?.messagetemplate.html;

        console.log('PreviewScheduledMessage: data', result.data);

        const messageTemplate = result.data?.messagetemplate?.html;

        console.log(
          'PreviewScheduledMessage: messageTemplate',
          messageTemplate
        );

        let buttonAccept = createButton({
          // template: buttonTemplate,
          buttonColor: '#00bb2d', // 'green',
          buttonText: 'Accept Invite',
          buttonLink: process.env.TDU_WEBSITE + '/events/'
        });

        let buttonDecline = createButton({
          // template: buttonTemplate,
          buttonColor: 'red',
          buttonText: 'Decline Invite',
          buttonLink: process.env.TDU_WEBSITE + '/events/'
        });

        const messageBodyVariables = {
          recipientFirstName: scheduledMessage?.recipientFirstName || 'John',
          recipientLastName: scheduledMessage?.recipientLastName || 'Doe',
          messageHeadline:
            scheduledMessage?.messageHeadline || 'Test Message Headline'
        };

        // first, parse scheduledMessage messageBody in case any variable were used there
        const messageBodyParser = Handlebars.compile(
          scheduledMessage?.messageBody || ''
        );

        const messageBody = messageBodyParser(messageBodyVariables);

        console.log('PreviewScheduledMessage: message body:', messageBody);

        const templateVariables = {
          recipientFirstName: 'John',
          recipientLastName: 'Doe',
          messageHeadline:
            scheduledMessage?.messageHeadline || 'Test Message Headline',
          messageContent: messageBody || 'Test Message Content',
          button: simulateAcceptDeclineButtons
            ? '<div style="display: flex;">' +
              buttonAccept +
              buttonDecline +
              '</div>'
            : ''
        };

        // then parse template with result from previpus parsing
        const handlebarsTemplate = Handlebars.compile(messageTemplate);
        const parsedHtml = handlebarsTemplate(templateVariables);

        console.log('PreviewScheduledMessage: Parsed HTML:', parsedHtml);

        _setParsedHtml(parsedHtml);
      } catch (e) {
        console.error('Error:', e);
      }
    });
  }, [scheduledMessage, messageTemplateId]);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: _parsedHtml
      }}></div>
  );
}

PreviewScheduledMessage.propTypes = {
  scheduledMessage: PropTypes.object,
  messageTemplateId: PropTypes.string
};

export default PreviewScheduledMessage;
