import React from 'react';
import PropTypes from 'prop-types';

import * as DOMPurify from 'dompurify';

function RenderSafeHtml({ rawHTML }) {
  return (
    <div
      style={{ width: '100%' }}
      className="argonEditorRender"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawHTML) }}
    />
  );
}

RenderSafeHtml.propTypes = {
  rawHTML: PropTypes.string.isRequired
};

export default RenderSafeHtml;
