/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

// Argon Dashboard 2 PRO MUI helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { transparent } = colors;
const { size } = typography;

const MuiAlert = {
  styleOverrides: {
    root: {
      fontSize: size.sm,

      '.MuiAlert-message': {
        fontSize: size.sm
      },
      '.MuiAlertTitle-root': {
        fontSize: size.sm
      }
    }
  }
};

export default MuiAlert;
