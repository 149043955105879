import 'assets/css/ka-table.css';

import React, { useEffect, useState } from 'react';

import { Card } from '@mui/material';
import ArgonBox from 'components/ArgonBox';

import { useNavigate } from 'react-router-dom';
import { getSession } from 'services/cognito/cognitoAccount';

import LoaderWrap from 'LoaderWrap';

import ArgonBadge from 'components/ArgonBadge';

import {
  ActionType,
  DataType,
  FilteringMode,
  PagingPosition,
  SortingMode,
  Table,
  useTable
} from 'ka-table';

import { useTranslation } from 'react-i18next';
import { getOrdersPaginated } from 'services/REST_API/orders';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';
import { Link } from 'react-router-dom';

const OpenOrdersList = (props) => {
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const [showExportCsvDialog, setShowExportCsvDialog] = useState(false);

  const [organizationId, setOrganizationId] = useState(null);

  const [showFilters, setShowFilters] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [searchInputText, setSearchInputText] = React.useState('');

  const [pageIndex, setPageIndex] = useState(0);
  // const [downloadPageIndex, setdownloadPageIndex] = useState(0);

  const [preparedData, setPreparedData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [paymentStatusFilterOption, setPaymentStatusFilterOption] = useState({
    value: undefined,
    label: 'all'
  });
  const [orderStatusFilterOption, setOrderStatusFilterOption] = useState({
    value: undefined,
    label: 'all'
  });

  //  const [exportData, setExportData] = useState([]);

  // remembers input field value
  const handleSearchInputTextChange = (event) => {
    setSearchInputText(event.target.value);
  };

  // transfers input field value to search text to fire search
  const handleSearchInputTextSubmit = (event) => {
    setSearchText(searchInputText);
  };

  const [pageSizeOption, setPageSizeOption] = useState(5);

  const pageSizeOptions = [10, 25, 50];

  const [totalPagesCount, setTotalPagesCount] = useState(0);

  const table = useTable({
    onDispatch: async (action) => {
      console.log('action', action);
      if (action.type === ActionType.UpdatePageIndex) {
        console.log('Showing page index:', action.pageIndex);
        setPageIndex(action.pageIndex);
      }

      if (action.type === ActionType.UpdatePageSize) {
        console.log('Updating page size:', action.pageSize);
        setPageSizeOption(action.pageSize);
      }
    }
  });

  useEffect(() => {
    // fetch data from REST API
    setLoading(true);
    getOrdersPaginated({
      pageOffset: pageIndex,
      pageSize: pageSizeOption,
      // sort: 'creationDate',
      sortDirection: -1,
      filterPaymentStatus: paymentStatusFilterOption.value,
      // filterOrderStatus: orderStatusFilterOption.value,
      filterOrderStatus: 'open',
      searchText
    })
      .then((res) => {
        prepareData(res?.data.orders);
        setTotalPagesCount(res?.data?.totalCount);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    searchText,
    pageIndex,
    pageSizeOption,
    paymentStatusFilterOption,
    orderStatusFilterOption
  ]);

  const handlePaymentStatusFilterChange = (option) => {
    console.log('Payment status filter changed:', option);
    setPaymentStatusFilterOption(option);
  };

  const handleOrderStatusFilterChange = (option) => {
    console.log('Order status filter changed:', option);
    setOrderStatusFilterOption(option);
  };

  const prepareData = (orderData) => {
    const tempData = orderData?.map((order) => ({
      ...order,
      formattedAmountDue: new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: order.currency || 'EUR',
        minimumFractionDigits: 2
      }).format(order.totalAmountDue / 100),
      customerName:
        order?.customerLastName &&
        order?.customerFirstName &&
        order?.customerLastName + ', ' + order?.customerFirstName
    }));

    console.log('Prepared data: ', tempData);

    setPreparedData(tempData);
  };

  const handleExportCsvButtonClicked = () => {
    setShowExportCsvDialog(true);
  };

  return (
    <Card sx={{ maxHeight: '19.25rem', height: '100%' }}>
      <LoaderWrap show={!preparedData}>
        <ArgonBox p={3}>
          <ArgonBox display="flex" justifyContent="space-between" mb={1}>
            <ArgonTypography variant="h5" textTransform="capitalize">
              Open Orders
            </ArgonTypography>
            <ArgonBox>
              <ArgonButton
                variant="text"
                color="secondary"
                size="small"
                component={Link}
                to="/organizer/orders">
                See all orders
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>

          <ArgonBox mt={0} pb={0} sx={{ overflow: 'scroll', height: '100%' }}>
            {/* <ArgonBox
            display="flex"
            alignItems="center"
            mb={1}
            justifyContent="end">
            <ArgonBox display="flex" alignItems="center" mr={2}>
              <ArgonTypography
                variant="regular"
                // fontWeight="bold"
                color="secondary">
                Payment status:
              </ArgonTypography>
              <ArgonBox ml={1}>
                <ArgonSelect
                  id="paymentStatusSelection"
                  name="paymentStatusSelection"
                  size="small"
                  options={[
                    { value: undefined, label: 'all' },
                    { value: 'open', label: 'open' },
                    { value: 'paid', label: 'paid' },
                    { value: 'free', label: 'free' }
                  ]}
                  value={paymentStatusFilterOption}
                  onChange={handlePaymentStatusFilterChange}
                />
              </ArgonBox>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center" mr={2}>
              <ArgonTypography
                variant="regular"
                // fontWeight="bold"
                color="secondary">
                Fulfilment status:
              </ArgonTypography>
              <ArgonBox ml={1}>
                <ArgonSelect
                  id="orderStatusSelection"
                  name="orderStatusSelection"
                  size="small"
                  options={[
                    { value: undefined, label: 'all' },
                    { value: 'open', label: 'open' },
                    { value: 'completed', label: 'completed' }
                  ]}
                  value={orderStatusFilterOption}
                  onChange={handleOrderStatusFilterChange}
                />
              </ArgonBox>
            </ArgonBox>
          </ArgonBox> */}
            <div className="ka-table_transparent_dark_font_small_paddings">
              <Table
                // key={showFilters}
                table={table}
                columns={[
                  // { key: '_id', title: 'ID', dataType: DataType.String },
                  {
                    key: 'creationDate',
                    title: 'Date',
                    dataType: DataType.Date
                  },
                  {
                    key: 'customerName',
                    title: 'Customer',
                    dataType: DataType.String
                  },
                  // {
                  //   key: 'customerEmail',
                  //   title: 'Email',
                  //   dataType: DataType.String
                  // },
                  {
                    key: 'formattedAmountDue',
                    title: 'Amount Due',
                    dataType: DataType.String
                  },

                  {
                    key: 'paymentStatus',
                    title: 'Payment Status',
                    dataType: DataType.String
                  },
                  {
                    key: 'orderStatus',
                    title: 'Fulfilment Status',
                    dataType: DataType.String
                  }
                ]}
                data={preparedData}
                paging={{
                  enabled: true,
                  pageIndex,
                  pageSize: pageSizeOption,
                  pageSizes: pageSizeOptions,
                  pagesCount: Math.ceil(totalPagesCount / pageSizeOption) || 0,
                  position: PagingPosition.Bottom
                }}
                // editingMode={EditingMode.Cell}
                filteringMode={showFilters && FilteringMode.FilterRow}
                rowKeyField={'_id'}
                sortingMode={SortingMode.Single}
                // search={({ searchText: searchTextValue, rowData, column }) => {
                //   if (column.key === "passed") {
                //     return (
                //       (searchTextValue === "false" && !rowData.passed) ||
                //       (searchTextValue === "true" && rowData.passed)
                //     );
                //   }
                // }}
                searchText={searchText}
                loading={{
                  enabled: loading,
                  text: 'Loading data'
                }}
                noData={{
                  text: 'No Data Found'
                }}
                format={({ column, value, rowData }) => {
                  if (column.key === 'creationDate') {
                    return new Intl.DateTimeFormat(i18n.language, {
                      dateStyle: 'medium'
                    }).format(new Date(value));
                  }

                  if (column.key === 'orderStatus') {
                    if (value === 'open')
                      return (
                        <>
                          <ArgonBadge
                            badgeContent="open"
                            color="warning"
                            variant="gradient"
                            size="xs"
                            container
                          />
                        </>
                      );
                    if (value === 'completed')
                      return (
                        <ArgonBadge
                          badgeContent="completed"
                          color="success"
                          variant="gradient"
                          size="xs"
                          container
                        />
                      );

                    if (value === 'cancelled')
                      return (
                        <ArgonBadge
                          badgeContent="cancelled"
                          color="secondary"
                          variant="gradient"
                          size="xs"
                          container
                        />
                      );

                    // if (value === 'free')
                    //   return (
                    //     <ArgonBadge
                    //       badgeContent="free"
                    //       color="success"
                    //       variant="gradient"
                    //       size="xs"
                    //       container
                    //     />
                    //   );

                    return value;
                  }

                  if (column.key === 'paymentStatus') {
                    if (value === 'open')
                      return (
                        <>
                          <ArgonBadge
                            badgeContent="open"
                            color="error"
                            variant="gradient"
                            size="xs"
                            container
                          />
                        </>
                      );
                    if (value === 'paid')
                      return (
                        <ArgonBadge
                          badgeContent="paid"
                          color="success"
                          variant="gradient"
                          size="xs"
                          container
                        />
                      );
                    if (value === 'free')
                      return (
                        <ArgonBadge
                          badgeContent="free"
                          color="success"
                          variant="gradient"
                          size="xs"
                          container
                        />
                      );

                    if (value === 'cancelled')
                      return (
                        <ArgonBadge
                          badgeContent="cancelled"
                          color="secondary"
                          variant="gradient"
                          size="xs"
                          container
                        />
                      );

                    return value;
                  }
                }}
                childComponents={{
                  dataRow: {
                    elementAttributes: () => ({
                      onClick: (event, extendedEvent) => {
                        console.log(
                          'dataRow: onClick: extendedEvent',
                          extendedEvent
                        );

                        table.selectSingleRow(
                          extendedEvent.childProps.rowKeyValue
                        );

                        navigate(
                          `/organizer/orders/${extendedEvent.childProps.rowKeyValue}`
                        );
                        //! here nvigate to order details page...
                        // setSelectedOrderId(
                        //   extendedEvent.childProps.rowKeyValue
                        // );
                        // setOrderDetailsOpen(true);
                      }
                    })
                  }
                }}
              />
            </div>
          </ArgonBox>
        </ArgonBox>
      </LoaderWrap>
    </Card>
  );
};

export default OpenOrdersList;
