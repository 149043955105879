import { EVENTINSTANCE_FRAGMENT } from 'GraphQL/EventInstances/fragments';

const { gql } = require('@apollo/client');

export const EVENTINSTANCES_GET = gql`
  ${EVENTINSTANCE_FRAGMENT}

  query getEventInstances($query: EventInstanceQueryInput!) {
    eventInstances(query: $query) {
      ...EventInstanceFragment
    }
  }
`;

export const EVENTINSTANCE_GET_BY_ID = gql`
  ${EVENTINSTANCE_FRAGMENT}
  query getEventInstanceById($eventInstanceId: ObjectId!) {
    eventInstance(query: { _id: $eventInstanceId }) {
      ...EventInstanceFragment
    }
  }
`;

export const EVENTINSTANCES_GET_BY_EVENTID = gql`
  ${EVENTINSTANCE_FRAGMENT}
  query getEventInstancesById($eventId: ObjectId!) {
    eventInstances(query: { event: { _id: $eventId } }) {
      ...EventInstanceFragment
    }
  }
`;
