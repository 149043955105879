import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import { EVENTINSTANCE_DIVISION_UPDATE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_UPDATE } from 'GraphQL/EventInstances/mutations';
import { useSnackbar } from 'notistack';
import LoaderWrap from 'LoaderWrap';
import { Alert, AlertTitle, Collapse, Grid } from '@mui/material';
import FormikTextInput from 'components/FormikTextInput';
import MessageTemplateSelector from '../../Communications/ScheduledMessages/ScheduledMessageDetails/MessageTemplateSelector';
import ArgonBox from 'components/ArgonBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormikNumberInput from 'components/FormikNumberInput';
import ArgonEditor from 'components/ArgonEditor';
import ArgonButton from 'components/ArgonButton';
import DialogWrapper from 'components/DialogWrapper';
import PreviewScheduledMessage from '../../Communications/ScheduledMessages/PreviewScheduledMessage';
import { nanoid } from 'nanoid';
import { MESSAGETEMPLATE_GET_BY_ID } from 'GraphQL/MessageTemplates/queries';
import ArgonSelect from 'components/ArgonSelect';
import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import selectData from 'options/selectData';

import ArgonTypography from 'components/ArgonTypography';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Glass from 'components/Glass';

import {
  invitationSubjectTemplate,
  invitationBodyTemplate
} from '../invitationMessageTemplates';
import { useTranslation } from 'react-i18next';
import { ORGANIZATION_GET_BY_ID } from 'GraphQL/Organization/queries';
import { initiateAutoInvitations } from 'services/REST_API/participants';
import AutoInvitationDivisionMapping from '../components/AutoInvitationDivisionMapping';
import EditInvitesPerDivision from '../components/EditInvitesPerDivision';
import ScrollBar from 'react-perfect-scrollbar';
import { getLeaderboardData } from 'services/restApi';
import Swal from 'sweetalert2';

function AutoInvitationSettingsWizard({
  eventInstanceId,
  open,
  onClose,
  onSaved
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n, t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);

  const [uniqueEditorId] = useState(nanoid().toString());

  const [eventName, setEventName] = useState('');

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [messageTemplateIsValid, setMessageTemplateIsValid] = useState();

  const [showMessagePreview, setShowMessagePreview] = useState(false);

  const [previousEventInstancesOptions, setPreviousEventInstancesOptions] =
    useState([]);

  // const [
  //   sourceEventInstanceLeaderboardData,
  //   setSourceEventInstanceLeaderboardData
  // ] = useState([]);

  // const [
  //   sourceEventInstanceLeaderboardDataLoading,
  //   setSourceEventInstanceLeaderboardDataLoading
  // ] = useState(false);

  const [
    preceedingEventInstanceDivisions,
    setPreceedingEventInstanceDivisions
  ] = useState([]);

  const steps = [
    'Select Source',
    'Map Divisions',
    'Set Invitation Counts',
    'Edit Message',
    'Confirm'
  ];

  const isLastStep = activeStep === steps.length - 1;

  const stepValidationFields = {
    0: ['eventInstanceFromId', 'sourceEventInstanceLeaderboardData'],
    1: [],
    // 2: ['invitesPerDivision', 'totalInvites'],
    2: ['totalInvites'],
    3: [
      'messageSubject',
      'messageBody',
      'inviteExpiryDays',
      'messageSenderName',
      'messageSenderEmail'
    ]
  };

  const isStepValid = (step) => {
    const fieldsToValidate = stepValidationFields[step];
    return fieldsToValidate.every((field) => {
      try {
        validationSchema.validateSyncAt(field, formik.values);
        return true;
      } catch (error) {
        return false;
      }
    });
  };

  const handleNext = () => {
    if (isStepValid(activeStep)) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = (data) => {
    // setData((prev) => ({ ...prev, ...data }));
    setActiveStep(activeStep - 1);
  };

  const [gqlFetchEventInstance, { loading, data }] = useLazyQuery(
    EVENTINSTANCE_GET_BY_ID,
    {
      onCompleted: async (data) => {
        console.log('AutoInvitationSettingsWizard: data: ', data);

        // if autoInvitationData is populated, then we need to set the formik values to the autoInvitationData

        console.log('AutoInvitationSettings: eventInstance fetched:', data);
        const { data: eventInstancesData } = await fetchEventInstancesByEventId(
          {
            variables: { eventId: data?.eventInstance?.event._id }
          }
        );

        console.log(
          'AutoInvitationSettingsWizard: eventInstances: ',
          eventInstancesData?.eventInstances
        );

        console.log(
          'AutoInvitationSettingsWizard: Setting preceedingEventInstanceDivisions: ',
          eventInstancesData?.eventInstances
        );
        setPreceedingEventInstanceDivisions(
          eventInstancesData?.eventInstances
            ?.find(
              (o) =>
                o?._id?.toString() ===
                data?.eventInstance?.autoInvitationData?.fromEventInstance?.toString()
            )
            ?.divisions?.map((d) => ({
              ...d,
              value: d._id,
              label: d.name
            }))
        );
        console.log(
          'Setting preceedingEventInstanceDivisions: ',
          eventInstancesData?.eventInstances.find(
            (o) =>
              o?._id?.toString() ===
              data?.eventInstance?.autoInvitationData?.fromEventInstance?.toString()
          )?.divisions
        );

        await fetchOrganization({
          variables: {
            id: data?.eventInstance?.organizationId
          }
        });

        //! when data was loaded from the db record, set flag to update record instead of creating a new one
        if (data?.eventInstance?.autoInvitation === true)
          await formik.setFieldValue('isNew', false);

        const tempEventName =
          data?.eventInstance?.event?.name +
          ' ' +
          (data?.eventInstance?.name ||
            selectData.eventInstanceTypes.find(
              (eit) => eit?.value === data?.eventInstance?.type
            )?.label);

        console.log('EventName:', tempEventName);

        await formik.setFieldValue(
          'messageSubject',
          invitationSubjectTemplate(tempEventName)
        );

        await formik.setFieldValue(
          'messageBody',
          invitationBodyTemplate({
            eventName: tempEventName,
            startDate: data?.eventInstance?.startDate,
            endDate: data?.eventInstance?.endDate,

            expiryInDays: formik.values.inviteExpiryDays,
            organizerName: formik.values.messageSenderName,
            locale: i18n?.language
          })
        );

        await formik.setFieldValue(
          'organization',
          data?.eventInstance?.organizationId
        );

        if (data?.eventInstance?.autoInvitationData) {
          console.log(
            'AutoInvitationSettingsWizard: autoInvitationData: ',
            data?.eventInstance?.autoInvitationData
          );

          // formik.setFieldValue(
          //   'eventInstanceToId',
          //   data?.eventInstance?.autoInvitationData?.eventInstanceToId
          // );
          formik.setFieldValue(
            'eventInstanceFromId',
            data?.eventInstance?.autoInvitationData?.fromEventInstance
          );
          formik.setFieldValue(
            'messageTemplateId',
            data?.eventInstance?.autoInvitationData?.messageTemplateId
          );
          formik.setFieldValue(
            'messageSubject',
            data?.eventInstance?.autoInvitationData?.messageSubject
          );
          formik.setFieldValue(
            'messageBody',
            data?.eventInstance?.autoInvitationData?.messageBody
          );

          formik.setFieldValue(
            'messageSenderName',
            data?.eventInstance?.autoInvitationData?.senderName
          );
          formik.setFieldValue(
            'messageSenderEmail',
            data?.eventInstance?.autoInvitationData?.senderEmail
          );
          // formik.setFieldValue(
          //   'organization',
          //   data?.eventInstance?.autoInvitationData?.organization
          // );

          formik.setFieldValue(
            'inviteExpiryDays',
            data?.eventInstance?.autoInvitationData?.invitationExpiryDays
          );

          let totalInvites = 0;

          data?.eventInstance?.autoInvitationData?.invitationsPerDivision?.forEach(
            (d) => {
              totalInvites += d?.inviteCount;
            }
          );
          console.log(
            'AutoInvitationSettingsWizard: totalInvites: ',
            totalInvites
          );

          formik.setFieldValue('totalInvites', totalInvites);

          const invitesPerDivision =
            data?.eventInstance?.autoInvitationData?.invitationsPerDivision.map(
              (d) => ({
                divisionFromId: d?.divisionFromId,
                divisionFromName: d?.divisionFromName,
                divisionToId: d?.divisionToId,
                divisionToName: d?.divisionToName,
                inviteCount: d?.inviteCount,
                invitationMethod: d?.invitationMethod
              })
            );

          console.log(
            'AutoInvitationSettingsWizard: invitesPerDivision: ',
            invitesPerDivision
          );

          formik.setFieldValue('invitesPerDivision', invitesPerDivision);
        }
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: error fetching eventInstance:',
          error
        );
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const [gqlUpdateEventInstance] = useMutation(EVENTINSTANCE_UPDATE);

  const [gqlUpdateDivision] = useMutation(EVENTINSTANCE_DIVISION_UPDATE, {
    onCompleted: (data) => {
      enqueueSnackbar('Division updated', {
        variant: 'success'
      });
    },
    onError: (error) => {
      console.error('AutoInvitationSettings: Error updating division:', error);
      enqueueSnackbar('Error updating division', {
        variant: 'error'
      });
    }
  });

  const [fetchOrganization, { loading: loadingOrganization }] = useLazyQuery(
    ORGANIZATION_GET_BY_ID,
    {
      variables: {
        id: data?.eventInstance?.organizationId
      },

      onCompleted: async (data) => {
        console.log('AutoInvitationSettings: Organization fetched:', data);
        await formik.setFieldValue(
          'messageSenderName',
          data?.organization?.name
        );
        await formik.setFieldValue(
          'messageSenderEmail',
          data?.organization?.email
        );
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: Error fetching organization:',
          error
        );
      }
    }
  );

  const [fetchEventInstancesByEventId, { loading: allEventInstancesLoading }] =
    useLazyQuery(EVENTINSTANCES_GET_BY_EVENTID, {
      onCompleted: async (data) => {
        console.log(
          'AutoInvitationSettings: Event Instances for Event received from Database: ',
          data
        );

        const temp = [];

        data?.eventInstances?.forEach((instance) => {
          if (instance._id.toString() !== eventInstanceId.toString()) {
            temp.push({
              ...instance,
              value: instance?._id,
              label:
                instance?.name ||
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === instance.type
                )?.label
            });
          }
        });
        console.log(
          'AutoInvitationSettings: setting options for previous eventInstances:',
          temp
        );
        setPreviousEventInstancesOptions(temp);
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: Error getting event instances for event from Database: ',
          error
        );
      }
    });

  const [fetchMessageTemplate, { loading: loadingMessageTemplate }] =
    useLazyQuery(MESSAGETEMPLATE_GET_BY_ID, {
      onCompleted: (data) => {
        console.log(
          'AutoInvitationSettings: received MessageTemplate from Database: ',
          data
        );
        // const { name, html, design } = data.messagetemplate;

        // console.log('PreviewScheduledMessage: Loading design...', design);
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: Error receiving MessageTemplate:',
          error
        );
      }
    });

  // const updateAutoInvitationSettings = async () => {
  //   setUpdateInProgress(true);
  //   await gqlUpdateEventInstance({
  //     variables: {
  //       eventInstanceId: formik.values.eventInstanceId,
  //       data: {
  //         // invitationsDefaults: {
  //         //   messageSubject: formik.values.messageSubject,
  //         //   messageBody: formik.values.messageBody,
  //         //   messageTemplateId: formik.values.messageTemplateId,
  //         //   inviteExpiryDays: formik.values.inviteExpiryDays,
  //         //   messageSenderName: formik.values.messageSenderName,
  //         //   messageSenderEmail: formik.values.messageSenderEmail
  //         // }
  //       }
  //     }
  //   })
  //     .then(async (data) => {
  //       console.log('result from update operation', data);

  //       enqueueSnackbar('Your changes have been saved.', {
  //         variant: 'success'
  //       });
  //       // Swal.fire('Success!', 'Your profile has been updated!', 'success');
  //     })
  //     .catch((err) => {
  //       console.error('error', err);
  //     })
  //     .finally(() => {
  //       setUpdateInProgress(false);
  //     });
  // };

  const handleClose = () => {
    setActiveStep(0);
    onClose && onClose();
  };

  useEffect(() => {
    gqlFetchEventInstance({
      variables: {
        eventInstanceId: eventInstanceId
      }
    });
  }, [eventInstanceId]);

  // await gqlUpdateDivision({
  //   variables: {
  //     data: {
  //       eventInstanceId: eventInstanceId,
  //       division: updatedDivision
  //     }
  //   }
  // });

  // let eventName =
  //   data?.eventInstance?.event && data?.eventInstance?.event.toString() + ' ';
  // eventName = eventName + data?.eventInstance?.name;

  const checkIfMessageTemplateIsValidForInvitations = (messageTemplateId) => {
    if (messageTemplateId)
      fetchMessageTemplate({
        variables: { id: messageTemplateId }
      }).then((result) => {
        // result?.data?.messagetemplate?.html?.includes(
        //   '{{{recipientFirstName}}}'
        // ) &&
        result?.data?.messagetemplate?.html?.includes('{{{messageContent}}}') &&
        result?.data?.messagetemplate?.html?.includes('{{{button}}}')
          ? setMessageTemplateIsValid(true)
          : setMessageTemplateIsValid(false);
      });
    else setMessageTemplateIsValid(true);
  };

  const validationSchema = Yup.object({
    // email: yup.string().email('Invalid email').required('Required'),
    // firstName: yup.string().required('Required'),
    // lastName: yup.string().required('Required')
    eventInstanceToId: Yup.string().required('Required'),
    eventInstanceFromId: Yup.string().required('Required'),
    messageTemplateId: Yup.string(),
    messageSubject: Yup.string().required('Required'),
    messageBody: Yup.string().required('Required'),
    inviteExpiryDays: Yup.number().min(0).required('Required'),
    eventInstanceId: Yup.string().required('Required'),
    organization: Yup.string().required('Required'),
    messageSenderEmail: Yup.string().email().required('Required'),
    messageSenderName: Yup.string().required('Required'),
    totalInvites: Yup.number().min(0).required('Required'),
    sourceEventInstanceLeaderboardData: Yup.array().min(
      1,
      'No leaderboard data for Event Instance'
    ),

    invitesPerDivision: Yup.array()
      .of(
        Yup.object().shape({
          divisionFromId: Yup.string(),
          divisionFromName: Yup.string(),
          divisionToId: Yup.string(),
          divisionToName: Yup.string(),
          inviteCount: Yup.number().min(0).required('Required'),
          invitationMethod: Yup.string().required('Required')
        })
      )
      .required('Required')
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      isNew: true, // when true, the record should created, when false, an existing record should be updated
      eventInstanceToId: eventInstanceId,
      eventInstanceFromId: '',
      messageTemplateId: process.env.REACT_APP_STANDARD_MESSAGE_TEMPLATE_ID,
      messageSubject: invitationSubjectTemplate(eventName),
      messageBody: '',
      inviteExpiryDays: 7,
      eventInstanceId: eventInstanceId,
      organization: data?.eventInstance?.organization?._id,
      messageSenderEmail: '',
      messageSenderName: '',
      invitesPerDivision: []
    },
    onSubmit: async (values) => {
      // alert('Form submitted: ' + JSON.stringify(values, null, 2));

      // if values.invitesPerDivision... subdocument has no divisionFromId or inviteCount = 0, then remove it from array
      const filteredInvitesPerDivision = values.invitesPerDivision.filter(
        (d) => d.divisionFromId && d.inviteCount > 0
      );

      console.log('AutoInvitationSettingsWizard: form submitted: ', values);

      await initiateAutoInvitations({
        inviteToEventInstanceId: values.eventInstanceToId,
        inviteFromEventInstanceId: values.eventInstanceFromId,
        inviteExpiryInDays: values.inviteExpiryDays,
        inviteSubject: values.messageSubject,
        inviteMessageBody: values.messageBody,
        messageTemplateId: values.messageTemplateId,
        invitesPerDivision: filteredInvitesPerDivision,
        messageSenderName: values.messageSenderName,
        messageSenderEmail: values.messageSenderEmail
      })
        .then((result) => {
          console.log(
            'AutoInvitationSettings: initiateAutoInvitations: ',
            result
          );
          enqueueSnackbar('Auto-Invitations initiated', {
            variant: 'success'
          });

          // if we have created a new record, set isNew to false to update with next save
          formik.setFieldValue('isNew', false);

          Swal.fire({
            title: 'Auto-Invitations initiated',
            text: 'Auto-Invitations initiated successfully. Participants will start receiving invitations now.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            // navigate(`/organizer/events/${eventId}/instances/${eventInstanceId}`);
            // onClose();
            onSaved && onSaved();
          });
        })
        .catch((error) => {
          console.error(
            'AutoInvitationSettings: initiateAutoInvitations: ',
            error
          );
          enqueueSnackbar('Error initiating Auto-Invitations', {
            variant: 'error'
          });
        });
    }
  });

  console.log('AutoInvitationSettings: formik.values: ', formik.values);

  useEffect(() => {
    checkIfMessageTemplateIsValidForInvitations(
      formik.values.messageTemplateId
    );
  }, [formik.values.messageTemplateId]);

  console.log(
    'AutoInvitationSettings: divisions:',
    data?.eventInstance?.divisions
  );

  const handleDivisionMappingChanged = async (newDivisionMapping) => {
    console.log('handleDivisionMappingChanged: ', newDivisionMapping);

    // now transform input to required format:
    const transformedInvitationsPerDivision = newDivisionMapping.map((d) => ({
      divisionFromId: d?.divisionFromId,
      divisionFromName: d?.divisionFromName,
      divisionToId: d?.divisionToId,
      divisionToName: d?.divisionToName,
      inviteCount: Number(d?.inviteCount),
      invitationMethod: d?.invitationMethod
    }));

    console.log(
      'handleDivisionMappingChanged: transformedInvitationsPerDivision: ',
      transformedInvitationsPerDivision
    );

    await formik.setFieldValue(
      'invitesPerDivision',
      transformedInvitationsPerDivision
    );
  };

  const handleDivisionInviteCountChanged = async (newDivisionInviteCount) => {
    console.log('handleDivisionInviteCountChanged: ', newDivisionInviteCount);

    // now transform input to required format:
    const transformedInvitationsPerDivision = newDivisionInviteCount.map(
      (d) => ({
        divisionFromId: d?.divisionFromId,
        divisionFromName: d?.divisionFromName,
        divisionToId: d?.divisionToId,
        divisionToName: d?.divisionToName,
        inviteCount: Number(d?.inviteCount) || 0,
        invitationMethod: d?.invitationMethod
      })
    );

    console.log(
      'handleDivisionMappingChanged: transformedInvitationsPerDivision: ',
      transformedInvitationsPerDivision
    );

    await formik.setFieldValue(
      'invitesPerDivision',
      transformedInvitationsPerDivision
    );

    let totalInvites = 0;
    transformedInvitationsPerDivision.forEach((d) => {
      totalInvites += Number(d?.inviteCount) || 0;
    });
    console.log(
      'handleDivisionInviteCountChanged: totalInvites: ',
      totalInvites
    );

    await formik.setFieldValue('totalInvites', totalInvites);
  };

  console.log('AutoInvitationSettings: formik.errors: ', formik.errors);

  const inputSize = 'small';

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <SelectSourceEventInstance />;
      case 1:
        return renderMapDivisions();
      case 2:
        return renderDefineInvitationCounts();
      case 3:
        return renderEditMessageSettings();
      case 4:
        return renderConfirmation();
      default:
        return null;
    }
  }

  const SelectSourceEventInstance = () => {
    return (
      <ArgonBox mb={3}>
        {/* <FolderWrapper title="Division Mapping" isOpen> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ArgonTypography variant="h6" fontWeight="bold" color="text">
              Source Event Instance
            </ArgonTypography>

            <ArgonBox display="flex" flexDirection="column" gap={0}>
              <ArgonSelect
                id="eventInstanceFrom"
                name="eventInstanceFrom"
                overflow
                isLoading={allEventInstancesLoading}
                options={previousEventInstancesOptions}
                label="Select a preceeding Event Instance to invite participants from"
                onChange={(option) => {
                  formik.setFieldValue('eventInstanceFromId', option.value);
                  setPreceedingEventInstanceDivisions(
                    previousEventInstancesOptions
                      .find((o) => o?.value.toString() === option.value)
                      ?.divisions?.map((d) => ({
                        ...d,
                        value: d._id,
                        label: d.name
                      }))
                  );
                  console.log(
                    'Setting preceedingEventInstanceDivisions: ',
                    previousEventInstancesOptions.find(
                      (o) => o?.value.toString() === option.value
                    )?.divisions
                  );
                  // setSourceEventInstanceLeaderboardDataLoading(true);
                  // getLeaderboardData({ eventInstanceId: option.value })
                  //   .then((result) => {
                  //     console.log(
                  //       'AutoInvitationSettingsWizard: getLeaderboardData: ',
                  //       result
                  //     );
                  //     setSourceEventInstanceLeaderboardData(result);
                  //   })
                  //   .finally(() => {
                  //     setSourceEventInstanceLeaderboardDataLoading(false);
                  //   });
                }}
                value={previousEventInstancesOptions.find(
                  (o) =>
                    o?.value.toString() === formik.values.eventInstanceFromId
                )}
              />
              <ArgonBox mt={4}>
                <Alert severity="info">
                  <AlertTitle>
                    Only initiate Auto Invitation when you have a final
                    leaderboard for the preceeding event instance.
                  </AlertTitle>
                  Participants are usually selected based on leaderboard ranks
                  from a preceeding Event Instance. If there is no (final)
                  leaderboard, participants may be selected randomly.
                </Alert>
              </ArgonBox>
              {/* <LoaderWrap loading={sourceEventInstanceLeaderboardDataLoading}>
                {sourceEventInstanceLeaderboardData.length === 0 && (
                  <Alert severity="warning">
                    <AlertTitle>
                      No leaderboard available for this event instance.
                    </AlertTitle>
                    This event instance does not have a leaderboard. Invitatees
                    are usually seleced based on leaderboard ranks from a
                    preceeding Event Instance.
                  </Alert>
                )}
              </LoaderWrap> */}
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    );
  };

  const renderMapDivisions = () => {
    return (
      <>
        <ArgonBox mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Collapse in={preceedingEventInstanceDivisions?.length > 0}>
                <ArgonBox mt={0}>
                  <ArgonTypography variant="h6" fontWeight="bold" color="text">
                    Map Divisions
                  </ArgonTypography>
                  <ArgonBox mt={1}>
                    <ArgonTypography variant="regular" color="text">
                      Participants will be invited from the selected source
                      divisions into the mapped divisions of this event
                      instance.
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox mt={2}>
                    <AutoInvitationDivisionMapping
                      key={formik.values?.eventInstanceFromId}
                      sourceEventInstanceId={formik.values?.eventInstanceFromId}
                      divisions={data?.eventInstance?.divisions}
                      predecessorDivisions={preceedingEventInstanceDivisions}
                      onChange={handleDivisionMappingChanged}
                      invitesPerDivision={formik.values?.invitesPerDivision}
                    />
                  </ArgonBox>
                </ArgonBox>
              </Collapse>
            </Grid>
          </Grid>
        </ArgonBox>
      </>
    );
  };

  const renderDefineInvitationCounts = () => {
    return (
      <ArgonBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Collapse in={preceedingEventInstanceDivisions.length > 0}>
              <ArgonBox mt={0}>
                <ArgonTypography variant="h6" fontWeight="bold" color="text">
                  Set invites per division
                </ArgonTypography>
                <EditInvitesPerDivision
                  key={formik.values?.eventInstanceFromId}
                  sourceEventInstanceId={formik.values?.eventInstanceFromId}
                  divisions={data?.eventInstance?.divisions}
                  predecessorDivisions={preceedingEventInstanceDivisions}
                  onChange={handleDivisionInviteCountChanged}
                  invitesPerDivision={formik.values?.invitesPerDivision}
                />
              </ArgonBox>
            </Collapse>
          </Grid>
        </Grid>
      </ArgonBox>
    );
  };

  const renderEditMessageSettings = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ArgonBox mt={0} />
            <ArgonTypography variant="h6" fontWeight="bold" color="text">
              Message settings
            </ArgonTypography>
          </Grid>

          <Grid item xs={9}>
            <MessageTemplateSelector
              id="messageTemplateId"
              name="messageTemplateId"
              key={formik.values?.eventInstanceId}
              formik={formik}
              label="Select Message Template (optional)"
              organizationId={formik.values?.organization}
            />
            <Collapse in={!messageTemplateIsValid}>
              <ArgonBox mt={2}>
                <Alert severity="error">
                  <AlertTitle>Message template not valid</AlertTitle>
                  This message template does not contain all of the required
                  variables (recipientFirstName, recipientLastName,
                  messageContent, button). Check the template in the editor and
                  make sure it contains all of the required variables. Or choose
                  another template.
                </Alert>
              </ArgonBox>
            </Collapse>
          </Grid>
          <Grid item xs={3}>
            <FormikNumberInput
              id="inviteExpiryDays"
              name="inviteExpiryDays"
              label="Invite will expire in"
              formik={formik}
              suffix=" days"
            />
          </Grid>

          <Grid item xs={6}>
            <FormikTextInput
              id="messageSenderName"
              name="messageSenderName"
              label="Name of the sender (email from)"
              formik={formik}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextInput
              id="messageSenderEmail"
              name="messageSenderEmail"
              label="Email of the sender (reply-to)"
              formik={formik}
            />
          </Grid>

          <Grid item xs={12}>
            <LoaderWrap loading={loading}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormikTextInput
                    id="messageSubject"
                    name="messageSubject"
                    label="Email Subject & Body"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonEditor
                    uniqueKey={uniqueEditorId}
                    id={uniqueEditorId}
                    name={uniqueEditorId}
                    value={formik.values.messageBody}
                    onChange={(value) =>
                      formik.setFieldValue('messageBody', value)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Collapse in={formik.values.messageTemplateId !== null}>
                    <ArgonButton
                      variant="outlined"
                      color="secondary"
                      size={inputSize}
                      onClick={() => setShowMessagePreview(true)}
                      disabled={formik.isSubmitting || !messageTemplateIsValid}
                      loadingColor="dark">
                      Preview Invitation Email
                    </ArgonButton>
                  </Collapse>
                </Grid>
              </Grid>
            </LoaderWrap>
          </Grid>
        </Grid>

        <DialogWrapper
          open={showMessagePreview}
          title={'Preview: ' + formik.values.messageSubject}
          onClose={() => setShowMessagePreview(false)}
          fullWidth
          maxWidth="lg">
          <PreviewScheduledMessage
            // scheduledMessage={data?.scheduledMessage}
            scheduledMessage={{
              messageHeadline: formik.values.messageSubject,
              messageBody: formik.values.messageBody,
              email: formik.values.email,
              recipientFirstName: formik.values.firstName,
              recipientLastName: formik.values.lastName,
              messageTemplateId: formik.values.messageTemplateId
            }}
            messageTemplateId={formik.values?.messageTemplateId}
            eventInstanceId={eventInstanceId}
            simulateAcceptDeclineButtons={true}
          />
        </DialogWrapper>
      </>
    );
  };

  //! ############################################################################################
  // TODO: beim Anmelden mit Einladung auf gesetzte Division begrenzen, wenn divisionTo gesetzt ist
  //! ############################################################################################

  const renderConfirmation = () => {
    return (
      <>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <ArgonBox mt={0} />
            <ArgonTypography variant="h6" fontWeight="bold" color="text">
              Message settings
            </ArgonTypography>
          </Grid> */}

          <Grid item xs={9}></Grid>
          <Grid item xs={12}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={3}
              gap={3}>
              {formik.values.isNew && (
                <ArgonTypography variant="h6" fontWeight="bold" color="text">
                  You have configured automatic invitations. Press activate
                  button below to save configuration and start sending
                  invitations.
                </ArgonTypography>
              )}

              {!formik.values.isNew && (
                <ArgonTypography variant="h6" fontWeight="bold" color="text">
                  You have configured automatic invitations. Changes are not
                  possible after invitation procedure started.
                </ArgonTypography>
              )}
              {/* </ArgonBox> */}
              {/* <ArgonButton
                id="activate_automatic_invitations"
                name="activate_automatic_invitations"
                variant="gradient"
                color="success"
                loading={formik.isSubmitting}
                loadingColor="white"
                onClick={formik.handleSubmit}>
                Activate Automatic Invitations
              </ArgonButton> */}
            </ArgonBox>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <DialogWrapper open={open} onClose={onClose} fullWidth maxWidth="lg">
      <LoaderWrap loading={loading || loadingOrganization}>
        <ArgonBox>
          <ArgonBox pt={0} pb={0} position="relative">
            {/* <Glass> */}
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={12}>
                <ArgonBox mt={0} mb={8} textAlign="center">
                  <ArgonBox mb={1} gap={2}>
                    <ArgonTypography
                      variant="h3"
                      color="dark"
                      fontWeight="bold">
                      Setup Automatic Invitations
                    </ArgonTypography>
                    <ArgonTypography variant="h6" color="secondary">
                      When set up, STRONG ONE takes care of inviting
                      participants from another event instance to this one.
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>

                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {/* <Glass> */}

                <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                  <ScrollBar>
                    <ArgonBox p={3}>
                      {getStepContent(
                        activeStep
                        // handleBack,
                        // handleNext,
                        // handleSubmit,
                        // data,
                        // workInProgress
                      )}
                    </ArgonBox>
                  </ScrollBar>
                </div>
                <ArgonBox
                  px={3}
                  pb={3}
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between">
                  {activeStep === 0 ? (
                    <ArgonBox />
                  ) : (
                    <ArgonButton
                      variant="gradient"
                      color="light"
                      onClick={() => handleBack()}
                      disabled={formik.isSubmitting}>
                      Back
                    </ArgonButton>
                  )}
                  {!isLastStep && (
                    <ArgonButton
                      variant="contained"
                      color="info"
                      onClick={() =>
                        !isLastStep ? handleNext() : formik.handleSubmit()
                      }
                      disabled={!isStepValid(activeStep)}>
                      {isLastStep ? 'Send' : 'Next'}
                    </ArgonButton>
                  )}
                  {isLastStep && (
                    <ArgonBox display="flex" gap={1}>
                      <ArgonButton
                        variant="gradient"
                        color="success"
                        onClick={formik.handleSubmit}
                        disabled={
                          formik.isSubmitting || formik.values.isNew === false
                        }
                        loading={formik.isSubmitting}
                        loadingColor="white">
                        Activate Automatic Invitations
                      </ArgonButton>
                      <ArgonButton
                        variant="gradient"
                        color="light"
                        onClick={handleClose}
                        disabled={formik.isSubmitting}>
                        Cancel
                      </ArgonButton>
                    </ArgonBox>
                  )}
                </ArgonBox>

                {/* </Glass> */}
              </Grid>
            </Grid>
            {/* </Glass> */}
          </ArgonBox>
        </ArgonBox>
      </LoaderWrap>
    </DialogWrapper>
  );
}

AutoInvitationSettingsWizard.propTypes = {};

export default AutoInvitationSettingsWizard;
