const { gql } = require('@apollo/client');

export const MESSAGETEMPLATE_FRAGMENT = gql`
  fragment MessageTemplateFragment on Messagetemplate {
    _id
    name
    creationDate
    organization
    html
    design
  }
`;
