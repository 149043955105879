import ArgonBox from 'components/ArgonBox';
import React from 'react';
import { PuffLoader } from 'react-spinners';

export default function LoaderWrap({
  minHeight = '100%',
  show,
  loading,
  children
}) {
  if (Boolean(show) === true || Boolean(loading) === true)
    return (
      <ArgonBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={minHeight}>
        <PuffLoader color="#36d7b7" />
      </ArgonBox>
    );
  else return children;
}
