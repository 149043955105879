import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import React from 'react';

import 'assets/css/glass.css';

export default function Glass({ children, ...rest }) {
  return (
    <>
      <div
        className="glass"

        // style={{
        //   backgroundColor: 'rgba(255, 255, 255, .15)',
        //   // backdropFilter: 'blur(5px)',
        //   backdropFilter: 'saturate(120%) blur(10px)',
        //   borderRadius: '7px',
        //   //   boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
        //   border: '1px solid rgba(255, 255, 255, 0.03)',
        //   transform: 'translate3d(0, 0, 0)'
        // }}
      >
        <ArgonBox p={{ xs: 1, md: 2, lg: 6 }} {...rest}>
          {children}
        </ArgonBox>
      </div>
    </>
  );
}
