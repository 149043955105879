import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';

function DialogWrapper({
  open,
  onClose,
  title,
  children,
  fullWidth,
  maxWidth
}) {
  const handleClose = (event) => {
    event?.preventDefault();

    onClose() && onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}>
        <ArgonBox p={3}>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <ArgonTypography variant="h5" fontWeight="bold">
              {title}
            </ArgonTypography>
            <IconButton onClick={handleClose}>
              <ClearIcon color="dark" />
            </IconButton>
          </ArgonBox>
          <ArgonBox mt={2}>{children}</ArgonBox>
        </ArgonBox>
      </Dialog>
    </>
  );
}

DialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
};

export default DialogWrapper;
