import { MESSAGETEMPLATE_FRAGMENT } from 'GraphQL/MessageTemplates/fragments';

const { gql } = require('@apollo/client');

export const MESSAGETEMPLATES_QUERY = gql`
  ${MESSAGETEMPLATE_FRAGMENT}

  query queryMessageTemplates($query: MessagetemplateQueryInput!) {
    messagetemplates(query: $query) {
      ...MessageTemplateFragment
    }
  }
`;

export const MESSAGETEMPLATE_GET_BY_ID = gql`
  ${MESSAGETEMPLATE_FRAGMENT}
  query getMessageTemplateById($id: ObjectId!) {
    messagetemplate(query: { _id: $id }) {
      ...MessageTemplateFragment
    }
  }
`;
