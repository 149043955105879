/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

import 'assets/css/custom-local-fonts.css';

// import i18n (needs to be bundled ;))
import './i18n';

// Soft UI Context Provider
import { ArgonControllerProvider } from 'context';

// react-perfect-scrollbar component
import PerfectScrollbar from 'react-perfect-scrollbar';

// react-perfect-scrollbar styles
import 'react-perfect-scrollbar/dist/css/styles.css';

// Sentry Error Alerts
import * as Sentry from '@sentry/react';

// Posthog Analytics
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import PostHogPageviewTracker from 'PostHogPageViewTracker';

posthog.init('phc_6PHEwgUrxFVpDSQVbNrF2aSMjK1VNYMx0OqJvxZSFrg', {
  api_host: 'https://eu.posthog.com',
  capture_pageview: false
});

if (process.env.NODE_ENV === 'production') {
  // disable React DevTools in Chrome
  disableReactDevTools();

  // deactivate  console logging on PROD
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          // /^https:\/\/api.throwdown-universe\.com/,
          /^https:\/\/www.strongone\.dev/,
          /^https:\/\/www.strong\.one/
        ]
      }),

      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <PostHogPageviewTracker /> {/* captures page views */}
    <ArgonControllerProvider>
      <PostHogProvider instance={posthog}>
        {/* <PerfectScrollbar> */}
        <App />
        {/* </PerfectScrollbar> */}
      </PostHogProvider>
    </ArgonControllerProvider>
  </BrowserRouter>
);
