import { gql } from '@apollo/client';

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    _id
    address1
    address2
    city
    country
    createdBy
    creationDate
    email
    logo_url
    name
    state
    taxId
    website
    zip
    currency
    team {
      _id
      userId
      email
      name
      roles
    }
    stripeTaxRates {
      stripeTaxRateId
      name
      countryCode
      taxRateInteger
      description
      inclusive
      active
    }
    merchandiseShippingFee
    ticketShippingFee
    stripeAccountId
  }
`;
