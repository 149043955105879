import axios from 'axios';
import { getSession } from '../../cognito/cognitoAccount';

export const createStripeProduct = ({
  organizationId,
  productName,
  description,
  defaultPrice,
  defaultCurrency,
  stripeProductTaxCode,
  imageUrl,
  productType,
  productDbId,
  metadata
}) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('organizationId is required'));
    if (!productName) reject(new Error('productName is required'));
    // if (!description) reject(new Error('description is required'));
    // if (!defaultPrice) reject(new Error('defaultPrice is required'));
    if (!defaultCurrency) reject(new Error('defaultCurrency is required'));
    // if (!stripeProductTaxCode)
    //   reject(new Error('stripeProductTaxCode is required'));

    const session = await getSession();
    console.log('Posting data to REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/product/create`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId: organizationId,
        productName: productName,
        description: description,
        defaultPrice: defaultPrice || 0,
        defaultCurrency: defaultCurrency,
        stripeProductTaxCode: stripeProductTaxCode,
        imageUrl: imageUrl,
        metadata: {
          type: productType,
          id: productDbId,
          ...metadata
        }
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const updateStripeProduct = ({
  organizationId,
  productId,
  productName,
  description,
  stripeProductTaxCode,
  imageUrl,
  shippable,
  productType,
  productDbId,
  metadata
}) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('organizationId is required'));

    const session = await getSession();
    console.log('Posting data to REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/product/update`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        productId: productId,
        organizationId: organizationId,
        productName: productName,
        description: description,
        stripeProductTaxCode: stripeProductTaxCode,
        imageUrl: imageUrl,
        shippable: shippable,
        metadata: {
          type: productType,
          id: productDbId,
          ...metadata
        }
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const deactivateStripeProduct = ({ organizationId, productId }) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('organizationId is required'));

    const session = await getSession();
    console.log('Posting data to REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/product/update`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        productId: productId,
        organizationId: organizationId,
        active: false
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const stripeCreateNewPrice = ({
  organizationId,
  productId,
  priceInCents,
  currency
}) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('organizationId is required'));

    const session = await getSession();
    console.log('Fetching Data from REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/price/create`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId: organizationId,
        productId: productId,
        price: priceInCents,
        currency: currency
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const setNewPriceForStripeProduct = ({
  organizationId,
  productId,
  newPriceInCents,
  newCurrency,
  archiveOldPrice
}) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('organizationId is required'));

    const session = await getSession();
    console.log('Fetching Data from REST API...');

    var config = {
      method: 'post',

      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/product/set-new-price`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/price/replace-price-for-product`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId: organizationId,
        productId: productId,
        newPrice: newPriceInCents,
        newCurrency: newCurrency,
        archiveOldPrice: archiveOldPrice
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const createStripeTaxRate = ({
  organizationId,
  name,
  countryCode,
  jurisdiction,
  taxRateInteger,
  description,
  inclusive,
  state
}) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('organizationId is required'));
    if (!name) reject(new Error('productName is required'));
    if (!countryCode) reject(new Error('description is required'));
    // if (!jurisdiction) reject(new Error('jurisdiction is required'));
    if (!taxRateInteger) reject(new Error('defaultPrice is required'));

    const session = await getSession();
    console.log('Posting data to REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/tax-rate/create`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId: organizationId,
        name: name,
        countryCode: countryCode,
        jurisdiction: jurisdiction,
        taxRateInteger: taxRateInteger,
        description: description,
        inclusive: inclusive,
        state: state
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getAllStripeTaxRates = ({ organizationId }) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('organizationId is required'));

    const session = await getSession();
    console.log('Posting data to REST API...');

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/tax-rate/get-all`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId: organizationId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
