import { EVENTINSTANCE_FRAGMENT } from './fragments';
import { gql } from '@apollo/client';

export const EVENTINSTANCE_INSERT = gql`
  ${EVENTINSTANCE_FRAGMENT}
  mutation InsertOneEventInstance($data: EventInstanceInsertInput!) {
    insertOneEventInstance(data: $data) {
      ...EventInstanceFragment
    }
  }
`;

export const EVENTINSTANCE_UPDATE = gql`
  ${EVENTINSTANCE_FRAGMENT}
  mutation UpdateOneEventInstance(
    $eventInstanceId: ObjectId!
    $data: EventInstanceUpdateInput!
  ) {
    updateOneEventInstance(query: { _id: $eventInstanceId }, set: $data) {
      ...EventInstanceFragment
    }
  }
`;

export const EVENTINSTANCE_DELETE = gql`
  mutation DeleteEventInstance($eventInstanceId: ObjectId!) {
    deleteOneEventInstance(query: { _id: $eventInstanceId }) {
      _id
    }
  }
`;

export const EVENTINSTANCE_DIVISION_CREATE = gql`
  mutation eventInstanceDivisionCreate(
    $data: EventInstanceDivisionCreateInput
  ) {
    EventInstanceDivisionCreate(input: $data) {
      _id
      name
      description
      maxParticipants
      forIndividuals
      forTeams
      forUnderage
      teamSize
      minAge
      maxAge
      registrationFee
      advancingAthletesNumber
      advancingAthletesPercentage
      assignedJudges
      sequence
      stripePriceId
    }
  }
`;

export const EVENTINSTANCE_DIVISION_UPDATE = gql`
  mutation updateInstanceEventDivision(
    $data: EventInstanceDivisionUpdateInput_!
  ) {
    EventInstanceDivisionUpdate(input: $data) {
      eventInstanceId
      division {
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
        advancingAthletesNumber
        advancingAthletesPercentage
        assignedJudges
        sequence
        stripePriceId
      }
    }
  }
`;

export const EVENTINSTANCE_DIVISION_DELETE = gql`
  mutation deleteInstanceDivision($data: EventInstanceDivisionDeleteInput!) {
    EventInstanceDivisionDelete(input: $data) {
      status
    }
  }
`;
