import Handlebars from 'handlebars';

const buttonTemplate = require('./strongone_transactional_1_button.js');

export const createButton = ({
  template = buttonTemplate,
  buttonText,
  buttonColor = '#44576b',
  buttonLink
}) => {
  const hb = Handlebars.compile(template);

  const data = {
    buttonText: buttonText,
    buttonLink: buttonLink,
    buttonColor: buttonColor
  };

  const button = hb(data);

  // console.log('Button template: ', button);

  return button;
};
