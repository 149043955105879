/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import 'assets/css/argon-editor-quill-render.css';

import React from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from 'context';
// import { ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Switch
} from '@mui/material';
import { styled } from '@mui/styles';
import FormField from 'layouts/pages/account/components/FormField';
import CreateDivisionDialog from './CreateDivisionDialog';

import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';
import { EVENTINSTANCE_DIVISION_DELETE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import EventInstances from '../Setup';

import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RenderSafeHtml from 'services/RenderSafeHtml';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

function DivisionSummaryCard({
  division,
  noGutter,
  edit,
  currency,
  eventId,
  eventInstance
}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { t, i18n } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = React.useState(false);
  const [duplicateDivision, setDuplicateDivision] = React.useState({});

  const [gqlDeleteEventInstanceDivision] = useMutation(
    EVENTINSTANCE_DIVISION_DELETE,
    {
      refetchQueries: [
        {
          query: EVENTINSTANCE_GET_BY_ID,
          variables: { eventInstanceId: eventInstance?._id }
        }
      ],
      awaitRefetchQueries: true
    }
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDuplicateDivisionButton = (event) => {
    event.preventDefault();
    const dupe = { ...division, _id: undefined };
    setDuplicateDivision(dupe);
    setOpenDuplicateDialog(true);
  };

  const handleDeleteDivisionButton = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteDivision();
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    });
  };

  const deleteDivision = async () => {
    await gqlDeleteEventInstanceDivision({
      variables: {
        data: {
          eventInstanceId: eventInstance?._id,
          divisionId: division._id
        }
      }
    });
  };

  return (
    <>
      <ArgonBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        borderRadius="lg"
        pl={3}
        pr={3}
        mb={noGutter ? 0 : 1}
        mt={2}
        sx={({ palette: { grey, background } }) => ({
          backgroundColor: darkMode ? background.default : grey[100]
        })}>
        <ArgonBox
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="60px">
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            mb={1}
            mt={1}
            // onClick={handleExpandClick}
            onClick={() => setOpenEditDialog(true)}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize">
              {division?.name}
            </ArgonTypography>

            <ArgonBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}>
              {/* <ArgonBox mr={1}>
              <ArgonButton variant="text" color="error">
                <Icon>delete</Icon>&nbsp;Delete
              </ArgonButton>
            </ArgonBox> */}
              {/* <ArgonButton variant="text" color="dark">
              <Icon>edit</Icon>
            </ArgonButton> */}

              {/* <ArgonButton variant="text" color="dark">

                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more">
                  <ExpandMoreIcon />
                </ExpandMore>
              </ArgonButton> */}
            </ArgonBox>
          </ArgonBox>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={0} mt={{ xs: 1 }}>
              <Grid item xs={12}>
                <ArgonBox mb={1} lineHeight={0} width="100%">
                  <ArgonTypography variant="caption" fontWeight="medium">
                    Description:&nbsp;&nbsp;&nbsp;
                  </ArgonTypography>
                </ArgonBox>

                <ArgonBox mb={1} lineHeight={0} width="100%">
                  {/* <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="text">
                    <RenderSafeHtml rawHTML={division?.description} />
                  </ArgonTypography> */}
                  <ArgonTypography color="dark">
                    <div className="small">
                      <RenderSafeHtml rawHTML={division?.description} />
                    </div>
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <ArgonBox mb={1} lineHeight={0}>
                <ArgonTypography variant="caption" color="text">
                  {division.forTeams
                    ? "Max # of teams"
                    : "Max # of participants"}

                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {division?.maxParticipants}
                  </ArgonTypography>
                </ArgonTypography>
              </ArgonBox>
            </Grid> */}

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      For individuals:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography variant="caption" color="text">
                        {division.forIndividuals ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems="center">
                      <Switch
                        readOnly
                        name="forIndividuals"
                        checked={division.forIndividuals}
                        // onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant="caption"
                      // color="text"
                      fontWeight="medium">
                      For underage:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        // fontWeight="medium"
                      >
                        {division?.forUnderage ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems="center">
                      <Switch
                        readOnly
                        name="forUnderage"
                        checked={division?.forUnderage}
                        // onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      For teams:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        // fontWeight="medium"
                      >
                        {division?.forTeams ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems="center">
                      <Switch
                        readOnly
                        name="forUnderage"
                        checked={division?.forTeams}
                        // onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                {Boolean(division?.forTeams) && (
                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    width={{ xs: '100%' }}
                    justifyContent="space-between">
                    {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                    <ArgonBox
                    // lineHeight={1.4}
                    >
                      <ArgonTypography variant="caption" fontWeight="medium">
                        Teamsize:
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox
                      display="flex"
                      // justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: '100%', sm: 'auto' }}
                      mt={{ xs: 1, sm: 0 }}>
                      <ArgonBox mr={1} alignItems="center">
                        {edit ? (
                          <FormField
                            readOnly
                            id="teamSize"
                            name="teamSize"
                            value={division?.teamSize}
                            style={{ maxWidth: '50px' }}
                            // onChange={formik.handleChange}
                            // error={formik.touched.name && Boolean(formik.errors.name)}
                            // helperText={formik.touched.name && formik.errors.name}
                          />
                        ) : (
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            // fontWeight="medium"
                          >
                            {division?.teamSize || 'no limit set'}
                          </ArgonTypography>
                        )}
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      {division.forTeams
                        ? 'Max # of teams'
                        : 'Max # of participants'}
                      :
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      {edit ? (
                        <FormField
                          readOnly
                          id="maxParticipants"
                          name="maxParticipants"
                          value={division?.maxParticipants}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          // fontWeight="medium"
                        >
                          {division?.maxParticipants || 'no limit set'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      Registration fee:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      {edit ? (
                        <FormField
                          readOnly
                          id="registrationFee"
                          name="registrationFee"
                          value={division?.registrationFee}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          // fontWeight="medium"
                        >
                          {new Intl.NumberFormat(i18n.language, {
                            style: 'currency',
                            currency: currency || 'EUR'
                          }).format(Number(division.registrationFee) / 100)}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant="caption"
                      // color="text"
                      fontWeight="medium">
                      Minimum age of competitior (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      {edit ? (
                        <FormField
                          readOnly
                          id="minAge"
                          name="minAge"
                          value={division?.minAge}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          // fontWeight="medium"
                        >
                          {division?.minAge || 'not limited'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant="caption"
                      // color="text"
                      fontWeight="medium">
                      Maximum age of competitor (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      {edit ? (
                        <FormField
                          readOnly
                          id="maxAge"
                          name="maxAge"
                          value={division?.maxAge}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          // fontWeight="medium"
                        >
                          {division?.maxAge || 'not limited'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <ArgonBox display="flex" justifyContent="end">
              <ArgonBox mr={0} mb={1}>
                <ArgonButton
                  variant="text"
                  color="info"
                  onClick={handleDuplicateDivisionButton}>
                  {/* <AddIcon /> */}
                  <ContentCopyIcon />
                  &nbsp;Duplicate
                  {/* <Icon>add</Icon>&nbsp;Duplicate */}
                </ArgonButton>
                <ArgonButton
                  variant="text"
                  color="info"
                  onClick={() => setOpenEditDialog(true)}>
                  <EditIcon />
                  &nbsp;Edit
                  {/* <Icon>edit</Icon>&nbsp;Edit */}
                </ArgonButton>
                <ArgonButton
                  variant="text"
                  color="error"
                  onClick={handleDeleteDivisionButton}>
                  <DeleteIcon />
                  &nbsp;Delete
                  {/* <Icon>delete</Icon>&nbsp;Delete */}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </Collapse>
        </ArgonBox>
      </ArgonBox>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <CreateDivisionDialog
          eventId={eventId}
          eventInstance={eventInstance}
          division={division}
          currency={currency}
          onClose={() => setOpenEditDialog(false)}
        />
      </Dialog>

      <Dialog
        open={openDuplicateDialog}
        onClose={() => setOpenDuplicateDialog(false)}>
        <CreateDivisionDialog
          eventId={eventId}
          eventInstance={eventInstance}
          currency={currency}
          division={{ ...division, _id: undefined }}
          onClose={() => setOpenDuplicateDialog(false)}
        />
      </Dialog>
    </>
  );
}

// Setting default values for the props of Bill
DivisionSummaryCard.defaultProps = {
  noGutter: false
};

// Typechecking props for the Bill
DivisionSummaryCard.propTypes = {
  division: PropTypes.object.isRequired,
  noGutter: PropTypes.bool
};

export default DivisionSummaryCard;
