/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Data

// import ArgonButton from 'components/ArgonButton';

// import { Add as AddIcon } from '@mui/icons-material';
import { useArgonController } from 'context';
// import { AppBar, Icon, Tab, Tabs } from '@mui/material';
// import EventInstanceSummaryCard from '../EventInstanceSummaryCard';
import EventInstanceDetails from '../EventInstanceDetails';
// import { EVENTINSTANCE_INSERT } from 'GraphQL/EventInstances/mutations';
// import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
// import RegisteredAthletesOverview from '../Participants/RegisteredAthletesList';
// import AthletesTable from '../Participants/Participants';
// import Judging from '../Judging/scoresTable';
// import Workouts from '../Workouts';
import EventInstanceMenuWrapper from '../Layout/EventInstanceMenuWrapper';
import { useParams } from 'react-router-dom';

// Settings page components
import BaseLayout from '../../../BaseLayout';

function OrganizerEventInstanceDetails(props) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  let { eventId, eventInstanceId } = useParams();

  console.log('OrganizerEventInstanceDetails', eventId, eventInstanceId);

  return (
    <>
      <BaseLayout
        eventId={eventId}
        currentlySelectedEventInstanceId={eventInstanceId}
        navbarTitle="Event Details">
        <EventInstanceMenuWrapper>
          <EventInstanceDetails
            key={eventInstanceId}
            eventInstanceId={eventInstanceId}
          />
        </EventInstanceMenuWrapper>
      </BaseLayout>
    </>
  );
}

export default OrganizerEventInstanceDetails;
