import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

import ArgonInput from 'components/ArgonInput';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { getDecimalSeparator } from 'services/numberFormatting';
import { getGroupSeparator } from 'services/numberFormatting';
import ArgonTextFieldRoot from 'components/ArgonTextFieldSelect/ArgonTextFieldRoot';

// First, you need to create the `numberMask` with your desired configurations

const useStyles = makeStyles((theme) => ({
  container: {
    // display: 'flex',
    // flexWrap: 'wrap'
  },
  formControl: {
    // margin: theme.spacing(1)
  }
}));

// const numberMask = createNumberMask({
//   prefix: '',
//   suffix: ' $' // This will put the dollar sign at the end, with a space.
// });

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', {
  minYear: 1900,
  maxYear: 2099
});

function TextMaskCustom(props) {
  const {
    inputRef,
    onEnterPressed,
    error,
    decimals,
    helperText,
    width,
    size,
    ...other
  } = props;

  // console.log('Score Input: TextMaskCustom: Decimals: ', decimals);
  // console.log(
  //   'Score Input: TextMaskCustom: Allow Decimals: ',
  //   decimals && decimals > 0 ? true : false
  // );
  // console.log(
  //   'Score Input: TextMaskCustom: No of Decimals: ',
  //   decimals && decimals > 0 ? decimals : 0
  // );

  const { i18n } = useTranslation();

  const defaultNumberMask = createNumberMask({
    allowDecimal: decimals && decimals > 0 ? true : false,
    prefix: '',
    decimalSymbol: getDecimalSeparator(i18n.language), //',',
    thousandsSeparatorSymbol: getGroupSeparator(i18n.language), //'.',
    includeThousandsSeparator: true,
    decimalLimit: decimals && decimals > 0 ? decimals : 0
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      // render={(textMaskRef, props) => (
      //   <input
      //     {...props}
      //     ref={(node) => {
      //       if (node) {
      //         textMaskRef(node);
      //         if (inputRef) {
      //           inputRef.current = node;
      //         }
      //       }
      //     }}
      //   />
      // )}
      mask={defaultNumberMask}
      //   placeholderChar={'\u2000'}
      //   pipe={autoCorrectedDatePipe}
      //   guide
      //   keepCharPositions
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          console.log('Enter key pressed');
          onEnterPressed && onEnterPressed(event.target.value);
        }
      }}
      size={size}
      error={error}
      helperText={helperText}
      style={{ width: width }}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default function NumberInput({
  value,
  maxValue,
  error = false,
  helperText,
  size,
  width,
  decimals, // amount of decimals to allow (0 or false to not allow decimals)
  min,
  max,
  onChange,
  onEnterPressed,
  inputRef,
  ...rest
}) {
  const { i18n } = useTranslation();

  const classes = useStyles();
  const [values, setValues] = React.useState({
    textmask: value
  });

  const handleChange = (name) => (event) => {
    event.preventDefault();
    console.log('new value', event.target.value);

    let value = event.target.value;

    // when entered in european style, convert to US / number style

    if (maxValue && value > maxValue) value = maxValue;

    setValues({
      ...values,
      [name]: value //! probably here I should use Intl.NumberFormat to format the number to locale
    });

    if (getDecimalSeparator(i18n.language) === ',') {
      // remove all dots
      value = value.replace(/\./g, '');
      // replace comma with dot
      value = value.replace(',', '.');
    }

    let numValue = Number(value);

    console.log('new value', value, numValue);
    onChange && onChange(value);
  };

  const handleEnterPressed = (value) => {
    // when entered in european style, convert to US / number style

    if (getDecimalSeparator(i18n.language) === ',') {
      // remove all dots
      value = value.replace(/\./g, '');
      // replace comma with dot
      value = value.replace(',', '.');
    }

    let numValue = Number(value);

    //   console.log('Weight Input: enter pressed: new value', value, numValue);
    //   onEnterPressed && onEnterPressed(value);
  };

  // console.log('NumberInput: Decimals: ', decimals);
  // console.log('NumberInput: error:', error);

  return (
    <div className={classes.container}>
      <ArgonInput
        className={classes.formControl}
        value={values.textmask}
        onChange={handleChange('textmask')}
        id="score"
        name="score"
        size={size}
        inputComponent={TextMaskCustom}
        // inputProps={{ mask: mask }}
        helperText
        error={error}
        inputProps={{
          //   inputComponent: TextMaskCustom
          onEnterPressed: handleEnterPressed,
          // helperText,
          error: helperText,
          size,
          width,
          decimals,
          inputRef,
          min,
          max
        }}
        variant="outlined"
        // margin="dense"

        {...rest}
      />
    </div>
  );
}
