/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-select components
import Select, { components } from 'react-select';

// Argon Dashboard 2 PRO MUI base styles
import colors from 'assets/theme/base/colors';

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from 'context';

// Custom styles for ArgonSelect
import styles from 'components/ArgonSelect/styles';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

const ArgonSelect = forwardRef(
  (
    {
      id,
      name,
      label,
      required,
      placeholder,
      size,
      error,
      helperText,
      success,
      isDisabled,
      overflow,
      labelColor,
      ...rest
    },
    ref
  ) => {
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const { light, dark } = colors;

    // console.log("ArgonSelect's error: ", error);
    // console.log("ArgonSelect's helperText: ", helperText);

    return (
      <>
        {/* <ArgonBox display="flex" flexDirection="column"> */}
        {label && (
          <>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color={labelColor || (darkMode ? dark.main : light.main)}
                textTransform="capitalize">
                {label}
                {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
              </ArgonTypography>
            </ArgonBox>
          </>
        )}

        <Select
          id={id}
          inputId={id}
          name={name}
          menuPortalTarget={overflow && document.querySelector('body')}
          // menuPortalTarget={overflow && document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
            ...styles(size, error, success, darkMode, isDisabled)
          }}
          inputProps={{ id: id }}
          // menuPosition="fixed"
          // // menuPosition={isFixed ? 'fixed' : 'absolute'}
          // menuPlacement={'auto'}
          placeholder={placeholder}
          // placeholder="Test"
          isDisabled={isDisabled}
          // onBlur={() => console.log('blur')}
          {...rest}
          ref={ref}
          // styles={styles(size, error, success, darkMode, isDisabled)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: light.main,
              primary: light.main
            }
          })}
          components={{
            Option: ({ children, ...props }) => (
              <components.Option {...props}>
                <div onTouchStart={(e) => e.stopPropagation()}>{children}</div>
              </components.Option>
            )
          }}
        />
        {helperText && (
          <>
            <ArgonBox mt={-1.4} ml={0.5}>
              <ArgonTypography
                component="label"
                color="error"
                variant="caption"
                fontWeight="regular"
                textTransform="capitalize">
                {helperText}
              </ArgonTypography>
            </ArgonBox>
          </>
        )}
        {/* </ArgonBox> */}
      </>
    );
  }
);

// Setting default values for the props of ArgonSelect
ArgonSelect.defaultProps = {
  size: 'medium',
  error: false,
  success: false
};

// Typechecking props for the ArgonSelect
ArgonSelect.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default ArgonSelect;
